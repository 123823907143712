// Initialise Sentry error monitoring
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://e9a7cb90d7fe84969ed38bfd9a243f56@o4506190134116352.ingest.sentry.io/4506190138703872",
  environment: process.env.NODE_ENV || "development",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0
});

// Initialise PostHog analytics
import posthog from "posthog-js";
import { posthogInit } from "~/utils/posthog/basic.js";
posthogInit();

// Loaded from CDN and included in externals in webpack.config.js
import MathJax from "mathjax";
import tinymce from "tinymce";

// Get jQuery object from node_modules and set globally
import $ from "jquery";
window.$ = window.jQuery = $;

// Required JS from node_modules to modify global jQuery object
import "jquery-modal";
import "jquery-ui";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/ui/widgets/dialog";
import "jquery-ui/ui/widgets/slider";
import "jquery-ui/ui/widgets/sortable";
require("jquery-ui-touch-punch");

// Required local JS to modify global jQuery object
import "./dfm-canvas-2.4.js";
import "./dfm-whiteboard-new.js";

// Required JS from local files
import { showKeyboard, closeKeyboard } from "./algebra-1.8.js";
import { getAllSkillsCourseDetails, getCourse, getCourseGroupsForDropdown } from "~/utils/courses.js";
import { getDownloadablesForCourseUnit } from "~/utils/downloadables.js";
import { posthogEventCoursesHomeClicked } from "~/utils/posthog/courses.js";
import {
  posthogEventSearchUserClicked,
  posthogEventSearchUserEditDetailsChosen,
  posthogEventSearchUserEditDetailsSubmitted,
  posthogEventSearchUserMoveClassChosen
} from "~/utils/posthog/misc.js";
import { posthogEventResourceGroupSelected } from "~/utils/posthog/resources.js";
import {
  posthogEventLearnModalClosed,
  posthogEventLearnModalOpened,
  posthogEventRevisionHubLinkClicked,
  posthogEventSetATaskModalClosed,
  posthogEventSetATaskModalOpened,
  posthogEventStartAPracticeModalClosed,
  posthogEventStartAPracticeModalOpened,
  posthogEventTaskByTopicChosen,
  posthogEventTaskChooseQuestionsChosen,
  posthogEventTaskIndependentPracticeStarted,
  posthogEventTaskSet
} from "~/utils/posthog/tasks.js";
import {
  posthogEventWorksheetHomeDirectoryViewed,
  posthogEventWorksheetPastPapersDirectoryViewed
} from "~/utils/posthog/worksheets.js";

// CSS
import "jquery-modal/jquery.modal.css"
import "jquery-ui/themes/base/datepicker.css";
import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/theme.css";
import "jquery-ui/themes/base/slider.css";
import "./dfm5.css";

export const Context = {
  user: undefined,
  messageCounter: 0
}

export function dfmAlert(message, onClose) {
  Context.messageCounter++;

  // Wrap a plain-text message in a <p> tag.
  // ("plain-text" = doesn't obviously start with a '<' tag)
  if (message.trim().charCodeAt(0) !== 60) { message = `<p>${message}</p>`; }

  $('body').append("<div id='message-" + Context.messageCounter + "' class='modal'>" + message + "</div>");

  // This is annoying - to do with the current modal needing time to close first.
  setTimeout(function () { $("#message-" + Context.messageCounter).modal({ closeExisting: false }); }, 200);

  // Causes modal to be completely removed from DOM after closure (avoiding problems of unique form elements with id fields floating around):
  $("#message-" + Context.messageCounter).on($.modal.AFTER_CLOSE, function (event, modal) {
    if (typeof onClose === "function") {
      onClose();
    }
    $(this).remove();
  });

  return false;
}

export function dfmAlertInverted(message, onClose) {
  dfmAlert(message, onClose);
  $(".modal").addClass('dialog-inverted');
}

export function dfmDialogCloseLast() {
  console.log("Closing last dialog " + Context.messageCounter);
  $("#message-" + Context.messageCounter).find('.close-modal').click();
}

export function isTouchDevice() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}

export function isAdmin() {
  return Context.user?.type === "admin";
}

export function isTeacher() {
  return Context.user != null && Context.user != undefined && (Context.user.type == "teacher" || Context.user.type == "admin");
}

export function isStudent() {
  return Context.user != null && Context.user != undefined && Context.user.type == "student";
}

export function isDemoStudent() {
  return Context.user?.status === 5;
}

export function isParent() {
  return Context.user != null && Context.user != undefined && Context.user.type == "parent";
}

export function isTrustAdmin() {
  return Context.user != null && Context.user != undefined && Context.user.type == "trust";
}


export function mustUseSso() {
  return isAdmin() || Context.user?._school?.ssorequired === "ALL" ||
    (Context.user?._school?.ssorequired === "TEACHERS" && Context.user?.type === "teacher");
}

const PERMISSION_ADMINONLY = "y";
const PERMISSION_AUTHORONLY = "u";
const PERMISSION_SCHOOLTEACHERS = "x";
const PERMISSION_SCHOOLSTUDENTS = "s";
const PERMISSION_TRUSTTEACHERS = "w";
const PERMISSION_TRUSTSTUDENTS = "v";
const PERMISSION_ALLTEACHERS = "t";
const PERMISSION_ALL = "a";

export function hasDirectoryPermission(directory, permissionType) {
  const permission = directory[permissionType];

  if (!Context.user) return permission === PERMISSION_ALL;
  if (Context.user.type == "admin") return true;

  const schoolMatches = Context.user.sid === directory.sid;
  const academyChainMatches = Context.user._school != null && Context.user._school.acid != null && Context.user._school.acid === directory.acid;
  const isTeacher = Context.user.type === "teacher" || Context.user.type === "trust";

  switch (permission) {
    case PERMISSION_ADMINONLY:
      return Context.user.type == "admin";  // Will have already returned true above, in practice
    case PERMISSION_AUTHORONLY:
      return Context.user.uid === directory.uid;
    case PERMISSION_SCHOOLTEACHERS:
      return isTeacher && schoolMatches;
    case PERMISSION_SCHOOLSTUDENTS:
      return schoolMatches;
    case PERMISSION_TRUSTTEACHERS:
      return isTeacher && (schoolMatches || academyChainMatches);
    case PERMISSION_TRUSTSTUDENTS:
      return schoolMatches || academyChainMatches;
    case PERMISSION_ALLTEACHERS:
      return isTeacher;
    case PERMISSION_ALL:
      return true;
    default:
      // TODO log some kind of warning/alert, as this should be handled by cases above.
      // Fail-safe is to deny access if we have no idea what the permission is
      return false;
  }
}


export function redirectToRequiredLogin() {
  if (!Context.user) {
    window.location = `/login.php?url=${window.location.pathname}${window.location.search}`;
  }
}

/* ----------------------------------------------
   -- DFM Dialog with Buttons

   -- e.g. buttons = [{label: "OK", action: function(){ ... }}]
   ---------------------------------------------- */
// preventClose = true completely prevents the dialog being closed
// softPreventClose = true keeps the X button but prevents accidental closure by clicking outside the dialog.

export function dfmDialog(content, buttons, allowMultiple, preventClose, softPreventClose, onClose) {
  if (preventClose === undefined) {
    preventClose = false;
  }
  if (!Array.isArray(buttons)) {
    buttons = [buttons];
  }

  Context.messageCounter++;

  // Wrap plain-text content in a <p> tag.
  // ("plain-text" = doesn't obviously start with a '<' tag)
  if (content.trim().charCodeAt(0) !== 60) { content = `<p>${content}</p>`; }

  var buttonHTML = "";
  $.each(buttons, function (k, b) {
    buttonHTML += `<button id="but-${Context.messageCounter}-${k}" ${b.disabled ? "disabled" : ""} ${b.tooltip ? `title="${b.tooltip}"` : ""}>${b.label}</button>`;
  });
  $('body').append("<div id='message-" + Context.messageCounter + "' class='modal'>" + content + "<div class='dialog-buttons'>" + buttonHTML + "</div></div>");
  if (allowMultiple) $("#message-" + Context.messageCounter).modal({ closeExisting: false, escapeClose: !preventClose, clickClose: !preventClose, showClose: true });
  else $("#message-" + Context.messageCounter).modal({ escapeClose: !preventClose, clickClose: !preventClose && softPreventClose !== true, showClose: true });
  if (preventClose) $(".close-modal").hide();
  $("#message-" + Context.messageCounter).data("id", Context.messageCounter);
  $.each(buttons, function (k, b) {
    $("#but-" + Context.messageCounter + "-" + k).click(function (e) {
      e.preventDefault();
      var id = $(this).closest('.modal').data("id");
      var response = b.action();
      if (response !== false) {
        $("#message-" + id).find(".close-modal").click();
      }
    });
  });
  // Causes modal to be completely removed from DOM after closure (avoiding problems of unique form elements with id fields floating around):
  $("#message-" + Context.messageCounter).on($.modal.AFTER_CLOSE, function (event, modal) {
    if (typeof onClose === "function") {
      onClose();
    }
    $(this).remove();
  });
  return Context.messageCounter;
}

/* ----------------------------------------------
   -- DFM confirmation dialog
   ---------------------------------------------- */

export function dfmConfirm(message, action) {
  Context.messageCounter++;

  // Wrap a plain-text message in a <p> tag.
  // ("plain-text" = doesn't obviously start with a '<' tag)
  if (message.trim().charCodeAt(0) !== 60) { message = `<p>${message}</p>`; }

  var buttonHTML = "<button id='yesbut-" + Context.messageCounter + "'>Yes</button>";
  $('body').append("<div id='message-" + Context.messageCounter + "' class='modal'>" + message + "<div class='dialog-buttons'>" + buttonHTML + "</div></div>");
  $("#message-" + Context.messageCounter).modal({ closeExisting: false });
  $("#yesbut-" + Context.messageCounter).click(function () {
    action();
    $.modal.close();
  });
  $("#message-" + Context.messageCounter).on($.modal.AFTER_CLOSE, function (event, modal) {
    $('.modal').remove();
  });
  return Context.messageCounter;
}

export function closeDfmMessage(messageId) {
  if (messageId) {
    $("#message-" + messageId).find(".close-modal").trigger('click');
  }
}

export function instantiateSideMenu() {
  $("#sidemenu").empty();

  const coursesHref = "/courses-redirect.php";
  const coursesItemFragment =
    `<li>
      <a id="courses-home-link" href='${coursesHref}'>
        <img src='/images/book_icon.svg'>Courses
      </a>
    </li>`;

  if (Context.user) {
    $("#top-bar #logo a").attr("href", "/dashboard.php"); // dfm logo will now link to dashboard

    if (isStudent()) {
      $("#sidemenu").append("<ul><li><a href='dashboard.php'><img src='/images/home_icon.svg'>Home</a></li></ul>");
      $("#sidemenu").append("<h1>Revision</h1>");
      $("#sidemenu").append("<ul>"
        + "<li><a id='revision-hub-link' href='/revision/papers'><img src='/images/list-check.svg'>GCSE Past Papers<span class='new-content-label'>New</span></a></li>"
        + "<li><a href='/worksheets.php?wdid=2'><img src='/images/directory_icon.svg'>All Past Papers</a></li>"
        + "</ul>");
      $("#sidemenu").append("<h1>Tasks & Learning</h1>");
      $("#sidemenu").append("<ul>"
        + "<li><a id='sidemenu-startpractice' href='#'><img src='/images/radical.svg'>Start a Practice</a></li>"
        + "<li><a href='progress.php?mode=tasklist'><img src='/images/pencil_icon.svg'>My Tasks</a></li>"
        + coursesItemFragment
        + "<li><a href='progress.php?mode=studentprogress'><img src='/images/progress_icon.svg'>Progress Data</a></li>"
        + "</ul>");

      $("#sidemenu-startpractice").on("click", () => {
        posthogEventStartAPracticeModalOpened({ triggerId: "vertical-nav-start-a-practice-link" });
        startPracticeDialog();
      });

      $("#sidemenu").append("<h1>Games</h1>");
      $("#sidemenu").append("<ul>"
        + "<li><a href='/live-join-new.php'><img src='/images/phone_icon2.svg'>Join a Live! Game</a></li>"
        + "</ul>");

    } else {
      // Teachers/parents/MAT admins/etc.
      $("#sidemenu").append("<ul><li><a href='dashboard.php'><img src='/images/home_icon.svg'>Home Dashboard</a></li></ul>");
      $("#sidemenu").append("<h1>Student Revision</h1>");
      $("#sidemenu").append("<ul>"
        + "<li><a id='revision-hub-link' href='/revision/papers'><img src='/images/list-check.svg'>GCSE Past Papers<span class='new-content-label'>New</span></a></li>"
        + "<li><a href='/worksheets.php?wdid=2'><img src='/images/directory_icon.svg'>All Past Papers</a></li>"
        + "</ul>");
      $("#sidemenu").append("<h1>Tasks & Learning</h1>");
      $("#sidemenu").append("<ul>"
        + "<li><a id='sidemenu-settasklink' href='#'><img src='/images/pencil_icon.svg'>Set a Task</a></li>"
        + "<li><a href='downloadables.php'><img src='/images/downloadables_icon.svg'>Lesson Resources</a></li>"
        + "<li><a href='progress.php'><img src='/images/progress_icon.svg'>Progress Data</a></li>"
        + "<li><a href='explorer.php'><img src='/images/compass_icon.svg'>Question Explorer</a></li>"
        + "<li><a href='worksheets.php'><img src='/images/cabinet_icon.svg'>Papers & Worksheets</a></li>"
        + coursesItemFragment
        + "</ul>");

      $("#sidemenu-settasklink").on("click", function () {
        posthogEventSetATaskModalOpened({ triggerId: "vertical-nav-set-a-task-link" });
        setTaskDialog();
        closeSideMenu();
        return false;
      });

      $("#sidemenu").append("<h1>Setup & Help</h1>");
      $("#sidemenu").append("<ul>"
        + "<li><a href='settings.php?tab=classes'><img src='/images/cog_icon.svg'>Classes & Settings</a></li>"
        + "<li><a href='training.php'><img src='/images/help_icon.svg'>Help & Training</a></li>"
        + "</ul>");

      $("#sidemenu").append("<h1>Games</h1>");
      $("#sidemenu").append("<ul>"
        + "<li><a href='#' id='sidemenu-livegame'><img src='/images/phone_icon2.svg'>Live! Game</a></li>"
        + "</ul>");
      $("#sidemenu-livegame").click(liveGameDialog);
    }

  } else {
    $("#sidemenu").append("<ul><li><a href='/login.php'><img src='/images/home_icon.svg'>Login/Register</a></li></ul>");
    $("#sidemenu").append("<h1>Student Revision</h1>");
    $("#sidemenu").append("<ul>"
      + "<li><a id='revision-hub-link' href='/revision/papers'><img src='/images/list-check.svg'>GCSE Past Papers<span class='new-content-label'>New</span></a></li>"
      + "<li><a href='/worksheets.php?wdid=2'><img src='/images/directory_icon.svg'>All Past Papers</a></li>"
      + "</ul>");
    $("#sidemenu").append("<h1>Tasks & Learning</h1>");
    $("#sidemenu").append("<ul>"
      + "<li><a id='sidemenu-startpractice' href='#'><img src='/images/pencil_icon.svg'>Practise</a></li>"
      + "<li><a href='downloadables.php'><img src='/images/downloadables_icon.svg'>Lesson Resources</a></li>"
      + coursesItemFragment
      + "</ul>");

    $("#sidemenu-startpractice").on("click", () => {
      posthogEventStartAPracticeModalOpened({ triggerId: "vertical-nav-start-a-practice-link" });
      startPracticeDialog();
    });
  }

  $("#courses-home-link").on("click", () => {
    posthogEventCoursesHomeClicked({
      triggerId: "vertical-nav-courses-link",
    });
  });

  $("#revision-hub-link").on("click", () => {
    posthogEventRevisionHubLinkClicked({
      triggerId: "vertical-nav-gcse-past-papers-link",
      linkUrl: "/revision/papers",
    });
  });
}

// Adds a export function to call once the logged in user object is loaded.
export function addInitialFunction(func) {
  if (!$(document).data('initialFuncs')) $(document).data('initialFuncs', [])
  $(document).data('initialFuncs').push(func);
}

/**
 * Gets the user object along with notification data.
 * If the user is not logged in, the user object will be null.
 *
 * @return {Promise<{ count: number, notifications: any[], user: any? }>}
 */
async function getLoggedInUser() {
  try {
    const response = await fetch("/api/user/current/");
    if (!response.ok) {
      const e = await response.json();
      throw new Error(e.message);
    }
    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    dfmAlert("There was an error getting the current user.");
  }
}

function dealWithGetLogin(data) {
  $("#account-menu").empty();

  const notLoggedIn = !data?.user;
  if (notLoggedIn) {
    $("#account-menu").append("<li id='mainmenu-learn'><a>Learn</a></li>");
    $("#account-menu").append("<li id='mainmenu-pricing'><a href='/pricing.php'>Pricing</a></li>");
    $("#account-menu").append("<li id='mainmenu-donate'><a href='/donate.php'>Donate</a></li>");
    $("#account-menu").append(`<li id='mainmenu-login'><a href='/login.php?url=${window.location.pathname}${window.location.search}'>Login</a></li>`);
    $("#mainmenu-learn").on("click", () => {
      posthogEventLearnModalOpened({ triggerId: "top-bar-learn-link" });
      learnDialog();
    });
  } else {
    let nameToUse;

    if (isStudent() || isParent()) {
      nameToUse = data.user.firstname
        ? data.user.firstname
        : data.user.firstname
          ? data.user.firstname[0] + " " + data.user.surname
          : data.user.surname;
    } else {
      nameToUse = data.user.firstname
        ? data.user.firstname[0] + " " + data.user.surname
        : data.user.surname;
    }

    let initials = (data.user.firstname ? data.user.firstname[0] : "") + data.user.surname[0];

    $("#account-menu").append("<li id='mainmenu-user'><a href='#'><label class='longer-name'>" + nameToUse + "</label><label class='initials'>" + initials + "</label><img src='/images/chevron_down.svg'></a></li>");

    if (data.count) {
      // Notification count (for students - it's the number of incomplete homeworks)
      $("#mainmenu-user label").append(
        "<span id='notification-count'>" + data.count + "</span>",
      );
    }
    $("#mainmenu-user").append("<div id='account-submenu' class='submenu' style='display: none'>"
      + "<div id='account-submenu-notifications'>"
      + "<ul></ul>"
      + "</div>"
      + "<ul>"
      + "<li><a href='/dashboard.php' id='mainmenu-account'>My Dashboard</a></li>"
      + (isTeacher() ? "<li><a id='mainmenu-viewasstudent' style='cursor:pointer;'>View as Student</a></li>" : "")
      + (!isDemoStudent() ? "<li><a href='/settings.php?tab=account' id='mainmenu-account'>Account Settings</a></li>" : "")
      + (Context.user?.type === "admin" ? "<li><a href='/admin.php'>Admin</a></li>" : "")
      + "<li><a id='mainmenu-logoff' style='cursor:pointer;'>" + (isDemoStudent() ? "End demo" : "Log out") + "</a></li>"
      + "</ul></div>");

    $("#mainmenu-logoff").click(() => logOut());
    $("#mainmenu-viewasstudent").click(() => viewAsStudent());

    $("#account-submenu-notifications ul").empty();
    $.each(data.notifications, function (k, n) {
      $("#account-submenu-notifications ul").append("<li><a href='" + (n.url ? n.url : "") + "'><img src='" + n.icon + "'>" + n.text + "</a></li>");
    });
    $("#account-submenu-notifications ul").children().last().addClass('last');

    $("#account-menu > li").mouseover(function () {
      console.log("mouseover");
      showSubMenu($(this));
    });
    $("#account-menu > li").mouseout(function () {
      hideSubMenu($(this));
    });

    if (isTrustAdmin()) {
      $("#trust-schoolselection").html("<option value=''>Select a trust school</option>");
      $.each(Context.user._trust._schools, function (k, school) {
        $("#trust-schoolselection").append("<option value='" + school.sid + "'>" + school.name + "</option>");
      });
      $("#trust-schoolselection").show().val(Context.user.sid ? Context.user.sid : "");
      $("#trust-schoolselection").change(function () {
        updateTrustAdminSchool($(this).val());
      });
    }
  }
}

export function logOut(v) {
  $.ajax({
    url: "/api/auth/logout",
    context: document.body,
    dataType: "json",
    type: "GET",
    contentType: "application/json",
    success: function (data, textStatus, jqXHR) {
      posthog.reset();
      window.location = "index.php";
    },
    error: function (jqXHR, textStatus, errorThrown) {
      if (jqXHR.responseJSON?.message === "LOGOUT_NOT_LOGGED_IN") {
        window.location = "index.php";
      } else {
        let msg = "There was an error logging out";
        msg += j.responseText ? `: <strong>${j.responseText}</strong>` : ".";
        dfmAlert(msg);
      }
    },
  });
}

export function parseJwtPayload(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function updateTrustAdminSchool(sid) {
  $.ajax({
    url: "/api/users/updatetrustadmin/" + sid,
    type: 'PATCH',
    context: document.body,
    dataType: "json",
    success: function (data) {
      location.reload(); // reload page, as contact will depend on school
    },
    error: function (jqXHR, textStatus, errorThrown) {
      dfmAlert("There was an error getting the class list:<br><br><strong>" + jqXHR.responseText + "</strong>");
    }
  });
}

export function viewAsStudent() {
  $.ajax({
    url: "/api/class/get_school_classes?repeatUserClassGroups=true",
    context: document.body,
    dataType: "json",
    success: function (data, textStatus, jqXHR) {
      dfmAlert("<h2>Select a Class</h2>"
        + "<p>This facility allows you to use a demo student account as if you were a student in the class, which includes any tasks you have set.</p>"
        + "<p><select id='viewasstudent'><option value=''>-</option></select></p>");

      $.each(data.classes, function (k, c) {
        if (c.cid) {
          $("#viewasstudent").append("<option value='" + c.cid + "'>" + c.name + "</option>");
        } else {
          $("#viewasstudent").append("<option value=''>-----</option>");
        }
      });
      $("#viewasstudent").change(function () {
        let cid = $(this).val();

        $.ajax({
          url: "/api/class/loginasdemo/" + cid,
          type: "POST",
          context: document.body,
          dataType: "json",
          success: function (data) {
            window.location = 'dashboard.php';
          },
          error: function (jqXHR, textStatus, errorThrown) {
            dfmAlert("There was an error logging on as this class' demo account:<br><br><strong>" + jqXHR.responseText + "</strong>");
          }
        });
      });
    },
    error: function (jqXHR, textStatus, errorThrown) {
      dfmAlert("There was an error getting the class list:<br><br><strong>" + jqXHR.responseText + "</strong>");
    }
  });

}


// <span class='notification-number ".($newNotifications ? "highlight" : "")."'>$numn</span>


export function setDefaultSearch() {
  closeSearch();
}

export function closeSearch() {
  $("#search-results-new").hide();
  $("#search-results-new .dfm_TabsWrapper ul").empty();
  emSearchBar_resultsActive = false;
}

var emSearchBar_pendingQueryTimerId;
var emSearchBar_waitTime = 200;
var emSearchBar_latestSearch;
var emSearchBar_resultsActive = false;
var searchCounter = 0;
var searchReceived = -1;
export function doSearch(val) {
  emSearchBar_resultsActive = true;
  // $("#search-loading").show();
  // $("#search-results").show();
  $("#users-panel").hide();
  // $("#page").hide();
  if (emSearchBar_pendingQueryTimerId) {
    // console.log("Cleared search "+emSearchBar_pendingQueryTimerId);
    clearTimeout(emSearchBar_pendingQueryTimerId);
  }
  emSearchBar_pendingQueryTimerId = setTimeout(function () {
    doAJAXSearch(val);
    emSearchBar_pendingQueryTimerId = undefined;
  }, emSearchBar_waitTime);
  // console.log("Initiating timer for search id "+emSearchBar_pendingQueryTimerId);
}


export function userPopupMenu(uid, firstname, surname, email, title, hasClass) {
  let buttons = [];
  if (Context.user?.type !== "parent") {
    buttons.push({
      label: "Move Class", disabled: !hasClass, tooltip: hasClass ? "" : "User has no current class", action: () => {
        posthogEventSearchUserMoveClassChosen();

        window.location = `settings.php?tab=classes&action=move&uid=${uid}`;
      }
    });
  }
  buttons.push({
    label: "Edit Details", action: () => {
      editUserDetails({
        uid: uid,
        firstname: firstname,
        surname: surname,
        email: email,
        title: title
      }, () => {
        posthogEventSearchUserEditDetailsSubmitted();
      });

      posthogEventSearchUserEditDetailsChosen();
    }
  });
  dfmDialog(`<span style="font-size: 20px; font-weight: 600;">${firstname} ${surname}</span>`, buttons);
}

export function editUserDetails(user, callback) {
  dfmDialog("<form>"
    + (user.title ? "<div><label>Title</label><select id='editstudent-title'><option>Mr</option><option>Ms</option><option>Miss</option><option>Mrs</option><option>Mx</option><option>M</option><option>Dr</option><option>Prof</option></select></div>" : "")
    + "<div><label>Firstname</label><input type='text' id='editstudent-firstname'></div>"
    + "<div><label>Surname</label><input type='text' id='editstudent-surname'></div>"
    + "<div><label>Email/Username</label><input type='text' id='editstudent-email'></div>"
    + "</form>", [{
      label: "Submit", action: function () {

        var firstname = $("#editstudent-firstname").val();
        var surname = $("#editstudent-surname").val();
        var email = $("#editstudent-email").val();
        var title = $("#editstudent-title").val();
        if (!firstname || !surname || !email) {
          dfmAlert("Please ensure the form details are filled in.");
          return false;
        }

        var data = { uid: user.uid, firstname: firstname, surname: surname, email: email };
        if (title) data.title = title;
        $.ajax({
          url: "/api/user/other",
          type: "PATCH",
          contentType: false,
          processData: false,
          cache: false,
          data: JSON.stringify(data),
          success: function (data) {
            console.log("[Successfully updated student]");
            if (callback) {
              callback(data);
            }
          },
          error: function (j, textStatus, errorThrown) {
            dfmAlert("An error occurred when trying to update this student's details: <strong>" + j.responseText + "</strong>");
          }
        });
      }
    }]);
  $("#editstudent-firstname").val(user.firstname);
  $("#editstudent-surname").val(user.surname);
  $("#editstudent-email").val(user.email);
  $("#editstudent-title").val(user.title);
}


export function doAJAXSearch(query) {
  searchCounter++;
  console.log("Started search " + emSearchBar_pendingQueryTimerId + " c" + searchCounter + ": " + query);
  var url = "/api/search/" + encodeURIComponent(query) + "/" + searchCounter;
  console.log(url);
  $.ajax({
    url: url,
    context: document.body,
    dataType: "json",
    success: function (data, textStatus, jqXHR) {
      console.log("Received results from search " + data.counter);
      if (data.counter && Number(data.counter) < searchReceived) {
        console.log("[RejectedSearchResults] lastSuccess=" + searchReceived + " current=" + data.counter);
        return; // prevents responses to older search requests overriding newer ones.
      }
      searchReceived = Number(data.counter);
      console.log("[DisplayingSearchResults] searchReceived updated to " + searchReceived);
      $("#search-loading").hide();
      var c = data.users.length + data.skills.length + data.schools.length;
      $("#search-header span").html(c + " results");

      if (Context.user && Context.user.type === "admin") $("#search-results-new-tab-users").click();
      var anyResults = false;

      // DOWNLOADABLES

      const downloadablesList = $("#search-results-new-downloadables ul");
      downloadablesList.empty();
      const downloadables = data.downloadables ?? [];

      data.downloadables.forEach((resource, i) => {
        const { rid = "", title = "", description = "" } = resource;
        downloadablesList.append("<li><a id='downloadable-" + rid + "' href='/downloadables.php?rid=" + rid + "'><img src='/images/downloadables_icon.svg' /><h1>" + title + "</h1><h2>" + description + "</h2></a></li>");
        $("#downloadable-" + rid).on("click", function () {
          posthogEventResourceGroupSelected({
            triggerId: "main-search-downloadable-result",
            groupType: "resource",
            resource,
            search: {
              query,
              results: downloadables.length,
              position: i + 1,
            }
          });
        });
      });

      if (downloadables.length === 0) {
        $("#search-results-new-tab-downloadables").hide();
      } else {
        $("#search-results-new-tab-downloadables").html("Downloadables (" + downloadables.length + ")").show();
        anyResults = true;
      }

      // SKILLS

      const skillsContainer = $("#search-results-new-skills");
      skillsContainer.empty();
      const skills = data.skills ?? [];

      /**
       * Each skill returned will contain a locations array. This array provides
       * context about each location the skill could be found in the course, which
       * in this case is the all skills course.
       *
       * In theory, in the context of the all skills course, we shouldn't have a
       * skill in multiple locations. However, we should still handle duplicates.
       *
       * We first need to group the skills by their location.
       */

      const locationsToRender = [];
      skills.forEach(({ locations = [], ...skill }) => {
        locations.forEach(location => {
          const existingLocation = locationsToRender.find(l => l.cuid === location.cuid);
          if (existingLocation) {
            existingLocation.skills.push(skill);
          } else {
            locationsToRender.push({ ...location, skills: [skill] });
          }
        })
      })

      // Sort by module and unit.
      locationsToRender.sort((a, b) => a.unitOrder - b.unitOrder);
      locationsToRender.sort((a, b) => a.moduleOrder - b.moduleOrder);

      // Render the skills grouped by location.
      locationsToRender.forEach(({ cuid, unitName = "", moduleName = "", skills = [] }) => {
        if (skills.length) {
          skillsContainer.append(
            `<h2>${moduleName}</h2><h1>${unitName}</h1>`
          );
        }
        skills.forEach(({ skid, name: skillName = "", publicId, subskills = [] }) => {
          if (subskills.length) {
            subskills.sort((a, b) => {
              const aLetter = a.letter ? a.letter : "";
              const bLetter = b.letter ? b.letter : "";
              return aLetter.localeCompare(bLetter);
            });
            const skillElementId = `unit-${cuid}-skill-${skid}`;
            skillsContainer.append(
              `<div class='skill' id='${skillElementId}'>
                           <div>
                              <label>${publicId}</label>
                              <div>
                                 <h2>${skillName}</h2>
                                 <button class='explore-button'>
                                    <img src='/images/compass_icon.svg'>Explore
                                 </button>
                              </div>
                           </div>
                           <ul></ul>
                        </div>`
            );
            $(`#${skillElementId}`).on("click", () => window.location = `/explorer.php?cuid=${cuid}&skid=${skid}`);
            const skillList = $(`#${skillElementId} > ul`);
            subskills.forEach(({ active, letter, name: subskillName = "" }) => {
              skillList.append(
                `<li>
                              <span class='${active ? "" : "inactive"}'>
                                 <strong>${publicId}${letter ? letter : ""}</strong> - ${subskillName}
                              </span>
                           </li>`
              );
            })
          }
        });
      });

      if (skills.length === 0) {
        $("#search-results-new-tab-skills").hide();
      } else {
        $("#search-results-new-tab-skills").html(`Skills (${skills.length})`).show();
        anyResults = true;
      }

      // USERS

      var uUL = $("#search-results-new-users ul");
      uUL.empty();
      $.each(data.users, function (k, v) {
        var resultClass = v.status == 9 ? "class='deleted'" : "";

        const thumb =
          v.thumb ? v.thumb :
            v._school && v._school.thumb ? v._school.thumb :
              "/homework/img/user-neutral.png"

        const type = v.type ?? ""

        if (v._school && v._school.name) {
          uUL.append("<li " + resultClass + "><a href='#'><img src='" + thumb + "'>" + v.firstname + " " + v.surname + "<br><small>" + v._school.name + " (" + type + ")" + (v.ctext ? " - " + v.ctext : "") + "</small></a></li>");
        } else {
          uUL.append("<li " + resultClass + "><a href='#'><img src='" + thumb + "'>" + v.firstname + " " + v.surname + "<br><small>" + type[0].toUpperCase() + type.substr(1) + " " + (v.ctext ? " - " + v.ctext : "") + "</small></a></li>");
        }

        uUL.children().last().data('user', v);
        uUL.children().last().click(function () {
          var user = $(this).data('user');
          // Ctext is non-empty iff the user is a member of at least one class
          const hasClass = Boolean(user.ctext);

          userPopupMenu(user.uid, user.firstname, user.surname, user.email, user.title, hasClass);

          posthogEventSearchUserClicked();
        });
      });
      if (!data.users || data.users.length == 0) {
        $("#search-results-new-tab-users").hide();
      } else {
        $("#search-results-new-tab-users").html("Users (" + data.users.length + ")").show();
        anyResults = true;
      }



      // SCHOOLS

      var r = $("#search-results-new-schools ul");
      r.empty();
      $.each(data.schools, function (k, school) {


        var sUrl = "dashboard.php?sid=" + school.id;
        var sPic = school.thumb ? school.thumb : "/homework/img/school.png";
        r.append("<li><a href='#'><img src='" + sPic + "'>" + school.name + "<br><small>" + school.town + " &nbsp;&nbsp; Num Students: " + school.numstudents + "</small></a>");

        r.children().last().click(function () {
          var input = "<button onclick=\"window.location='" + sUrl + "'\">" + (Context.user.type === "admin" ? "Dashboard" : "Info") + "</button> ";
          input += "<br><br><button onclick=\"window.location='schoolsanalysis-map.php?sid=" + school.sid + "'\">Stats</button> ";
          input += "<br><br><button onclick=\"window.location='manage-school-settings.php?sid=" + school.sid + "'\">Edit School Details/Logo</button> ";
          input += "<br><br><button onclick=\"window.location='admin-update-subscription.php?sid=" + school.sid + "'\">Update Subscription</button> ";
          dfmDialog("<h2>" + school.name + "</h2>" + input, []);
        });


      });
      if (!data.schools || data.schools.length == 0) {
        $("#search-results-new-tab-schools").hide();
      } else {
        $("#search-results-new-tab-schools").html("Schools (" + data.schools.length + ")").show();
        anyResults = true;
      }


      if (!anyResults) {
        $("#search-noresults").show();
      } else {
        $("#search-noresults").hide();
      }



      MathJax.typeset();

      $("#search-results-new").show().offset({ left: $("#search-form input").offset().left });
      $("#search-header-new-type a").filter(":visible").first().click();
    }
  });
}



export function showSubMenu(div) {
  closeSideMenu();
  $("#account-submenu").show();
  var submenuWidth = $("#account-submenu").outerWidth();
  var menuItemRight = $("#mainmenu-user").offset().left + $("#mainmenu-user").outerWidth();
  $("#account-submenu").offset({ left: menuItemRight - submenuWidth });
}

var menuBreakpoint = 670; // changes to expandable menu this width and below.

export function hideSubMenu(div) {
  $("#account-submenu").hide();
}

$(window).resize(function () {

  if ($(document).width() <= 660 && $("#search-form input").val() === "Search") {
    $("#search-form input").val("Search for resources");
  } else if ($(document).width() > 660 && $("#search-form input").val() === "Search for resources") {
    $("#search-form input").val("Click to search for resources");
  }
});
$(document).ready(function () {
  $(window).resize();
});

var sideMenuOpen = false;
$(document).ready(function () {
  $("#mainmenu-button").click(function () {
    if (sideMenuOpen) closeSideMenu();
    else openSideMenu();
    $(window).resize();
  });
  $("#account-submenu-notifications a").last().addClass('last');
});

export function closeSideMenu() {
  if (!sideMenuOpen) return;
  $("#sidemenu").animate({ left: "-280" }, 350);
  sideMenuOpen = false;
}
export function openSideMenu() {
  if (sideMenuOpen) return;
  closeSearch();
  $("#sidemenu").animate({ left: "0" }, 350);
  sideMenuOpen = true;
}
$(window).resize(function () {
  $("#sidemenu").outerHeight($(window).height() - $("#top-bar").outerHeight());
});

$(document).click(function (event) {
  var $target = $(event.target);
  if (!$target.closest('#sidemenu').length && !$target.closest('#mainmenu-button').length && $('#sidemenu').is(":visible")) {
    closeSideMenu();
  }
});

/* ----------------------------------------------
   -- Textual utility functions
   ---------------------------------------------- */

export function andJoinArray(a) {
  var txt = "";
  for (var i = 0; i < a.length; i++) {
    if (i >= 1 && i < a.length - 1) txt += ", ";
    if (i >= 1 && i == a.length - 1) txt += " and ";
    txt += a[i];
  }
  return txt;
}



// -------------------------------
// -- TEXTAREA (using tinymce)  --
// -------------------------------

jQuery.fn.dfmTextarea = async function ({ onChange, allowImages = true } = {}) {
  var div = $(this);
  tinymce.suffix = '.min';
  return tinymce.init({
    selector: "#" + div.attr('id'),
    license_key: 'gpl',
    menubar: false,
    theme_advanced_resizing: true,
    theme_advanced_resizing_use_cookie: false,
    height: 300,
    toolbar: false,
    statusbar: true,
    elementpath: false,
    branding: false,
    base_url: 'js/tinymce',
    paste_data_images: allowImages ? true : false,
    images_upload_url: allowImages ? '/api/questions/image' : undefined,
    images_file_types: allowImages ? 'jpeg,jpg,jpe,jfi,jfif,png,gif,bmp,webp,svg' : '',
    block_unsupported_drop: true,
    table_sizing_mode: 'fixed',
    setup: function (ed) {
      ed.on('keydown', function (e) {
        if ((e.keyCode == 84) && e.altKey) {
          // Alt + T
          dfmDialog("<h2>Insert a table</h2><label>Num Cols</label><input name='cols'><br><label>Num Rows</label><input name='rows'><br><br><input type='checkbox' name='addrow' checked> Add Header Row<br><input type='checkbox' name='addcol'> Add Header Col",
            [{
              label: "+Add", action: function () {

                var thead = "";
                if ($("input[name=addrow]").is(":checked")) {
                  thead = "<tr>";
                  if ($("input[name=addcol]").is(":checked")) thead += "<th style='min-width:20px'></th>";
                  for (var i = 0; i < $("input[name=cols]").val(); i++)thead += "<th style='min-width:20px'></th>";
                  thead += "</tr>";
                }
                var tbody = "";
                for (var i = 0; i < $("input[name=rows]").val(); i++) {
                  tbody += "<tr>";
                  if ($("input[name=addcol]").is(":checked")) tbody += "<th></th>";
                  for (var j = 0; j < $("input[name=cols]").val(); j++)tbody += "<td></td>";
                  tbody += "</tr>";
                }
                tinyMCE.activeEditor.execCommand('mceInsertContent', false, "<table><thead>" + thead + "</thead><tbody>" + tbody + "</tbody></table>");

              }
            }]);
        }

        if ((e.keyCode == 187 || e.keyCode == 61) && e.altKey) {
          var input = $(this);
          dfmDialog("Press the Return key when done or click the button below.<br><br><span id='alg-quickexpr' name='alg-quickexpr' style='width:200px;padding:10px'></span>",
            [{ label: "Insert", action: function () { insertAlgebra(input, true); } }], true);
          showKeyboard($("#alg-quickexpr"));
          $("#alg-quickexpr").algebraicInput("handlers", {
            enter: function () { insertAlgebra(input, true); }
          });
          $("#alg-quickexpr").algebraicInput("focus");
        }
      });
      ed.addShortcut('alt+m', 'Maths Formula', 'mathsinsert');
      if (typeof onChange === 'function') {
        ed.on('change', onChange);
      }
    }
  });
};

// ----------------------------------
// -- INPUT WITH INSERTABLE MATHS  --
// ----------------------------------

jQuery.fn.dfmInputWithInsertableMaths = function () {
  var input = $(this);
  input.keydown(function (event) {
    if ((event.keyCode == 187 || event.keyCode == 77 || event.keyCode == 61) && event.altKey) { // 61 in Firefox
      dfmDialog("Press the Return key when done or click the button below.<br><br><span id='alg-quickexpr' name='alg-quickexpr' style='width:200px;padding:10px'></span>",
        [{ label: "Insert", action: function () { insertAlgebra(input, false); } }], true);
      showKeyboard($("#alg-quickexpr"));
      $("#alg-quickexpr").algebraicInput("handlers", {
        enter: function () { insertAlgebra(input, false); }
      });
      $("#alg-quickexpr").algebraicInput("focus");
    }
  });
}

export function insertAlgebra(input, useMCE) {
  var latex = $("#alg-quickexpr").algebraicInput("latex");
  if (!useMCE) {
    input.focus();
    input.val(input.val() + "[m]" + latex.replaceAll("<", " < ") + "[/m]"); // inserts at end for the moment
  } else {
    tinyMCE.activeEditor.execCommand('mceInsertContent', false, "[m]" + latex.replaceAll("<", " < ") + "[/m]");
  }
  closeKeyboard();
  $.modal.close();
}

String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};



// ----------------------------------------------------------------
// -- INPUT WHICH WAITS THEN EXECUTES FUNCTION          -----------
// ----------------------------------------------------------------

var emSearchBar_pendingQueryTimerId;
var dfmInput_waitTime = 500;
jQuery.fn.dfmWaitingInput = function (callback, immediate) {
  var input = $(this);
  input.on('input', function () {
    if (typeof immediate === 'function') {
      immediate();
    }
    if (input.data("timerId")) {
      clearTimeout(input.data("timerId"));
    }
    var timerId = setTimeout(function () {
      callback();
      input.removeData("timerId");
    }, dfmInput_waitTime);
    input.data("timerId", timerId);
  });
}





// -------------------------------
// -- TABS                      --
// -------------------------------

jQuery.fn.dfmTabs = function (optionsKey, optionsVal) {
  var div = $(this);

  if (optionsKey == "selectTab") {
    var panelContainer = div.data('panelContainer');
    panelContainer.children("div.dfm_TabsWindow").children().hide();
    panelContainer.children("div").children("div[id='" + optionsVal + "']").show();
    div.children("ul").children("li").removeClass("selected");
    div.children("div").children("ul").children("li").removeClass("selected"); // hack incase ul is wrapped in a div.
    div.children("ul").children("li").children("a[href='#" + optionsVal + "']").parent().addClass("selected");
    div.children("div").children("ul").children("li").children("a[href='#" + optionsVal + "']").parent().addClass("selected"); // again hack
    if (div.data('listener')) div.data('listener')();
  } else if (optionsKey == "getTab") {
    return div.find('li.selected').find('a').attr('href').substr(1);
  } else if (optionsKey == "setListener") {
    div.data('listener', optionsVal);
  } else {
    div.addClass("dfm_Tabs");
    var panelContainer = div;
    if (optionsKey && typeof optionsKey !== 'string') {
      panelContainer = optionsKey;
    }
    div.data('panelContainer', panelContainer);

    panelContainer.children("div").wrapAll("<div class='dfm_TabsWrapper'/>");
    panelContainer.children("div").children().hide();
    panelContainer.children("div").addClass("dfm_TabsWindow");
    var tabName = div.children("ul").children("li").children("a").first().attr('href').substring(1); // ignore leading #
    div.children("ul").children("li").children("a").each(function () {
      $(this).click(function (e) {
        e.preventDefault();
        div.dfmTabs("selectTab", $(this).attr('href').substring(1));
      });
    });
    div.children("ul").addClass("dfm_TabsUL");
    div.dfmTabs("selectTab", tabName);
  }
}


// ----------------------------------------------------------------
// -- LEVEL BAR                                        -----------
// ----------------------------------------------------------------

var levelAnimationId;
jQuery.fn.dfmLevelBar = function (arg1, arg2) {
  var span = $(this);
  if (arg1 == "giveColour") {
    span.addClass("colour");
    return;
  }

  if (!arg2) {
    if (!arg1) arg1 = parseInt(span.html());
    span.empty();
    span.addClass("dfm-levelbar");
    for (var i = 1; i <= 4; i++) {
      span.append("<span" + (i == arg1 ? " class='selected'" : "") + ">" + i + "</span>");
      if (i == arg1) span.data("oldVal", i);
    }
  }
  else if (arg1 == "val") {
    if (levelAnimationId) {
      clearInterval(levelAnimationId);
      levelAnimationId = undefined;
    }
    span.next(".dfm-levelbar-levelup").remove();
    var oldVal = span.data("oldVal");
    if (arg2 != oldVal) {
      span.find(".selected").removeClass("selected");
      setTimeout(function () {
        span.children(":nth-child(" + arg2 + ")").addClass("selected");
        setTimeout(function () {
          if (arg2 > oldVal) {
            span.after("<span class='dfm-levelbar-levelup'>Level Up!</span>");
            span.next().fadeToggle();
            levelAnimationId = setInterval(function () { span.next().fadeToggle(); }, 1000);
          }
        }, 500);
      }, 800);
    }
    span.data("oldVal", arg2);
  }
}

// ----------------------------------------------------------------
// -- ACCURACY BAR                                        -----------
// ----------------------------------------------------------------

jQuery.fn.dfmAccuracyBar = function (arg1) {
  let accuracy = null;
  let span = $(this);

  if ($(this).html() !== "") {
    arg1 = $(this).html();
  }
  if (arg1.indexOf("/") > 0) {
    let parts = arg1.split("/");
    accuracy = 100 * parseFloat(parts[0]) / parseFloat(parts[1]);
  } else {
    accuracy = parseFloat(arg1);
    span.html(Math.round(accuracy) + "%");
  }
  span.addClass("dfm-accuracybar");
  span.css('background-color', getAccuracyColour(accuracy));
}

export function getAccuracyColour(a) {
  if (a === -1 || a === null || a === undefined) {
    return "#ddd";
  }

  a = Math.max(0, a - 10) * 1;
  var h = Math.floor(a);
  var s = 0.9;
  var v = 0.9;
  return hsv2rgb(h, s, v);
}



var hsv2rgb = function (h, s, v) {
  // adapted from http://schinckel.net/2012/01/10/hsv-to-rgb-in-javascript/
  var rgb, i, data = [];
  if (s === 0) {
    rgb = [v, v, v];
  } else {
    h = h / 60;
    i = Math.floor(h);
    data = [v * (1 - s), v * (1 - s * (h - i)), v * (1 - s * (1 - (h - i)))];
    switch (i) {
      case 0:
        rgb = [v, data[2], data[0]];
        break;
      case 1:
        rgb = [data[1], v, data[0]];
        break;
      case 2:
        rgb = [data[0], v, data[2]];
        break;
      case 3:
        rgb = [data[0], data[1], v];
        break;
      case 4:
        rgb = [data[2], data[0], v];
        break;
      default:
        rgb = [v, data[0], data[1]];
        break;
    }
  }
  return '#' + $.map(rgb, function (x) {
    return ("0" + Math.round(x * 255).toString(16)).slice(-2);
  }).join('');
};



// ----------------------------------------------------------------
// -- KEY SKILL METER                                        -----------
// ----------------------------------------------------------------


jQuery.fn.dfmKSMeter = function (from, to) {
  var input = $(this);
  $(this).html("");
  $(this).addClass('dfmKSMeter');
  for (var i = 1; i <= 10; i++) {
    $(this).append("<span id='" + i + "' " + (i <= from ? "class='filled'" : "") + "></span>");
  }
  if (from == 5 && to == 6) $(this).append("<label>Skill complete!</label>");
  else if (from == 9 && to == 10) $(this).append("<label>Master level acquired</label>");
  if ((from == 5 && to == 6) || (from == 9 && to == 10)) {
    $(this).find('label').fadeIn();
    setInterval(function () {
      input.find('label').fadeToggle();
    }, 1000);
  }
  $(this).find("#6").addClass('complete');
  $(this).find("#10").addClass('master');

  if (to) {
    setTimeout(function () { input.find("#" + to).addClass('filled'); }, 400);
  }

};


// ----------------------------------------------------------------
// -- Multi accuracy bar
// ----------------------------------------------------------------

jQuery.fn.dfmMultiAccuracyBar = function (arg1) {
  var span = $(this);
  if ($(this).html() != "") {
    arg1 = $(this).html() ? $(this).html().split(",") : [-1, -1, -1, -1, -1];
    for (var i = 0; i <= 4; i++)arg1[i] = parseFloat(arg1[i]);
  }
  span.addClass("dfm-multiaccuracybar");
  span.empty();
  if (span.data("overall")) span.append("<span class='head overall-head' style='width:40px'></span>");
  for (var i = 1; i <= 4; i++) {
    span.append("<span class='head'>Lvl" + i + "</span>");
  }
  span.append("<br>");
  for (var i = (span.data("overall") ? 0 : 1); i <= 4; i++) {
    var acc = arg1[i] == -1 || arg1[i] == null || arg1[i] == undefined ? "-" : Math.round(arg1[i]) + "%";
    var txt = acc;
    if (span.data("numcompleted")) {
      var numcorrect = 0;
      if (span.data("numcompleted")[i] > 0) numcorrect = Math.round((arg1[i] / 100.0) * span.data("numcompleted")[i]);
      if (i == 0 && !arg1[0]) {
        // overall accuracy may not be set - so calculate from scratch
        numcorrect = 0;
        for (var j = 1; j <= 4; j++)numcorrect += Math.round((arg1[j] / 100.0) * span.data("numcompleted")[j]);
      }
      txt = numcorrect + "/" + (span.data("max") ? span.data("max")[i] : span.data("numcompleted")[i]);
    }
    if (i == 0) span.append("<span class='overall-accuracy' style='width:40px;font-size:15px'>" + txt + "</span>");
    else span.append("<span>" + txt + "</span>");
    span.children().last().css('background-color', getAccuracyColour(arg1[i]));
    // Hack for the moment when numcompleted=0 but accuracy for some reason is 0!
    if (span.data("numcompleted") && span.data("numcompleted")[i] == 0) span.children().last().css('background-color', '#ddd');
  }
  if (span.data("overall")) span.css('width', '166px');
  // if(span.data("numcompleted"))console.log("NUMCORRECT: "+JSON.stringify(span.data("numcompleted"))+" ACCURACIES: "+JSON.stringify(arg1));
}




/*
  Creates an HTML element to show an accuracy on a task.
  Params: arg1 = {  numCompleted    - array of length 1 of 5. If length 5, 0th element is overall and 1th-4th breakdown by difficulty
                    numCorrect     - as above
                    isPercentage   - if true, e.g. 60%. If false, e.g. 3/5.
                    repressColour  - if true, show as grey rather than colour. Useful e.g. if assessment is incomplete.
         }
*/
jQuery.fn.dfmAccuracyBarNew = function (arg1) {
  if (typeof arg1.numCompleted == 'string') arg1.numCompleted = parseInt(arg1.numCompleted);
  if (typeof arg1.numCorrect == 'string') arg1.numCorrect = parseInt(arg1.numCorrect);
  if (typeof arg1.numCompleted == 'number') arg1.numCompleted = [arg1.numCompleted]; // if not given as an array.
  if (typeof arg1.numCorrect == 'number') arg1.numCorrect = [arg1.numCorrect];

  var span = $(this);
  var isMultiAccuracy = arg1.numCompleted.length > 1;
  span.addClass(isMultiAccuracy ? "dfm-multiaccuracybar" : "dfm-accuracybar");
  span.empty();
  if (isMultiAccuracy) {
    // If some activity on homework but difficulty breakdown is completely blank (e.g. because all Key Skill questions), then ignore difficulty breakdown.
    var ignoreBreakdown = arg1.numCompleted[0] > 0 && arg1.numCompleted[1] == 0 && arg1.numCompleted[2] == 0 && arg1.numCompleted[3] == 0 && arg1.numCompleted[4] == 0;
    span.append("<span class='head overall-head' style='width:40px'></span>");
    if (!ignoreBreakdown) for (var i = 1; i <= 4; i++) {
      span.append("<span class='head'>Lvl" + i + "</span>");
    }
    span.append("<br>");
    for (var i = 0; i <= (ignoreBreakdown ? 0 : 4); i++) {
      var txt = "-";
      if (arg1.numCompleted[i] != -1 && arg1.numCompleted[i] != undefined && arg1.numCompleted[i] != null) {
        if (arg1.isPercentage) txt = arg1.numCompleted[i] == 0 ? "-" : Math.round(100.0 * arg1.numCorrect[i] / arg1.numCompleted[i]) + "%";
        else txt = arg1.numCorrect[i] + "/" + arg1.numCompleted[i];
      }
      if (i == 0) span.append("<span class='overall-accuracy' style='width:40px;font-size:15px'>" + txt + "</span>");
      else span.append("<span>" + txt + "</span>");
      span.children().last().css('background-color', getAccuracyColourNew(arg1.numCorrect[i], arg1.repressColour ? 0 : arg1.numCompleted[i]));

    }
    span.css('width', '166px');
  } else {
    // Single accuracy statistic (no difficulty breakdown)
    var txt = "-";
    if (arg1.numCompleted[i] != -1 && arg1.numCompleted[0] != undefined && arg1.numCompleted[0] != null) {
      if (arg1.isPercentage) txt = arg1.numCompleted[0] == 0 ? "-" : Math.round(100.0 * arg1.numCorrect[0] / arg1.numCompleted[0]) + "%";
      else txt = arg1.numCorrect[0] + "/" + arg1.numCompleted[0];
    }
    span.html(txt);
    span.css('background-color', getAccuracyColourNew(arg1.numCorrect[0], arg1.repressColour ? 0 : arg1.numCompleted[0]));

  }
}

export function getAccuracyColourNew(numCorrect, numCompleted) {
  if (numCompleted == -1 || numCompleted == null || numCompleted == undefined || numCompleted == 0) return "#ddd";

  var a = 100.0 * numCorrect / numCompleted;
  a = Math.max(0, a - 10) * 1;
  var h = Math.floor(a);
  var s = 0.9;
  var v = 0.9;
  return hsv2rgb(h, s, v);
}




// ----------------------------------------------------------------
// -- POINTS BAR                                        -----------
// ----------------------------------------------------------------

// Most of the code is to ensure the number is still visible
// if the black bar is going halfway through it.

jQuery.fn.dfmPointsBar = function (arg1, arg2, arg3) {
  var max = 100.0;
  if (arg3) max = parseFloat(arg3);
  var span = $(this);
  if (!arg2 && arg1 != "val") {
    span.addClass('dfm-pointsbar');
    if (arg1 == undefined) arg1 = parseFloat(span.html());
    span.empty();
    span.append("<span class='dfm-pointsbar-textblack'><span>" + Math.round(arg1) + "</span></span>");
    span.append("<span class='dfm-pointsbar-filled'><span class='main'></span><span class='added'></span><span class='subtracted'></span></span>");
    span.append("<span class='dfm-pointsbar-textwhite'><span>" + Math.round(arg1) + "</span></span>");
    span.children().first().children("span").width(span.width());
    span.children().first().next().width(span.width());
    span.find(".main").width(span.width() * arg1 / max);
    span.children().last().children("span").width(span.width());
    span.children().last().width(span.width() * arg1 / max);
    span.data("oldValue", arg1);
    span.data("max", max);
  }
  else if (arg1 == "val") {

    span.find(".added").width("0%");
    span.find(".subtracted").width("0%");
    var oldValue = span.data("oldValue");
    var diff = arg2 - oldValue;
    if (diff > 0) span.find(".added").animate({ width: diff + "%" },
      {
        duration: 1000,
        step: function (now, fx) {
          span.children().last().width(span.width() * (now + oldValue) / span.data("max")); // clips
        },
        complete: function () {

          span.find(".main").animate({ width: arg2 + "%" },
            {
              duration: 1000,
              step: function (now, fx) {
                var valTxt = String(Math.round(now));
                span.children().first().children().html(valTxt);
                span.find(".added").width(span.width() * (arg2 - now) / span.data("max"));
                span.children().last().children().html(valTxt);
              },
              complete: function () {
                span.data("oldValue", arg2);
              }
            });

        }
      });
    else if (diff < 0) span.find(".main").animate({ width: arg2 + "%" },
      {
        duration: 1000,
        step: function (now, fx) {
          span.find(".subtracted").width(span.width() * (oldValue - now) / span.data("max"));
        },
        complete: function () {

          span.find(".subtracted").animate({ width: "0%" },
            {
              duration: 1000,
              step: function (now, fx) {
                var valTxt = String(Math.round(now + arg2));
                span.children().first().children().html(valTxt);
                span.children().last().children().html(valTxt);
                span.children().last().width(span.width() * (now + arg2) / span.data("max")); // clips
              },
              complete: function () {
                span.data("oldValue", arg2);
              }
            });

        }
      });

  }

}

// ----------------------------------------------------------------
// -- TIME FUNCTIONS                                    -----------
// ----------------------------------------------------------------


export function timeSince(time) {

  if (!time || time == 0) return "-";

  var utc_timestamp = new Date().getTime() / 1000.0;

  var time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  var seconds = utc_timestamp - time,
    token = 'ago', list_choice = 1;

  if (seconds == 0) {
    return 'Just now'
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  var i = 0, format;
  while (format = time_formats[i++])
    if (seconds < format[0]) {
      if (typeof format[2] == 'string')
        return format[list_choice];
      else
        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
  return time;
}

export function convertToMinsSecs(t, includeSeconds, isTextual) {
  t = Math.round(t); // occasionally weird problems where time in secs is not an integer (but always should be).
  if (!t && t != 0) return "-";
  var negated = false;
  if (t < 0) {
    t = -t; negated = true;
  }
  var hours = Math.floor(t / 3600);
  var minutes = Math.floor(t / 60) % 60;
  var seconds = t % 60;
  if (!isTextual) {
    if (seconds < 10) seconds = "0" + seconds;
    if (hours != 0 && minutes < 10) minutes = "0" + minutes;
    if (includeSeconds) return hours == 0 ? minutes + ":" + seconds : hours + ":" + minutes + ":" + seconds;
    if (hours == 0) return (negated ? "-" : "") + minutes + " min" + (minutes != 1 ? "s" : "");
    else return hours + "h " + minutes + "m";
  } else {
    if (includeSeconds && seconds >= 30) minutes++;
    if (hours == 0 && minutes == 0) return seconds + " sec" + (seconds != 1 ? "s" : "");
    if (includeSeconds) return hours == 0 ? minutes + " min" + (minutes != 1 ? "s" : "") + " " + seconds + " secs" : hours + " hr " + minutes + " min" + (minutes != 1 ? "s" : "") + " " + seconds + " secs";
    if (hours == 0) return (negated ? "-" : "") + minutes + " min" + (minutes != 1 ? "s" : "");
    else return hours + "h " + minutes + "m";
  }
}

export function getCurrentTerm(sow) {
  var terms = getTermsPriorToCurrent(sow);
  console.log("PRIOR TERMS: " + JSON.stringify(terms));
  if (terms.length == 0) return undefined;
  else return terms[terms.length - 1].name;
}

export function getTermsPriorToCurrent(sow) {
  var terms = [];
  var dNOW = { month: (new Date()).getMonth() + 1, day: (new Date()).getDate() };
  var dSOW = { month: sow.month, day: sow.day };
  for (var i = 0; i < sow.terms.length; i++) {
    var term = sow.terms[i];
    var dTERM = { month: term.month, day: term.day }
    if (term.name == "prereq" || (isDateLE(dSOW, dTERM) && isDateLE(dTERM, dNOW))
      || (isDateLE(dNOW, dSOW) && isDateLE(dSOW, dTERM))
      || (isDateLE(dTERM, dNOW) && isDateLE(dNOW, dSOW))) {
      terms.push(term);
    }
  }
  return terms;
}

export function isDateLE(d1, d2) {
  if (d1.month < d2.month) return true;
  if (d1.month == d2.month && d1.day <= d2.day) return true;
  return false;
}

// ----------------------------------------------------------------
// -- PROGRESS LINE GRAPH
// ----------------------------------------------------------------

jQuery.fn.dfmProgressLineGraph = function (past) {
  var canvas = $(this);
  if (past === "redraw") {
    drawPastPerformance(canvas);
  } else {
    if (past) past.reverse();
    canvas.addClass("dfmProgressLineGraph");
    canvas.data("past", past);
    drawPastPerformance(canvas);
  }
}

export function drawPastPerformance(canvas) {
  var past = canvas.data("past");
  var ctx = canvas[0].getContext("2d");
  var w = canvas.width();
  var h = canvas.height();
  ctx.fillStyle = "#fff";
  ctx.fillRect(0, 0, w, h);
  var thresholds = [25, 50, 75];
  ctx.beginPath();
  ctx.strokeStyle = "rgb(181,230,29)";
  ctx.setLineDash([3, 5]);
  ctx.lineWidth = 1;
  for (var i = 1; i <= 3; i++) { // horizontal lines
    ctx.moveTo(0, h * (thresholds[i - 1] / 100.0));
    ctx.lineTo(w, h * (thresholds[i - 1] / 100.0));
  }
  ctx.stroke();

  ctx.fillStyle = "rgb(181,230,29)";
  ctx.font = "20px Helvetica";
  for (var i = 1; i <= 4; i++) {
    ctx.fillText(i, 5, 6 + (4.5 - i) * h / 4.0);
  }

  ctx.lineWidth = 2;
  ctx.setLineDash([1, 0]); // solid
  ctx.strokeStyle = "#555";
  ctx.beginPath();
  // if(past.length<20)past.unshift({points:0});
  if (past) for (var i = 0; i < past.length; i++) {
    if (i == 0) ctx.moveTo(25, Math.min(h - 2, h * (1 - past[i].points / 100.0)));
    else ctx.lineTo(25 + (w - 25) * i / (20 - 1), Math.min(h - 2, h * (1 - past[i].points / 100.0)));
  }
  ctx.stroke();
}






// ----------------------------------------------------------------
// -- STUDENT/CLASS SELECTOR
// ----------------------------------------------------------------

// DEVELOPER NOTE: This appears to be the old version. Check it's not still in use and decommission in favour of the 'studentSelector' component.

jQuery.fn.dfmStudentClassSelector = function (options, options2) {
  var input = $(this);
  if (options == "val") {
    input.val(options2);
    dfmStudentClassSelector_initiateClassesAndSelection(input);
    return;
  }

  if (options) input.data("options", options);

  // Constructor
  input.data("selection", []);

  input.after("<div class='dfmStudentClassSelector'></div>");
  d = input.next();

  d.append("<span>Click to select</span>");

  if (input.val()) {
    dfmStudentClassSelector_initiateClassesAndSelection(input)
    // dfmStudentClassSelector_updateFinalSelection();
  }


  d.click(function () {

    var inputTxt = "<div class='studentclass-selector-container'>"
      + "<div class='studentclass-selector-A'><input type='text' id='studentclass-selector-search' autocomplete='off' style='width:110px!important'> <select id='studentclass-selector-class' style='max-width:250px'><option value=''>Select class</option></select><br>"
      + "<div class='studentclass-alldiv'><input type='checkbox' name='studentclass-selectall' id='studentclass-selectall'> <label for='studentclass-selectall'>Select All</label></div>"
      + "<ul id='studentclass-selector-results'></ul></div>"
      + "<div class='studentclass-selector-B'><h1>Current Selection</h1><p>No current selection.</p><ul id='studentclass-selector-selection'></ul></div>"
      + "</div>";
    dfmDialog(inputTxt, [{
      label: "OK", action: function () {
        dfmStudentClassSelector_updateFinalSelection(input);
      }
    }], true);

    $("#message-" + Context.messageCounter).css("min-width", "755px");
    $("input[name=studentclass-selectall]").change(function () {
      var ch = $(this).prop('checked');
      $("#studentclass-selector-results input").each(function () {
        $(this).prop("checked", ch);
        $(this).change();
      });
    });
    $("#studentclass-selector-search").keydown(function () {
      $("#studentclass-selector-class").val("");
      $(".studentclass-alldiv").hide();
      $("#studentclass-selector-results").empty();
    });

    $.ajax({
      url: "/api/class/get_school_classes?repeatUserClassGroups=true",
      context: document.body,
      dataType: "json",
      success: function (data, textStatus, jqXHR) {
        var classes = data.classes;
        input.data("classes", classes);
        var seenOwnClass;
        $.each(classes, function (k, v) {
          if (v.isOwnClass) {
            var vname = v.name;
            if (v.initials) vname += " (" + v.initials + ")";
            $("#studentclass-selector-class").append("<option value='" + v.id + "'>" + vname + "</option>");
            seenOwnClass = true;
          }
        });
        if (seenOwnClass) $("#studentclass-selector-class").append("<option value=''>--------------</option>");

        $.each(classes, function (k, v) {
          var vname = v.name;
          if (v.initials) vname += " (" + v.initials + ")";
          $("#studentclass-selector-class").append("<option value='" + v.id + "'>" + vname + "</option>");
        });

        if (classes.length == 1 && classes[0].name == "My Children") $("#studentclass-selector-class").val(classes[0].id).change();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        dfmAlert("There was an error retrieving the class list:<br><br><strong>" + jqXHR.responseText + "</strong>");
      }
    });
    $("#studentclass-selector-class").change(function () {
      var v = $(this).val();
      $("#studentclass-selector-search").val("");
      dfmStudentClassSelector_doStudentSearch(input);
    });
    $("#studentclass-selector-search").dfmWaitingInput(function () { dfmStudentClassSelector_doStudentSearch(input) });
    dfmStudentClassSelector_populateULFromSelection(input, $("#studentclass-selector-selection"));
  });


}

export function dfmStudentClassSelector_updateFinalSelection(input) {
  console.log("[StudentSelector] UpdateFinalSelection");
  // Need to load classes if not there.
  // console.log("FINAL SELECTION: "+JSON.stringify(input.data("selection")));
  var selectionIds = [];
  $.each(input.data("selection"), function (k, v) {
    selectionIds.push(v.id);
  });
  input.val(selectionIds.join(","));
  dfmStudentClassSelector_populateULFromSelection(input, input.next());
  if (input.data("options") && input.data("options").callback) input.data("options").callback();
}


export function dfmStudentClassSelector_initiateClassesAndSelection(input) {
  $.ajax({
    url: "/api/class/get_school_classes?repeatUserClassGroups=true",
    context: document.body,
    dataType: "json",
    success: function (data, textStatus, jqXHR) {
      var classes = data.classes;
      input.data("classes", classes);
      $.ajax({
        url: "/util-studentsearch.php?uids=" + input.val(),
        context: document.body,
        dataType: "json",
        success: function (data, textStatus, jqXHR) {
          input.data("selection", data.students);
          dfmStudentClassSelector_populateULFromSelection(input, input.next());
        },
        error: function (jqXHR, textStatus, errorThrown) {
          dfmAlert("There was an error retrieving these students:<br><br><strong>" + jqXHR.responseText + "</strong>");
        }
      });

    },
    error: function (jqXHR, textStatus, errorThrown) {
      dfmAlert("There was an error retrieving the class list:<br><br><strong>" + jqXHR.responseText + "</strong>");
    }
  });
}

export function dfmStudentClassSelector_doStudentSearch(input) {
  var classV = $("#studentclass-selector-class").val();
  var searchV = $("#studentclass-selector-search").val();
  var url = "/util-studentsearch.php";
  if (classV) url += "?cid=" + classV + "&include=1" + (Context.user && Context.user.schoolid ? "&sid=" + Context.user.schoolid : "");
  else if (searchV) {
    url += "?search=" + searchV;
    $("#studentclass-selector-class").val("");
  }
  else return;
  console.log(url);
  $.ajax({
    url: url,
    context: document.body,
    dataType: "json",
    success: function (data, textStatus, jqXHR) {
      $(".studentclass-alldiv").show();
      $("#studentclass-selector-results").empty();
      $("input[name=studentclass-selectall]").prop("checked", false);
      $.each(data.students, function (k, v) {
        $("#studentclass-selector-results").append("<li><input type='checkbox' id='studentclass-selector-checkbox-" + v.id + "' name='studentclass-selector-checkbox' value='" + v.id + "'> <label for='studentclass-selector-checkbox-" + v.id + "'>" + v.surname + ", " + v.firstname + (v.ctext ? " " + v.ctext : "") + "</label></li>");
        $("#studentclass-selector-results").children().last().find('input').data("student", v);
        $("#studentclass-selector-results").children().last().find('input').change(function () {
          var ch = $(this).prop('checked');
          if (ch) dfmStudentClassSelector_addToSelection(v, input);
          else dfmStudentClassSelector_removeFromSelection(v, input);
          if ($("#studentclass-selector-results input[type=checkbox]:not(:checked)").length == 0) {
            $("#studentclass-selectall").prop("checked", true);
          } else {
            $("#studentclass-selectall").prop("checked", false);
          }
        });
        // Check selection.
        if (dfmStudentClassSelector_isInSelection(v.id, input.data("selection"))) {
          $("#studentclass-selector-results").children().last().find('input').prop("checked", true);
          if ($("#studentclass-selector-results input[type=checkbox]:not(:checked)").length == 0) {
            $("#studentclass-selectall").prop("checked", true);
          }
        }
      });
    },
    error: function (jqXHR, textStatus, errorThrown) {
      dfmAlert("There was an error when searching for students:<br><br><strong>" + jqXHR.responseText + "</strong>");
    }
  });
}

export function dfmStudentClassSelector_isInSelection(uid, selection) {
  for (var i = 0; i < selection.length; i++)if (selection[i].id == uid) return true;
  return false;
}

export function dfmStudentClassSelector_getStudentLabel(student) {
  if (student.classes.length == 0 && student.ctext) return student.surname + ", " + student.firstname + " " + student.ctext;
  if (student.classes.length == 0) return student.surname + ", " + student.firstname;
  var classNames = [];
  $.each(student.classes, function (k, v) { classNames.push(v.name); });
  return student.surname + ", " + student.firstname + " (" + classNames.join(",") + ")";
}

export function dfmStudentClassSelector_addToSelection(student, input) {
  var classes = input.data("classes");
  var selection = input.data("selection");
  // console.log("CLASSES: "+JSON.stringify(classes));
  if (!dfmStudentClassSelector_selectionContainsStudent(student, selection)) {
    selection.push(student);
  }
  dfmStudentClassSelector_populateULFromSelection(input, $("#studentclass-selector-selection"));
}

export function dfmStudentClassSelector_removeFromSelection(student, input) {
  var classes = input.data("classes");
  var selection = input.data("selection");
  var selectionNew = [];
  for (var i = 0; i < selection.length; i++) {
    if (selection[i].id != student.id) selectionNew.push(selection[i]);
  }
  selection = selectionNew;
  input.data("selection", selection);
  dfmStudentClassSelector_populateULFromSelection(input, $("#studentclass-selector-selection"));
}

export function dfmStudentClassSelector_removeClassFromSelection(cl, input) {
  var classes = input.data("classes");
  var selection = input.data("selection");
  var selectionNew = [];
  for (var i = 0; i < selection.length; i++) {
    var isInClass = false;
    $.each(selection[i].classes, function (k, c) {
      if (c.id == cl.id) isInClass = true;
    });
    if (!isInClass) selectionNew.push(selection[i]);
  }
  selection = selectionNew;
  input.data("selection", selection);
  dfmStudentClassSelector_populateULFromSelection(input, $("#studentclass-selector-selection"));
}


export function dfmStudentClassSelector_selectionContainsStudent(student, selection) {
  for (var i = 0; i < selection.length; i++) {
    if (selection[i].id == student.id) return true;
  }
  return false;
}

export function getClassCount(classes, cid) {
  for (var i = 0; i < classes.length; i++) {
    if (classes[i].id == cid) return classes[i].count;
  }
}

export function dfmStudentClassSelector_populateULFromSelection(input, ul) {

  var selection = input.data("selection");
  var classes = input.data("classes");

  if (ul.prop("tagName") === "UL") {
    if (selection.length != 0) $(".studentclass-selector-B > p").hide();
    else {
      $(".studentclass-selector-B > p").show();
      ul.empty();
      return;
    }
  } else {
    if (selection.length == 0) {
      input.next().html("<span>Click to Select</span>");
      return;
    }
  }

  var allClassesSelected = [];
  var allFullClasses = [];
  $.each(selection, function (k, student) {
    if (!student.cid) $.each(student.classes, function (k, c) {
      if ($.inArray(c.id, allClassesSelected) == -1) allClassesSelected.push(c.id);
    });
  });
  // console.log("ALLSELECTED: "+JSON.stringify(allClassesSelected));
  $.each(allClassesSelected, function (k, cid) {
    var selectionInClassCount = 0;
    $.each(selection, function (k, student) {
      if (!student.cid) $.each(student.classes, function (k, c) {
        if (c.id == cid) selectionInClassCount++;
      });
    });
    // console.log("[Count] cid="+cid+" selectionInClassCount="+selectionInClassCount+" classCount="+getClassCount(classes, cid));
    if (selectionInClassCount == getClassCount(classes, cid)) {
      allFullClasses.push(cid);
    }
  });
  // console.log("FULL: "+JSON.stringify(allFullClasses));
  var toRemoveIds = []; // students not to individually display
  $.each(allFullClasses, function (k, c) {
    $.each(selection, function (k, student) {
      if (!student.cid) $.each(student.classes, function (k, cl) {
        if (cl.id == c) {
          toRemoveIds.push(student.id);
          return false;
        }
      });
    });
  });
  // console.log("STUDENTSTONOTDISPLAY: "+JSON.stringify(toRemoveIds));

  ul.empty();
  $.each(allFullClasses, function (k, c) {
    var cl = dfmStudentClassSelector_getClass(classes, c);
    if (ul.prop("tagName") === "UL") {
      ul.append("<li id='studentclass-selection-c" + c + "'>All of " + cl.name + " (" + cl.count + ") <a href='#'>&times;</a></li>");
      $("#studentclass-selection-c" + c + " a").click(function () {
        dfmStudentClassSelector_removeClassFromSelection(cl, input);
      });

    }
    else ul.append("<span id='studentclass-selection-c" + c + "'>All of " + cl.name + " (" + cl.count + ")</span>");
  });
  input.data("fullclasses", allFullClasses);
  for (var i = 0; i < selection.length; i++) {
    if ($.inArray(selection[i].id, toRemoveIds) == -1) {
      if (ul.prop("tagName") === "UL") {
        ul.append("<li id='studentclass-selection-" + selection[i].id + "'>" + dfmStudentClassSelector_getStudentLabel(selection[i]) + " <a href='#'>&times;</a></li>");
        $("#studentclass-selection-" + selection[i].id + " a").data("student", selection[i]);
        $("#studentclass-selection-" + selection[i].id + " a").click(function () {
          var s = $(this).data("student");
          dfmStudentClassSelector_removeFromSelection(s, input);
        });
      }
      else ul.append("<span id='studentclass-selection-" + selection[i].id + "'>" + dfmStudentClassSelector_getStudentLabel(selection[i]) + "</span>");
    }

  }
}

export function dfmStudentClassSelector_getClass(classes, cid) {
  for (var i = 0; i < classes.length; i++) {
    if (classes[i].id == cid) return classes[i];
  }
}


// ------------------------------------------
// Make the elements in an array unique.

Array.prototype.unique = function () {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j])
        a.splice(j--, 1);
    }
  }
  return a;
};


export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function toFixed(num, precision) {
  return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

// ------------------------------------
// -- Report Question Error
// ------------------------------------

export function reportQuestionError(qid, aaid, qnum) {
  dfmDialog("Describe the error that you found. <strong>Please note that this facility is for reporting mistakes on questions and will be reviewed by a member of the Dr Frost Learning staff.</strong><br><br><textarea name='message' style='width: 430px;height: 140px'></textarea>",
    [{
      label: "Send", action: function () {
        var message = $("textarea[name=message]").val();
        if ($.trim(message) === "") {
          dfmAlert("No detail provided.");
          return;
        }

        var data = { qid: qid, uid: Context.user.uid, message: message };
        if (aaid) {
          // Error report in the context of a task.
          data.aaid = aaid;
          data.qnum = qnum;
        }
        $.ajax({
          url: "/api/questions/error-report",
          type: "POST",
          contentType: 'application/json',
          processData: false,
          cache: false,
          data: JSON.stringify(data),
          dataType: "json",
          success: function (data) {
            dfmAlert("Thank you for reporting this issue. Our team will investigate this as soon as possible.");
          },
          error: function (j, textStatus, errorThrown) {
            if (j.responseJSON?.message === "QUESTION_NOT_FOUND") {
              dfmAlert("The question this error is being reported on could not be found.");
            } else {
              let msg = "An error occurred";
              msg += j.responseText ? `: <strong>${j.responseText}</strong>` : ".";
              dfmAlert(msg);
            };
          }
        });

      }
    }], true
  );
}


// ----------------------------------------------------------------
// -- DESMOS GRAPH
// -- note: a lot of this code seems to be duplicated from util-questionutils-new.js
// ----------------------------------------------------------------

jQuery.fn.dfmDesmosLine = function (options) {
  if ($(this).length == 0) return;
  // console.log("[DesmosLine] "+JSON.stringify(options));
  var answerData = options.answerData;
  var answer = options.answer;
  console.log("Answer: " + JSON.stringify(answer));

  var elt = $(this)[0];
  var showGrid = answerData.showGrid != undefined ? answerData.showGrid : true;
  var options = !showGrid ? { expressions: false, lockViewport: true, settingsMenu: false, showGrid: false, showXAxis: false, showYAxis: false, xAxisNumbers: false, yAxisNumbers: false, border: false, trace: false }
    : { expressions: false, lockViewport: true, settingsMenu: false, showGrid: true, xAxisNumbers: showGrid && answerData.mode != "barchart", yAxisNumbers: showGrid, xAxisArrowMode: 'POSITIVE', yAxisArrowMode: 'POSITIVE', xAxisLabel: answerData.xaxis.label ? answerData.xaxis.label : 'x', yAxisLabel: !answerData.yaxis ? "" : (answerData.yaxis.label ? answerData.yaxis.label : 'y'), 'xAxisStep': answerData.xaxis.step ? parseFloat(answerData.xaxis.step) : 1, 'yAxisStep': !answerData.yaxis ? 1 : (answerData.yaxis.step ? parseFloat(answerData.yaxis.step) : 5), border: false, trace: false };
  var calculator = Desmos.GraphingCalculator(elt, options);

  // var dOptions = {	expressions: false, lockViewport:true, settingsMenu: false, showGrid:true, xAxisNumbers:true, yAxisNumbers:true, xAxisArrowMode:'POSITIVE', yAxisArrowMode:'POSITIVE', xAxisLabel: answerData.xaxis.label ? answerData.xaxis.label : 'x', yAxisLabel: !answerData.yaxis ? "" : (answerData.yaxis.label ? answerData.yaxis.label : 'y'), 'xAxisStep': answerData.xaxis.step ? answerData.xaxis.step : 1, 'yAxisStep': !answerData.yaxis ? 1 : (answerData.yaxis.step ? answerData.yaxis.step : 5), border:false, trace:false, };
  // var calculator = Desmos.GraphingCalculator(elt, dOptions);
  $(this).data("calculator", calculator);

  // Compile the additional expressions to be rendered on the correct answer graph.
  const expressions = [
    { data: answerData.nonanswershapes, prefix: 'extra'},
    { data: answerData.expressionsOnlyOnAnswer, prefix: 'extraAns'},
  ];
  // Render each of the expressions onto the graph.
  expressions.forEach(({ data, prefix }) => {
    if (data) {
        data.forEach((expression, index) => {
            expression.id = `${prefix}${index}`;
            calculator.setExpression(expression);
        });
    }
  });

  if (answerData.mode == "multiline") {
    answerData.mode = "geometric"; // legacy
    answerData.closed = false;
  }
  if (answerData.closed === true) answerData.submode = "polygon";
  else if (answerData.closed === false) answerData.submode = "multiline";
  if (!answerData.submode) answerData.submode = "polygon";

  if (answerData.mode === "geometric") {
    if (answerData.submode == "polygon") {
      var allPointsStr = [];
      for (var i = 0; i < answer.length; i++)allPointsStr.push('\\left(' + answer[i].x + ', ' + answer[i].y + '\\right)');
      calculator.setExpression({ id: 'fillC', latex: '\\operatorname{polygon}\\left(' + allPointsStr.join(", ") + '\\right)', color: 'black', lines: false, points: false, fill: true, fillOpacity: 0.5 });
    }
    if (answerData.submode != "points") {
      for (var i = 1; i < answerData.numpoints; i++) {
        calculator.setExpression({ id: 'line' + i, latex: '\\left(' + answer[i - 1].x + ',' + answer[i - 1].y + '\\right), \\left(' + answer[i].x + ',' + answer[i].y + '\\right)', color: 'black', lines: true, points: false });
      }
    }
    // Close the loop if a polygon...
    if (answerData.submode == "polygon") calculator.setExpression({ id: 'lineC', latex: '\\left(' + answer[answer.length - 1].x + ', ' + answer[answer.length - 1].y + '\\right), \\left(' + answer[0].x + ', ' + answer[0].y + '\\right)', color: 'black', lines: true, points: false });
    if (answerData.numpoints == 1 || answerData.submode == "points") {
      if (!answerData.hideAnswerPoints) {
        // No lines, so need to make individual points visible.
        for (var i = 1; i <= answerData.numpoints; i++) {
          calculator.setExpression({ id: 'singlePoint' + i, latex: '\\left(' + answer[i - 1].x + ', ' + answer[i - 1].y + '\\right)', color: 'black', points: true });
        }
      }
    }

  } else if (answerData.mode === "circle") {
    calculator.setExpression({ id: 'v1', latex: 'a=' + answer.centre.x, color: 'black' });
    calculator.setExpression({ id: 'v2', latex: 'b=' + answer.centre.y, color: 'black' });
    calculator.setExpression({ id: 'a3', latex: '\\left(x-a\\right)^{2}+\\left(y-b\\right)^{2}=' + answer.radius + '^2', color: 'black' });

  } else if (answerData.mode === "boxplot") {
    // Connecting lines on box plot:
    calculator.setExpression({ id: 'a1', latex: 'y=2.5\\left\\{\\left[' + answer[0] + ',' + answer[3] + '\\right]\\le x\\le\\left[' + answer[1] + ',' + answer[4] + '\\right]\\right\\}', color: 'black' });
    calculator.setExpression({ id: 'a2', latex: 'x=\\left[' + answer.join(",") + '\\right]\\left\\{1.5\\le y\\le 3.5\\right\\}', color: 'black' });
    calculator.setExpression({ id: 'a3', latex: 'y=\\left[3.5,1.5\\right]\\left\\{' + answer[1] + '\\le x\\le ' + answer[3] + '\\right\\}', color: 'black' });
  } else if (answerData.mode === "barchart") {
    var answerShapesSeen = 0;
    for (var i = 0; i < answerData.bars.length; i++) {
      answerData.bars[i].id = 'extra' + i;

      var isAnswer = !answerData.bars[i].frequency;
      var freq = isAnswer ? answer[answerShapesSeen++] : answerData.bars[i].frequency;
      var color = answerData.bars[i].color ? answerData.bars[i].color : 'blue';
      if (isAnswer && !answerData.bars[i].color) color = 'black'; // default colour of input bars is black

      var points = [];
      points.push("(" + (i + 0.25) + ",0)");
      points.push("(" + (i + 0.75) + ",0)");
      points.push("(" + (i + 0.75) + "," + freq + ")");
      points.push("(" + (i + 0.25) + "," + freq + ")");

      calculator.setExpression({ id: 'fillC' + i, latex: '\\operatorname{polygon}\\left(' + points.join(", ") + '\\right)', color: color, lines: false, points: false, fill: true, fillOpacity: isAnswer ? 0.5 : 0.8 });
      calculator.setExpression({ id: 'label' + i, latex: '(' + (i + 0.5) + ',-0.5)', color: color, showLabel: true, hidden: true, label: answerData.bars[i].label });

    }


  } else {
    if (answer[0].constructor == ({}).constructor) {
      // Instead of an array of polynomial coefficients, instead an array of {x: ..., y: ...} points is given. This would occur if using a student answer.
      if (answer.length == 2) {
        // 2 points -> Linear

        calculator.setExpression({ id: 'a1', latex: 'x_1=' + answer[0].x, color: '#000' });
        calculator.setExpression({ id: 'a2', latex: 'y_1=' + answer[0].y, color: '#000' });
        calculator.setExpression({ id: 'b1', latex: 'x_2=' + answer[1].x, color: '#000' });
        calculator.setExpression({ id: 'b2', latex: 'y_2=' + answer[1].y, color: '#000' });
        calculator.setExpression({ id: 'a3', latex: '\\left(x_1, y_1\\right)', color: '#000', dragMode: "NONE" });
        calculator.setExpression({ id: 'b3', latex: '\\left(x_2, y_2\\right)', color: '#000', dragMode: "NONE" });
        calculator.setExpression({ id: 'function', latex: 'f\\left(x\\right)=ax + b', color: '#000' });
        calculator.setExpression({ id: 'function1', latex: 'a=\\frac{y_{2} - y_{1}}{x_{2} - x_{1}}', color: '#000' });
        calculator.setExpression({ id: 'function2', latex: 'b=\\y_{1} - a \\cdot x_{1}', color: '#000' });

      }
      else if (answer.length == 3) {
        // 3 points -> Quadratic

        calculator.setExpression({ id: 'a1', latex: 'x_1=' + answer[0].x, color: '#000' });
        calculator.setExpression({ id: 'a2', latex: 'y_1=' + answer[0].y, color: '#000' });
        calculator.setExpression({ id: 'b1', latex: 'x_2=' + answer[1].x, color: '#000' });
        calculator.setExpression({ id: 'b2', latex: 'y_2=' + answer[1].y, color: '#000' });
        calculator.setExpression({ id: 'c1', latex: 'x_3=' + answer[2].x, color: '#000' });
        calculator.setExpression({ id: 'c2', latex: 'y_3=' + answer[2].y, color: '#000' });
        calculator.setExpression({ id: 'a3', latex: '\\left(x_1, y_1\\right)', color: '#000', dragMode: "NONE" });
        calculator.setExpression({ id: 'b3', latex: '\\left(x_2, y_2\\right)', color: '#000', dragMode: "NONE" });
        calculator.setExpression({ id: 'c3', latex: '\\left(x_3, y_3\\right)', color: '#000', dragMode: "NONE" });
        calculator.setExpression({ id: 'function', latex: 'f\\left(x\\right)=ax^{2}+bx+c', color: '#000' });
        calculator.setExpression({ id: 'function1', latex: 'A_{1}=-x_{1}^{2}+x_{2}^{2}', color: '#000' });
        calculator.setExpression({ id: 'function2', latex: 'B_{1}=-x_{1}+x_{2}', color: '#000' });
        calculator.setExpression({ id: 'function3', latex: 'D_{1}=-y_{1}+y_{2}', color: '#000' });
        calculator.setExpression({ id: 'function4', latex: 'A_{2}=-x_{2}^{2}+x_{3}^{2}', color: '#000' });
        calculator.setExpression({ id: 'function5', latex: 'B_{2}=-x_{2}+x_{3}', color: '#000' });
        calculator.setExpression({ id: 'function6', latex: 'D_{2}=-y_{2}+y_{3}', color: '#000' });
        calculator.setExpression({ id: 'function7', latex: 'B_{multiplier}=-\\left(\\frac{B_{2}}{B_{1}}\\right)', color: '#000' });
        calculator.setExpression({ id: 'function8', latex: 'A_{3}=B_{multiplier}\\cdot A_{1}+A_{2}', color: '#000' });
        calculator.setExpression({ id: 'function9', latex: 'D_{3}=B_{multiplier}\\cdot D_{1}+D_{2}', color: '#000' });
        calculator.setExpression({ id: 'function10', latex: 'a=\\frac{D_{3}}{A_{3}}', color: '#000' });
        calculator.setExpression({ id: 'function11', latex: 'b=\\frac{D_{1}-A_{1}\\cdot a}{B_{1}}', color: '#000' });
        calculator.setExpression({ id: 'function12', latex: 'c=y_{1}-ax_{1}^{2}-bx_{1}', color: '#000' });
      } else if (answer.length == 4) {
        // 4 points -> Cubic

        calculator.setExpression({ id: 'a1', latex: 'x_1=' + answer[0].x, color: '#000' });
        calculator.setExpression({ id: 'a2', latex: 'y_1=' + answer[0].y, color: '#000' });
        calculator.setExpression({ id: 'b1', latex: 'x_2=' + answer[1].x, color: '#000' });
        calculator.setExpression({ id: 'b2', latex: 'y_2=' + answer[1].y, color: '#000' });
        if (answer.length >= 3) calculator.setExpression({ id: 'c1', latex: 'x_3=' + answer[2].x, color: '#000' });
        if (answer.length >= 3) calculator.setExpression({ id: 'c2', latex: 'y_3=' + answer[2].y, color: '#000' });
        if (answer.length >= 4) calculator.setExpression({ id: 'd1', latex: 'x_4=' + answer[3].x, color: '#000' });
        if (answer.length >= 4) calculator.setExpression({ id: 'd2', latex: 'y_4=' + answer[3].y, color: '#000' });

        calculator.setExpression({ id: 'a3', latex: '\\left(x_1, y_1\\right)', color: '#000', dragMode: "NONE" });
        calculator.setExpression({ id: 'b3', latex: '\\left(x_2, y_2\\right)', color: '#000', dragMode: "NONE" });
        calculator.setExpression({ id: 'c3', latex: '\\left(x_3, y_3\\right)', color: '#000', dragMode: "NONE" });
        calculator.setExpression({ id: 'd3', latex: '\\left(x_4, y_4\\right)', color: '#000', dragMode: "NONE" });
        calculator.setExpression({ id: 'function2', latex: 'a\\left(x\\right)= \\left(x - x_2\\right)\\left(x - x_3\\right)\\left(x - x_4\\right)', hidden: true });
        calculator.setExpression({ id: 'function3', latex: 'b\\left(x\\right)= \\left(x - x_1\\right)\\left(x - x_3\\right)\\left(x - x_4\\right)', hidden: true });
        calculator.setExpression({ id: 'function4', latex: 'c\\left(x\\right)= \\left(x - x_1\\right)\\left(x - x_2\\right)\\left(x - x_4\\right)', hidden: true });
        calculator.setExpression({ id: 'function5', latex: 'd\\left(x\\right)= \\left(x - x_1\\right)\\left(x - x_2\\right)\\left(x - x_3\\right)', hidden: true });
        calculator.setExpression({ id: 'function1', latex: 'f\\left(x\\right)= \\left( \\left( a\\left(x\\right) \\cdot \\frac{y_1}{a\\left(x_1\\right)} \\right)  + \\left( b\\left(x\\right) \\cdot \\frac{y_2}{b\\left(x_2\\right)} \\right)  + \\left( c\\left(x\\right) \\cdot \\frac{y_3}{c\\left(x_3\\right)} \\right) + d\\left(x\\right) \\cdot \\frac{y_4}{d\\left(x_4\\right)} \\right)  ', color: '#000' });

      }
    } else {
      if (answerData.order == 1 && answer.length == 2) {
        calculator.setExpression({ id: 'function', latex: 'f\\left(x\\right)=' + answer[0] + 'x + ' + answer[1], color: '#000' });
      } else if (answerData.order == 1) {
        // Vertical straight line
        calculator.setExpression({ id: 'function', latex: 'x=' + answer[2], color: '#000' });
      } else if (answerData.order == 2) {
        calculator.setExpression({ id: 'function', latex: 'f\\left(x\\right)=' + answer[0] + 'x^2 + ' + answer[1] + 'x + ' + answer[2], color: '#000' });
      } else if (answerData.order == 3) {
        calculator.setExpression({ id: 'function', latex: 'f\\left(x\\right)=' + answer[0] + 'x^3 + ' + answer[1] + 'x^2 + ' + answer[2] + 'x + ' + answer[3], color: '#000' });
      }
    }
  }

  if (answerData.mode === "boxplot") calculator.setMathBounds({ left: parseFloat(answerData.xaxis.from), right: parseFloat(answerData.xaxis.to), bottom: -1.3, top: 4 });
  else if (answerData.mode === "barchart") calculator.setMathBounds({ left: -0.2, right: answerData.bars.length + 0.5, bottom: parseFloat(answerData.yaxis.from), top: parseFloat(answerData.yaxis.to) });
  else calculator.setMathBounds({ left: parseFloat(answerData.xaxis.from), right: parseFloat(answerData.xaxis.to), bottom: parseFloat(answerData.yaxis.from), top: parseFloat(answerData.yaxis.to) });

};




// --------------------------------------
// -- DIRECTORY SELECTOR
// --------------------------------------

// options:
//    -- update:
//    -- callback: function to execute once a selection is made.
//    -- default: unused?
//    -- hidden: is the HTML component for selection hidden?
//    -- readOnly: component can't be clicked
//    -- label: The pre-selection label to use for the component (default is 'Load Worksheet')

jQuery.fn.dfmWorksheetSelector = function (options) {
  console.log("[DFMWorksheetSelector] " + JSON.stringify(options));
  var input = $(this);
  if (options && options == "update") {
    // dfmCurrentDir = input.val();
    dfmGetDirectoryAncestors(input);
    return;
  }
  if (options && options == "select") {
    dfmOpenWorksheetSelector(input);
    return;
  }
  var readOnly = options && options.readOnly;
  if (options && options.callback) input.data("callback", options.callback);
  if (options && options.default) input.data("default", options.default);
  // dfmCurrentDir = input.val();
  input.after("<span class='dfm-directoryselector'></span>");
  input.data("currentDir", 1); // 1 is root directory
  if (options && options.hidden) input.next().hide();
  if (!readOnly) input.next().click(function () { dfmOpenWorksheetSelector(input) });

  input.next().html(options && options.label ? options.label : "Load Worksheet");

};


var dfmCurrentDir;
export function dfmOpenWorksheetSelector(input) {
  var dfmCurrentDir = input.data("currentDir");
  var inputTxt = "<div id='dfm-directoryselector-current'><span></span></div>";
  inputTxt += "<ul id='dfm-directoryselector-children'></ul>";
  dfmDialog(inputTxt, [], true);
  $(".modal").css('padding', '0px');
  $(".modal > p").hide();
  dfmOpenWorksheetSelector_load(input, dfmCurrentDir ? dfmCurrentDir : 1);
}

export function dfmOpenWorksheetSelector_load(input, wdid) {
  input.data("currentDir", wdid);
  var url = "/api/worksheets/directory/" + input.data("currentDir");
  console.log(url);
  $("#worksheet-fileexplorer ul").empty();

  $.ajax({
    url: url,
    type: "GET",
    context: document.body,
    dataType: "json",
    success: function (data, textStatus, jqXHR) {
      console.log(JSON.stringify(data));
      $("#dfm-directoryselector-children").empty();
      $.each(data._children, function (k, f) {
        // console.log(JSON.stringify(f));
        var img = f.type === "directory" ? "<img src='../homework/img/directory-small.png'>" : "";
        var fid = "db" + (f.wdid ? f.wdid : f.wid);
        $("#dfm-directoryselector-children").append("<li id='wf" + k + "-" + fid + "'>" + img + f.name + "</li>");
        $("#wf" + k + "-" + fid).data("file", f);
        $("#wf" + k + "-" + fid).click(function () {
          console.log("Clicked on worksheet file " + fid);
          if ($(this).data("file").type === "directory") dfmOpenWorksheetSelector_load(input, $(this).data("file").wdid);
          else dfmChooseWorksheet(input, $(this).data("file").wid, $(this).data("file").name);
        });
      });
      if (data._children.length == 0) {
        $("#dfm-directoryselector-children").append("There are no further subdirectories. Navigate upwards above.</span>");
      }

      $("#dfm-directoryselector-current span").empty();
      console.log("Updating ancestors...");
      $.each(data._ancestors, function (k2, anc) {
        $("#dfm-directoryselector-current span").append("/ <a id='anc-" + anc.wdid + "' href='#'>" + (anc.name == "ROOT" ? "DFM" : anc.name) + "</a>");
        $("#anc-" + anc.wdid).data("wdid", anc);
        $("#anc-" + anc.wdid).click(function () {
          dfmOpenWorksheetSelector_load(input, $(this).data("wdid").wdid);
        });
      });
      $("#dfm-directoryselector-current span").append("/ <a id='anc-" + wdid + "' href='#'>" + (data.name == "ROOT" ? "DFM" : data.name) + "</a>");
      $("#anc-" + wdid).data("wdid", wdid);
      $("#anc-" + wdid).click(function () {
        dfmOpenWorksheetSelector_load(input, $(this).data("wdid"));
      });


    },
    error: function (j, textStatus, errorThrown) {
      $.modal.close();
      dfmAlert("There was an error retrieving this directory:<br><br><strong>" + j.responseText + "</strong>");
    }
  });

}

export function dfmChooseWorksheet(input, wid, wtitle) {
  dfmDialogCloseLast();
  if (input.data("callback")) input.data("callback")({ id: wid, title: wtitle });
}



// --------------------------------------
// -- DOWNLOADABLE SELECTOR
// --------------------------------------

/**
 * Renders a modal with functionality to browse relevant courses and
 * select an array of associated downloadables.
 *
 * @param {(selection: Array) => void} onSelect Callback for selection confirmation.
 */
export function dfmDownloadableSelector(onSelect) {
  // Set up an array for the selection of downloadables.
  let selection = [];

  // Set up the dialog element with the initial DOM elements required.
  dfmDialog("<div id='dfm-directoryselector-current'><span></span></div><ul id='dfm-directoryselector-children'></ul>",
    [
      { label: `Use Selected (${selection.length})`, action: () => onSelect(selection) }
    ],
    true
  );

  // Keep a map of directories for breadcrumb links.
  let directories = {};

  const renderPath = function (path) {
    // Remove the current path.
    $("#dfm-directoryselector-current > span").empty();

    // Render each item in the path array, attaching the relevant event handler.
    path.forEach(({ id, label, type }, index) => {
      $("#dfm-directoryselector-current > span").append("<strong style='padding: 0px 4px;'>/</strong>");
      $("#dfm-directoryselector-current > span").append(`<a disabled id='${id}'>${label}</a>`);
      $(`#${id}`).on("click", () => {
        if (index === path.length - 1) return;
        switch (type) {
          case "COURSE":
            renderCourseDirectory(id);
            break;
          case "MODULE":
            renderModuleDirectory(id);
            break;
          case "UNIT":
            renderUnitDirectory(id);
            break;
          case "ROOT":
            renderRootDirectory();
            break;
        }
      });
    });
  }

  const renderRootDirectory = async function () {
    // Get the course list based on the user context.
    const courseGroups = await getCourseGroupsForDropdown({ user: Context?.user })
    const courses = courseGroups.flatMap(({ courses = [] }) => courses);

    // Render the root directory path.
    const rootPath = [{ id: "root", label: "Courses", type: "ROOT" }];
    renderPath(rootPath);

    // Empty the directory contents.
    $("#dfm-directoryselector-children").empty();

    courses?.forEach(course => {
      // Create a directory entry for each course.
      const { authorName, coid, name: courseName, sid } = course;
      const courseDirectoryId = `course-${coid}`;
      const coursePath = [...rootPath, { id: courseDirectoryId, label: courseName, type: "COURSE" }];
      directories[courseDirectoryId] = { ...course, path: coursePath };

      // Build the root directory contents, attaching event handlers.
      $("#dfm-directoryselector-children").append(
        `<li id='${courseDirectoryId}'>${sid === Context?.user?.sid ? "" : `[${authorName}] `}${courseName}</li>`
      );
      MathJax.typeset();
      $(`#${courseDirectoryId}`).on("click", () => {
        renderCourseDirectory(courseDirectoryId);
      });
    });

    // Handle empty directory.
    if (!courses || courses.length === 0) {
      $("#dfm-directoryselector-children").append("<span style='color: grey;'>No courses found<span>");
    }
  }

  const renderCourseDirectory = async function (id) {
    // Lookup the directory to get the course id and path.
    const { coid, path: coursePath = [] } = directories[id];

    // Render the course directory path.
    renderPath(coursePath);

    // Get the modules of the course.
    const { modules = [] } = await getCourse(coid);

    // Empty the directory contents.
    $("#dfm-directoryselector-children").empty();

    modules?.forEach(module => {
      // Create a directory entry for each module.
      const { cmid, name: moduleName, units = [] } = module;
      const moduleDirectoryId = `module-${cmid}`;
      const modulePath = [...coursePath, { id: moduleDirectoryId, label: moduleName, type: "MODULE" }];
      directories[moduleDirectoryId] = { ...module, path: modulePath };

      // Build the contents of the course directory, attaching event handlers.
      $("#dfm-directoryselector-children").append(`<li id='${moduleDirectoryId}'>${moduleName}</li>`);
      MathJax.typeset();
      $(`#${moduleDirectoryId}`).on("click", () => {
        renderModuleDirectory(moduleDirectoryId);
      });
    });

    // Handle empty directory.
    if (!modules || modules.length === 0) {
      $("#dfm-directoryselector-children").append("<span style='color: grey;'>No modules found<span>");
    }
  }

  const renderModuleDirectory = function (id) {
     // Lookup the directory to get the module units and path.
    const { path: modulePath = [], units = [] } = directories[id];

    // Render the module directory path.
    renderPath(modulePath);

    // Empty the directory contents.
    $("#dfm-directoryselector-children").empty();

    units?.forEach(unit => {
      // Create a directory entry for each unit.
      const { cuid, name: unitName } = unit;
      const unitDirectoryId = `unit-${cuid}`;
      const unitPath = [...modulePath, { id: unitDirectoryId, label: unitName, type: "UNIT" }];
      directories[unitDirectoryId] = { ...unit, path: unitPath };

      // Build the contents of the unit directory, attaching event handlers.
      $("#dfm-directoryselector-children").append(`<li id='${unitDirectoryId}'>${unitName}</li>`);
      MathJax.typeset();
      $(`#${unitDirectoryId}`).on("click", () => {
        renderUnitDirectory(unitDirectoryId);
      });
    });

    // Handle empty directory.
    if (!units || units.length === 0) {
      $("#dfm-directoryselector-children").append("<span style='color: grey;'>No units found<span>");
    }
  }

  const renderUnitDirectory = async function (id) {
    // Lookupt the directory to get the unit path.
    const { cuid, path: unitPath = [] } = directories[id];

    // Render the unit directory path.
    renderPath(unitPath);

    // Get the downloadables for the unit.
    const downloadables = await getDownloadablesForCourseUnit(cuid);

    // Empty the directory contents.
    $("#dfm-directoryselector-children").empty();

    downloadables?.forEach((downloadable) => {
      const { rid, title } = downloadable;

      // Create a checkbox element for each downloadable, taking into account whether it is in the current selection.
      const inSelection = selection.some(s => s === rid);
      $("#dfm-directoryselector-children").append(`<li id='resource-${rid}'><label><input ${inSelection ? "checked" : ""} type='checkbox'>${title}</label></li>`);
      MathJax.typeset();

      // Update the selection array and confirm button text when the checkbox element is changed.
      $(`#resource-${rid}`).on("change", (e) => {
        if (e.target.checked) {
          selection = [...selection, downloadable];
        } else {
          selection = selection.filter(d => d.rid !== rid);
        }
        $(".dialog-buttons button").html(`Use Selected (${selection.length})`);
      });
    });

    // Handle empty directory.
    if (!downloadables || downloadables.length === 0) {
      $("#dfm-directoryselector-children").append("<span style='color: grey;'>No downloadables found<span>");
    }
  }

  // Render the root directory when the dialog is first opened.
  renderRootDirectory();
}

// --------------------------------------
// -- EDITABLE TEXT
// --------------------------------------

// options:
//    -- callback: function to execute once a new value is set.

jQuery.fn.dfmEditableText = function (options) {
  var elem = $(this);
  if (options.callback) elem.data("callback", options.callback);
  elem.after("<input class='dfmEditableText' style='display:none'><button class='dfmEditableText' style='display:none'>Update</button>");
  elem.addClass('dfmEditableText_clickable');
  var input = elem.next(); var button = input.next();
  elem.click(function (e) {
    e.stopPropagation();
    elem.hide();
    input.show().focus(); button.show();
    input.val(elem.html());
  });
  button.mousedown(function () {
    var newText = $(this).prev().val();
    elem.html(newText).show();
    $(this).prev().hide(); $(this).hide();
    if (elem.data("callback")) elem.data("callback")(newText, elem);
  });
  input.blur(function () {
    elem.show();
    button.hide(); input.hide();
  });
  input.on('keypress', function (e) {
    if (e.which == 13) button.mousedown();
  });

}


// --------------------------------------
// -- CLASS ALLOCATION SELECTOR
// --------------------------------------
// options JSON:
//     -- callback - a function for when the value is updated

jQuery.fn.dfmClassSelector = function (arg1, arg2) {
  var input = $(this);
  if (!input) return;
  if (arg1 == "updateValue") {
    input.next().empty();
    var tS = input.val();
    var tSArray = [];
    if (tS) tSArray = tS.split(",");
    $.each(tSArray, function (k, v) {
      var n = dfmClassSelector_lookupName(v, classNameList);
      input.next().append("<span>" + n + "</span>");
    });
    if (tSArray.length == 0) input.next().append("<span>NONE</span>");
    return;
  }

  // Constructor
  input.data("options", arg1);

  input.after("<div class='dfmClassSelector'></div>");
  d = input.next();
  input.dfmClassSelector("updateValue");
  d.click(function () {

    dfmDialogSelector({
      items: $.map(classNameList, function (c) { return { val: c.id, text: c.name } }), multiple: true, callback: function (choices) {
        input.next().empty();
        input.val(choices.join(","));
        input.dfmClassSelector("updateValue");
        if (input.data("options").callback) input.data("options").callback(choices);
      }
    });
    // Use current value to check any relevant boxes.
    if (input.val()) {
      $.each(classNameList, function (c, i) {
        if ($.inArray(String(i.id), input.val().split(",")) >= 0) $("#dfmSelector-item-" + c).prop('checked', true);
      });
    }
  });
}

export function dfmClassSelector_lookupName(id, choices) {
  var toReturn;
  $.each(choices, function (k, v) {
    if (v.id == id) {
      toReturn = v.name;
      return false;
    }
  });
  return toReturn;
}



// --------------------------------------
// -- DIALOG SELECTOR
// --------------------------------------
// Allows a selection from a number of items.

// options:
//    -- callback(choice[s]) - an array or single value with the id(s) of the choice(s).
//    -- multiple - a boolean, true if multiple options allowed
//    -- items - an array of {val: ..., text: ...}
//    -- title - message at top of dialog
//    -- description - smaller further explanation text below it
//    -- startSelectAll - if true, all selected (only applicable if 'multiple' is true)

export function dfmDialogSelector(options) {
  var dialogHTML = "";
  if (options.title) dialogHTML += "<h2>" + options.title + "</h2>";
  if (options.description) dialogHTML += "<p>" + options.description + "</p>";

  var iType = options.multiple ? "checkbox" : "radio";
  if (options.multiple) dialogHTML += "<input type='" + iType + "' value='selectall' id='dfmSelector-selectall'><label for='dfmSelector-selectall'>Select All</label><br>";
  dialogHTML += "<ul class='dfmSelector'>";
  console.log("ITEMS: ######### " + JSON.stringify(options.items));
  $.each(options.items, function (i, item) {
    dialogHTML += "<li><input type='" + iType + "' name='dfmSelector-option' value=\"" + item.val + "\" id='dfmSelector-item-" + i + "'><label for='dfmSelector-item-" + i + "'>" + item.text + "</label></li>";
  });
  dialogHTML += "</ul>";

  dfmDialog(dialogHTML, [{
    label: "Continue", action: function () {
      var itemsChosen = [];
      $(".dfmSelector input:checked").each(function () {
        itemsChosen.push($(this).val());
      });
      options.callback(options.multiple ? itemsChosen : itemsChosen[0]);
    }
  }]);
  $("#dfmSelector-selectall").click(function () {
    var checked = $("#dfmSelector-selectall").is(":checked");
    $(".dfmSelector input").prop('checked', checked);
  });
  $(".dfmSelector input").change(function () {
    var allSelected = $(".dfmSelector input:checked").length == $(".dfmSelector input").length;
    $("#dfmSelector-selectall").prop('checked', allSelected);
  });
  if (options.startSelectAll) $("#dfmSelector-selectall").click();
  return false;
}

// dfmDialogSelector({message:"Test", multiple: true, callback: function(items){ console.log(JSON.stringify(items)); }, items: [{val:1, text: "First"},{val:2, text:"Second"}], startSelectAll: true});





// ----------------------------------------------------
// dfmExamBoardSelector
// ----------------------------------------------------
//    Methods:
//     *  val:                set to a particular value, updating the associated HTML element as well.
//     *  setSkillFilter:     if set, the question counts in the popup dialog will only be for that particular skill.
//    Instantiation:
//     *  callback:           a function to call once a board is chosen.
//     *  label:              a default label to use if there is no course selection.
//     *  showAllOptions:     a boolean flag that when set to true will show all available question group options.
// ----------------------------------------------------

const BOARD_ANY = 0;
export const BOARD_ANYEXAM = -1;
const BOARD_USERCONTRIBUTED = -2;
const BOARD_ME = -3;
const BOARD_SCHOOL = -4;

jQuery.fn.dfmExamBoardSelector = function (options, options2) {
  var input = $(this);
  if (options == "val") {
    if (typeof options2 === "string") {
      options2 = dfmOpenExamBoardSelector_legacyConvertVal(options2);
    }

    input.val(JSON.stringify(options2));
    if (options2[0] === BOARD_ANY) input.next().html("<span>Any Author</span>");
    else if (options2[0] === BOARD_ANYEXAM) input.next().html("<span>All exam questions</span>");
    else if (options2[0] === BOARD_USERCONTRIBUTED) input.next().html("<span>User Contributed</span>");
    else if (options2[0] === BOARD_ME) input.next().html("<span>My Questions</span>");
    else if (options2[0] === BOARD_SCHOOL) input.next().html("<span>My school’s questions</span>");
    else if (options2) {
      // Will need to look up course names.
      console.log("[CourseNameLookup] coids=" + options2);
      input.next().addClass('isLoading');
      $.ajax({
        url: "/api/course/labels",
        context: document.body,
        dataType: "json",
        type: "POST",
        data: JSON.stringify({ coids: options2 }),
        success: function (data, textStatus, jqXHR) {
          console.log(" --> " + JSON.stringify(data));
          input.next().html("<p>Showing questions from:<p>");
          $.each(data.courses, function (k, course) { input.next().append("<span>" + course.schoolname + "&rarr;" + course.name + "</span>") });
          if (data.courses.length == 0) {
            input.next().html(options && options.label ? options.label : "Filter by Exam Board");
            input.val("");
          }
          input.next().removeClass('isLoading');
        },
        error: function (j, textStatus, errorThrown) {
          $.modal.close();
          dfmAlert("There was an error retrieving the exam board selection:<br><br><strong>" + j.responseText + "</strong>");
        }
      });
    }
    else if (options2 === undefined) throw new Error("Attempted to set exam board to null value. Use empty string instead.");
    return;
  }
  else if (options == "setSkillFilter") {
    input.data("skill", options2);
    return;
  }
  if (options && options.callback) input.data("callback", options.callback);
  if (options && options.label) input.data("label", options.label);
  if (options && options.showAllOptions) input.data("showAllOptions", options.showAllOptions);

  input.after("<span class='dfmExamBoardSelector" + (options && options.light ? "-light" : "") + "'></span>");
  if (options && options.hidden) input.next().hide();
  input.next().click(function () { dfmOpenExamBoardSelector(input); return false; });
  input.next().html(options && options.label ? options.label : "Filter by Exam Board");

};

export function dfmOpenExamBoardSelector(input) {
  input.next().addClass('isLoading');
  var isSkillFilter = input.data("skill") != undefined;
  $.ajax({
    url: "/util-getexamboardselection.php" + (input.data("skill") ? "?skill=" + input.data("skill") : ""),
    context: document.body,
    dataType: "json",
    success: function (data, textStatus, jqXHR) {
      console.log(JSON.stringify(data));
      var dialogHTML = "";
      dialogHTML += "<div id='dfmExamBoardSelector-wrapper'>";
      dialogHTML += "<h3>Filter by question source</h3>";
      dialogHTML += "<ul id='dfmExamBoardSelector-ul'>";
      dialogHTML += "<li class='dfmExamBoardSelector-type'><label><input id='ddexam' type='radio' name='dfmExamBoardSelector-selection' value='" + BOARD_ANYEXAM + "'>All exam board questions</label></li>";
      dialogHTML += "<li class='dfmExamBoardSelector-type'><label><input id='ddschool' type='radio' name='dfmExamBoardSelector-selection' value='" + BOARD_SCHOOL + "'>My school’s questions</label></li>";
      if (input.data("showAllOptions")) {
        dialogHTML += "<div class='dfmExamBoardSelector-separator'></div>"
        dialogHTML += "<li class='dfmExamBoardSelector-type'><label><input id='ddall' type='radio' name='dfmExamBoardSelector-selection' value='" + BOARD_ANY + "'>Any Author</label></li>";
        dialogHTML += "<li class='dfmExamBoardSelector-type'><label><input id='dduser' type='radio' name='dfmExamBoardSelector-selection' value='" + BOARD_USERCONTRIBUTED + "'>User Contributed</label></li>";
        dialogHTML += "<li class='dfmExamBoardSelector-type'><label><input id='ddme' type='radio' name='dfmExamBoardSelector-selection' value='" + BOARD_ME + "'>Created by Me</label></li>";
      }
      dialogHTML += "<li class='dfmExamBoardSelector-type'><input type='radio' name='dfmExamBoardSelector-selection' value='board' id='ddboard'><label for='ddboard'>Specific exam board course(s)</label></li>";
      dialogHTML += "</ul>";
      dialogHTML += "<div id='examBoardCourseSelection' style='display:none;'>";
      dialogHTML += "<div class='dfmExamBoardSelector-separator'></div>"
      dialogHTML += "<ul></ul>";
      dialogHTML += "</div>";
      dialogHTML += "</div>";
      dfmDialog(dialogHTML, [{
        label: "Filter to Selected", action: function () {
          var coids = [];
          // var itemsChosen = []; var names = [];
          $("#dfmExamBoardSelector-wrapper input:checked").each(function () {
            if ($(this).val() != "board") {
              coids.push(Number($(this).val()));
            }
          });

          // If we've got a special-case negative selection in the first position, we
          // trim the rest of the array
          if (coids[0] < 0) {
            coids.length = 1;
          }

          // If we're filtering by board but haven't actually selected any, it's equivalent
          // to having no filter, so we'll make it explicit.
          if (coids.length === 0 ) {
            coids.push(BOARD_ANYEXAM);
          }

          input.next().removeClass('isLoading');
          input.dfmExamBoardSelector("val", coids);

          if (input.data("callback")) {
            input.data("callback")(coids);
          }
        }
      }], true);
      var currentBoardName;
      $.each(data, function (k, b) {
        var id = b.coid; // b.coid ? b.uid+"-"+b.coid : b.uid;
        if (b.boardname != currentBoardName) $("#examBoardCourseSelection ul").append("<li><h2>" + b.boardname + "</h2></li>");
        currentBoardName = b.boardname;
        $("#examBoardCourseSelection ul").append("<li class='dfmExamBoardSelector-course'><label><input type='checkbox' name='dfmExamBoardSelector-selection' value='" + id + "' id='ebs" + b.coid + "'> " + b.coursename + "<br><small>" + b.count + " questions" + (isSkillFilter ? " for this skill" : "") + "</small></label></li>")

        // $("#dd"+id).data("eb", b);
      });
      if (input.val()) {
        var coids;
        if (input.val().indexOf("[") == -1) {
          // Legacy conversion
          coids = dfmOpenExamBoardSelector_legacyConvertVal(input.val());
        } else {
          coids = JSON.parse(input.val());
        }

        // Update the UI to show the initial/current selection
        if (coids && coids[0] >= 1) {
          // If we've got a real set of coids, select the "board" option and display the list
          $("#ddboard").prop('checked', true);
          $.each(coids, function (k, coid) {
            $("#examBoardCourseSelection ul input[value=" + coid + "]").prop('checked', true);
          });
          $("#examBoardCourseSelection").css('display', 'block');
        } else if (coids && coids[0] < 0) {
          // Otherwise select the appropriate (negative!) "coid"
          $("input[name='dfmExamBoardSelector-selection'][value='" + coids[0] + "']").prop('checked', true);

        } else {
          // Fall back to the default all exam questions
          $("input[name='dfmExamBoardSelector-selection'][value='" + BOARD_ANYEXAM + "']").prop('checked', true);
        }
      }

      // Control visibility of the exam board course list when changing the main selection
      $("input[name='dfmExamBoardSelector-selection']").change(function () {
        const selection = $('input[name="dfmExamBoardSelector-selection"]:checked', '#dfmExamBoardSelector-wrapper').val();
        $("#examBoardCourseSelection").css('display', selection === "board" ? 'block' : 'none')
           });

      // Remove course selections when using a different option (TODO: remove this?)
      // $("#ddall, #ddallexam, #dduser").change(function () {
      //   $("#dfmExamBoardSelector-ul input[type=checkbox]").prop('checked', false);
      // });

      input.next().removeClass('isLoading');
    },
    error: function (j, textStatus, errorThrown) {
      $.modal.close();
      dfmAlert("There was an error retrieving the exam board selection:<br><br><strong>" + j.responseText + "</strong>");
    }
  });
}

export function dfmOpenExamBoardSelector_legacyConvertVal(strVal) {
  var arr = strVal.split(",");
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] == "all") arr[i] = BOARD_ANY;
    else if (arr[i] == "allexam") arr[i] = BOARD_ANYEXAM;
    else if (arr[i] == "user") arr[i] = BOARD_USERCONTRIBUTED;
    else if (arr[i] == "me") arr[i] = BOARD_ME;
    else arr[i] = Number(arr[i]);
  }
  return arr;
}

// --------------------------------------
// School selector
// --------------------------------------

var schoolSearchTimerId;
var schoolSearchBar_waitTime = 200;

jQuery.fn.dfmSchoolSelector = function (options, options2) {
  var input = $(this);
  if (!options2) input.data('options', options);
  input.after("<ul class='dfmSchoolSelector_results'></ul>");
  input.attr('autocomplete', 'off');
  input.next().hide();
  input.keyup(function () {
    if (input.val() != "") {
      if (schoolSearchTimerId) {
        clearTimeout(schoolSearchTimerId);
      }

      schoolSearchTimerId = setTimeout(function () {
        dfmSchoolSelectorSearchSchools(input);
        schoolSearchTimerId = undefined;
      }, schoolSearchBar_waitTime);
    }
  });

  if (options == "val") {
    dfmSchoolSelectorLoadSchool(input, options2);
  }
};


export function dfmSchoolSelectorSearchSchools(input) {
  var url = "/api/school/search/" + input.val();

  $.ajax({
    url: url,
    context: document.body,
    dataType: "json",
    success: function (data, textStatus, jqXHR) {
      const schools = data.schools;
      input.next().html("");


      $.each(schools, function (k, s) {
        input.next().append("<li><a href='#' id='school-" + s.sid + "'><h1>" + (s.thumb ? "<img src='" + s.thumb + "'>" : "<img src='/images/blank.png'>") + s.name + "</h1><h2>" + s.town + "</h2></a></li>");
        $("#school-" + s.sid).click(function () {
          dfmSchoolSelectorSelectSchool(input, $(this).data('school'));
        });
        $("#school-" + s.sid).data('school', s);
      });
      input.next().show();
      input.next().offset({ left: input.offset().left, top: input.next().offset().top });
      input.next().outerWidth(input.outerWidth());
    }
  });
}

export function dfmSchoolSelectorLoadSchool(input, sid) {
  $.ajax({
    url: "/api/school/name?sid=" + sid,
    context: document.body,
    dataType: "json",
    success: function (school) {
      dfmSchoolSelectorSelectSchool(input, school);
    }
  });
}

export function dfmSchoolSelectorSelectSchool(input, s) {
  if (input.data('options') && input.data('options').callback) input.data('options').callback(s);
  input.data('sid', s.sid);
  input.val(s.name);
  input.next().empty().hide();
}

// --------------------------------------
// User selector (mostly intended for admin features). e.g. For selecting a teacher to administer a region.
// --------------------------------------

jQuery.fn.dfmUserSelector = function (options, options2) {
  console.log("[dfmUserSelector] options=" + JSON.stringify(options) + " options2=" + JSON.stringify(options2));
  var input = $(this);
  if (!options2) {
    input.data('options', options);
    input.after("<ul class='dfmSchoolSelector_results' style='display:none'></ul>");
    input.attr('autocomplete', 'off');
    input.next().hide();
    input.keyup(function () {
      console.log(input.val());
      if (input.val() != "") {
        if (schoolSearchTimerId) {
          clearTimeout(schoolSearchTimerId);
        }
        schoolSearchTimerId = setTimeout(function () {
          dfmSchoolSelectorSearchUsers(input);
          schoolSearchTimerId = undefined;
        }, schoolSearchBar_waitTime);
      }
      else input.next().hide();
    });
  }
  if (options == "val") dfmSchoolSelector_loadUser(input, options2);
};

export function dfmSchoolSelector_loadUser(input, uid) {
  var url = "/api/user/other/" + uid;
  console.log(url);
  $.ajax({
    url: url,
    type: "GET",
    context: document.body,
    dataType: "json",
    success: function (data, textStatus, jqXHR) {
      input.next().html("");
      dfmSchoolSelectorSelectUser(input, data, false);
    }
  });
}

// getByUid allows us to immediately load a specific user rather than search by name.
export function dfmSchoolSelectorSearchUsers(input, getByUid) {
  if (!input.val()) return;
  var url = "/utility-homeworksearch.php?restrict=users&" + (getByUid ? "uid=" + getByUid : "query=" + input.val());
  console.log(url);
  $.ajax({
    url: url,
    context: document.body,
    dataType: "json",
    success: function (data, textStatus, jqXHR) {
      input.next().html("");
      if (getByUid) {
        dfmSchoolSelectorSelectUser(input, data.users[0], false);
      } else {
        $.each(data.users, function (k, u) {
          input.next().append("<li><a href='#' id='user-" + u.id + "'><h1>" + u.firstname + " " + u.surname + "</h1>" + (u.school ? "<h2>" + u.school.name + "</h2>" : "") + "</a></li>");
          $("#user-" + u.id).click(function () {
            dfmSchoolSelectorSelectUser(input, $(this).data('user'), true);
          });
          $("#user-" + u.id).data('user', u);
        });
        input.next().show();
        // console.log("LEFT: "+input.offset().left+" TOP: "+
        input.next().offset({ left: input.offset().left, top: input.offset().top + input.outerHeight() });
      }
    }
  });
}

export function dfmSchoolSelectorSelectUser(input, u, useCallback) {
  input.data('uid', u.id);
  input.val(u.firstname + " " + u.surname + (u.school ? " (" + u.school.name + ")" : ""));
  input.next().empty().hide();
  if (input.data('options') && input.data('options').callback && useCallback) input.data('options').callback(u);

}



// ------------------------------------------
// -- Choice selector
// ------------------------------------------
// Allows choice amongst

jQuery.fn.dfmChoiceSelector = function (arg1, arg2) {
  var input = $(this);

  if (arg1 == "updateValue") {
    input.next().empty();
    var tS = input.val();
    var tSArray = [];
    if (tS) tSArray = tS.split(",");
    $.each(tSArray, function (k, v) {
      var n = dfmChoiceSelector_lookupName(v, input.data("options").choices);
      input.next().append("<span>" + n + "</span>");
    });
    if (tSArray.length == 0) input.next().append("<span>NONE</span>");
    return;
  }
  else if (arg1 === "getOptions") {
    return input.data("options");
  }

  // Constructor
  input.data("options", arg1);
  const disabled = arg1.disabled;

  input.after(`<div class='dfmTeacherSelector ${disabled ? "dfmTeacherSelector--disabled" : ""}'></div>`);
  const d = input.next();
  input.dfmChoiceSelector("updateValue");

  if (disabled) {
    d.off("click");
    return;
  }

  d.on("click", function () {
    var inputTxt = "<ul class='dfmTeacherListDialog'>";
    if (input.data("options").choices) $.each(input.data("options").choices, function (k, v) {
      inputTxt += "<li><input type='checkbox' name='teacher-checkbox-" + v.val + "' id='teacher-checkbox-" + v.val + "' value='" + v.val + "'><label for='teacher-checkbox-" + v.val + "'>" + v.label + "</label></li>";
    });
    inputTxt += "</ul>";

    dfmDialog(input.data("options").message + "<br><br>" + inputTxt, [{
      label: "OK", action: function () {
        var vals = [];
        input.next().html("");
        $(".dfmTeacherListDialog input[type=checkbox]:checked").each(function () {
          vals.push($(this).val());
        });
        input.val(vals.join(","));
        input.dfmChoiceSelector("updateValue");
        if (input.data("options").onChange) input.data("options").onChange();

      }
    }], true);
    var tS = input.val();
    var tSArray = [];
    if (tS != "") tSArray = tS.split(",");
    $.each(tSArray, function (k, v) {
      $("input[name=teacher-checkbox-" + v + "]").attr('checked', 'checked');
    });
  });
}

export function dfmChoiceSelector_lookupName(val, choices) {
  var toReturn;
  $.each(choices, function (k, v) {
    if (v.val == val) {
      toReturn = v.label;
      return false;
    }
  });
  return toReturn;
}



// -------------------------------------------------
// --  Student/Class Selector
// -------------------------------------------------

jQuery.fn.studentSelector = function (arg1, arg2) {
  console.log("[StudentSelector] initialise");
  var input = $(this);
  if (arg1 == "setValue") {
    if (!arg2.uids) arg2.uids = undefined; // empty lists cause problem in API
    if (!arg2.cids) arg2.cids = undefined;
    input.val(JSON.stringify(arg2));
    dfmStudentSelector_setValue(input, arg2);
  } else if (arg1 === "reset") {
    input.next().val("");
  } else if (arg1 === "reload") {
    if (input.data('options').classesOnly) dfmStudentSelector_populateClassList(input, false, arg2);
  } else {
    input.data('options', arg1);
    input.after("<div class='dfm_studentSelector'><select class='dfm_studentSelector_classdropdown' style='display:none'></select>"
      + "<select class='dfm_studentSelector_studentdropdown' style='display:none'></select>"
      + "<span class='dfm_studentSelector_selection' style='display:none'></span></div>");
    if (input.data('options').classesOnly) {
      var dropdown = input.next().find('.dfm_studentSelector_classdropdown');
      dropdown.html("<option value=''>Select a class</option>").show();
      dfmStudentSelector_populateClassList(input, false, input.data('options').onLoad);
      dropdown.change(function (e) {
        if (e.target.value) {
          const newValue = isNaN(e.target.value) ? e.target.value : Number(e.target.value);
          input.val(JSON.stringify({ cids: [newValue] }));
        } else {
          input.val('');
        }
        if (input.data('options').onChange) {
          input.data('options').onChange();
        }
      });
    } else {
      // General student(s) and/or class(es) selection.
      var component = input.next().find('.dfm_studentSelector_selection');
      component.show().html("<label>" + (input.data('options').defaultLabel ? input.data('options').defaultLabel : "Click to choose") + "</label><span><img src='/images/chevron_down.svg'></span>");
      component.click(function () {
        dfmStudentSelector_openDialog(input);
      });

      var individualsDropdown = input.next().find('.dfm_studentSelector_studentdropdown');
      individualsDropdown.change(function () {
        if (!$(this).val()) dfmStudentSelector_openDialog(input); // this is the 'More options' option.
        else input.val(JSON.stringify({ uids: [Number($(this).val())] })); // chose a specific student from dropdown
        if (input.data('options').onChange) {
          input.data('options').onChange();
        }
      });

    }
  }
}

export function dfmStudentSelector_openDialog(input) {
  dfmAlert("<div id='dfmStudentSelector-tabs'><ul>"
    + (!isParent() ? "<li><a href='#dfmStudentSelector-singleclass'>Single Class</a></li>" : "")
    + (!isParent() ? "<li><a href='#dfmStudentSelector-multipleclasses'>Multiple Classes</a></li>" : "")
    + "<li><a href='#dfmStudentSelector-individuals'>Individual(s)</a></li>"
    + "</ul>"
    + "<div id='dfmStudentSelector-singleclass'><select></select></div>"
    + "<div id='dfmStudentSelector-multipleclasses'><ul></ul><div><button>Update</button></div></div>"
    + "<div id='dfmStudentSelector-individuals'>"
    + "<div id='dfmStudentSelector-individuals-container'>"
    + "<div id='dfmStudentSelector-individuals-left'>"
    + "<div id='dfmStudentSelector-individuals-filter'>"
    // +"<!-- <input id='dfmStudentSelector-individuals-search' type='text'> -->"
    + (!isParent() ? "<select id='dfmStudentSelector-individuals-class'><option value=''>Select a class</option></select>" : "")
    + "</div>"
    + "<ul></ul>"
    + "</div>"
    + "<div id='dfmStudentSelector-individuals-selection'><h1>Current Selection</h1><ul></ul></div>"
    + "</div>"
    + "<div><button>Update</button></div>"
    + "</div>"
    + "</div>");
  $("#message-" + Context.messageCounter).css("min-width", "600px");
  $("#dfmStudentSelector-tabs").dfmTabs();
  // This data object is what remembers the selection so far.
  $("#dfmStudentSelector-individuals-selection ul").data("studentselection", { students: {}, classes: {} });
  console.log("[DATA] " + JSON.stringify($("#dfmStudentSelector-individuals-selection ul").data("studentselection")));
  if (!isParent()) dfmStudentSelector_populateClassList(input, true);
  $("#dfmStudentSelector-singleclass select").change(function () {
    dfmStudentSelector_setValue(input, { cids: [Number($(this).val())] });
    // input.val(JSON.stringify({cids: [$(this).val()]}));
    // input.next().find('label').html($(this).find('option:selected').text());
    if (input.data('options').onChange) {
      input.data('options').onChange();
    }
    dfmDialogCloseLast();
  });
  $("#dfmStudentSelector-multipleclasses button").click(function () {
    var cids = [];
    var cnames = [];
    $("#dfmStudentSelector-multipleclasses input[type=checkbox]:checked").each(function () {
      cids.push(Number($(this).val()));
      cnames.push($(this).parent().text());
    });
    dfmStudentSelector_setValue(input, { cids: cids });
    // input.val(JSON.stringify({cids: cids}));
    // var component = input.next().find('.dfm_studentSelector_selection');
    // component.find('label').html(cnames.join(", "));
    if (input.data('options').onChange) {
      input.data('options').onChange();
    }
    dfmDialogCloseLast();
  });
  $("#dfmStudentSelector-individuals button").click(function () {
    var selection = $("#dfmStudentSelector-individuals-selection ul").data("studentselection");
    var cids = [];
    var uids = [];
    var names = [];
    $.each(selection.classes, function (k, cl) {
      cids.push(cl.cid);
      names.push(cl.name);
    });
    $.each(selection.students, function (k, student) {
      uids.push(student.uid);
      names.push(student.firstname + " " + student.surname + " " + student.ctext);
    });

    dfmStudentSelector_setValue(input, { cids: cids, uids: uids });
    // input.val(JSON.stringify({cids: cids, uids: uids}));
    // var component = input.next().find('.dfm_studentSelector_selection');
    // component.find('label').html(limitLength(names.join(", "), 80));
    if (input.data('options').onChange) {
      input.data('options').onChange();
    }
    dfmDialogCloseLast();
  });

  // Select the most appropriate tab.
  var currentVal = input.val() ? JSON.parse(input.val()) : {};
  if (currentVal.cids && currentVal.cids.length > 1) {
    if (currentVal.uids && currentVal.uids.length > 0) {
      $("#dfmStudentSelector-tabs").dfmTabs('selectTab', 'dfmStudentSelector-individuals');
    } else {
      $("#dfmStudentSelector-tabs").dfmTabs('selectTab', 'dfmStudentSelector-multipleclasses');
    }
  }
  else if (currentVal.uids && currentVal.uids.length > 0) {
    $("#dfmStudentSelector-tabs").dfmTabs('selectTab', 'dfmStudentSelector-individuals');
  }

  // Do we have any prior selection to fill into the 'Individual(s)' tab?

  if (input.val()) {
    var selectionUL = $("#dfmStudentSelector-individuals-selection ul");
    selectionUL.data('studentselection', input.data('studentselection'));
    dfmStudentSelector_displayIndividualSelection();
  }

  if (isParent()) dfmStudentSelector_loadClassStudents();

  $(window).resize();
}

export function dfmStudentSelector_populateClassList(input, withinDialog, onLoad) {
  console.log("[PopulatingClassList]");
  if (isParent()) {
    var select = withinDialog ? $("#dfmStudentSelector-singleclass select") : input.next().find('.dfm_studentSelector_classdropdown');
    select.empty();
    select.append("<option value=''>My Children</option>");
    return;
  }
  var url = "/api/class/get_school_classes?repeatUserClassGroups=true" + (input.data('options').includeMulticlassGroups ? "&includeMulticlassGroups=1" : "");
  console.log(url);
  $.ajax({
    url: url,
    type: "GET",
    contentType: false,
    processData: false,
    cache: false,
    dataType: "json",
    success: function (data) {
      var classes = data.classes;
      var select = withinDialog ? $("#dfmStudentSelector-singleclass select") : input.next().find('.dfm_studentSelector_classdropdown');
      var select2 = withinDialog ? $("#dfmStudentSelector-individuals-class") : undefined;
      var ul = withinDialog ? $("#dfmStudentSelector-multipleclasses ul") : undefined;

      select.empty();
      select.append("<option value=''>Select a class</option>");
      $.each(classes, function (k, cl) {
        const escapedClassName = $("<div>").text(cl.name).html();
        if (cl.cid == undefined) select.append("<option disabled value=''>---------</option>"); // seperator
        else select.append("<option value='" + cl.cid + "'>" + escapedClassName + "</option>");
        if (ul) {
          if (!cl.cid) ul.append("<hr>"); // separator
          else ul.append("<li><label><input type='checkbox' value='" + cl.cid + "'>" + escapedClassName + "</label></li>");
        }
        if (select2) {
          if (!cl.cid) select2.append("<option disabled value=''>---------</option>"); // seperator
          else select2.append("<option value='" + cl.cid + "'>" + escapedClassName + "</option>");
        }
      });
      // For multi-class selection, don't want the repeated 'own' classes at the start of list.
      $("#dfmStudentSelector-multipleclasses ul hr").prevAll().remove();
      $("#dfmStudentSelector-multipleclasses ul hr").remove();
      input.data('classes', classes); // for convenience

      // Do we need to fill in any values from prior selection?
      var currentVal = input.val() ? JSON.parse(input.val()) : {};
      if (currentVal.cids && currentVal.cids.length > 1) {
        $.each(currentVal.cids, function (k, cl) {
          $("#dfmStudentSelector-multipleclasses input[value=" + cl + "]").prop('checked', true);
        });
      }

      $("#dfmStudentSelector-individuals-class").change(dfmStudentSelector_loadClassStudents);

      if (onLoad) onLoad();
    },
    error: function (j, textStatus, errorThrown) {
      dfmAlert("An error occurred when trying to update the class list: <strong>" + j.responseText + "</strong>");
    }
  });

}

export function dfmStudentSelector_loadClassStudents() {
  $.ajax({
    url: "/api/class/class/" + $("#dfmStudentSelector-individuals-class").val(),
    type: "GET",
    contentType: false,
    processData: false,
    cache: false,
    success: function ({ classGrouping: cl }) {
      let ul = $("#dfmStudentSelector-individuals-left ul");
      ul.data('class', cl);
      ul.empty();
      ul.append("<li><label><input type='checkbox' id='dfmStudentSelector-individuals-selectall'><strong>Select All</strong></label></li>");

      $.each(cl._students, function (k, student) {
        ul.append("<li><label><input type='checkbox' class='individual-student' id='dfmStudentSelector-individual-" + student.uid + "'>" + student.surname + ", " + student.firstname + "</label></li>");
        $("#dfmStudentSelector-individual-" + student.uid).data('student', student);
        // See if we need to check any of the checkboxes based on students in the right column selection.
        var studentselection = $("#dfmStudentSelector-individuals-selection ul").data("studentselection");
        $("#dfmStudentSelector-individual-" + student.uid).prop('checked', studentselection.students && studentselection.students[student.uid]);
      });
      $("#dfmStudentSelector-individuals-selectall").change(function () {
        $(".individual-student").prop('checked', $(this).is(':checked'));
        dfmStudentSelector_respondToIndividualSelection();
      });
      $(".individual-student").change(dfmStudentSelector_respondToIndividualSelection);

    },
    error: function (j, textStatus, errorThrown) {
      dfmAlert("An error occurred when trying to get this class: <strong>" + j.responseText + "</strong>");
    }
  });
}

export function dfmStudentSelector_respondToIndividualSelection() {
  // Firstly see if Select All needs to be checked.
  var allClassSelected = $('.individual-student').length == $('.individual-student:checked').length;
  $("#dfmStudentSelector-individuals-selectall").prop('checked', allClassSelected);
  var selectionUL = $("#dfmStudentSelector-individuals-selection ul");
  var studentSelection = selectionUL.data('studentselection');
  var cl = $("#dfmStudentSelector-individuals-left ul").data('class');
  $('.individual-student').each(function () {
    var student = $(this).data('student');
    if ($(this).is(':checked')) {
      if (!studentSelection.students[student.uid]) studentSelection.students[student.uid] = student;
    } else {
      delete studentSelection.students[student.uid]
    }
  });

  // If selection of individual students matches whole class, replace individuals with class.
  var cl = $("#dfmStudentSelector-individuals-left ul").data('class');
  if (cl) {
    var allInClassSelected = true;
    $.each(cl._students, function (k, student) {
      if (!studentSelection.students[student.uid]) allInClassSelected = false
    });
    if (allInClassSelected) {
      // Get rid of the individual student entries in selection...
      $.each(cl._students, function (k, student) {
        delete studentSelection.students[student.uid];
      });
      // ... so we can replace with full class.
      studentSelection.classes[cl.cid] = cl;

    } else {
      delete studentSelection.classes[cl.cid];
    }
  }

  dfmStudentSelector_displayIndividualSelection();
}

// On the 'Individual(s)' tab on the student/class dialog, this populates the list on the right based on the underlying selection.

export function dfmStudentSelector_displayIndividualSelection() {

  var selectionUL = $("#dfmStudentSelector-individuals-selection ul");
  var studentSelection = selectionUL.data('studentselection');
  selectionUL.empty();
  $.each(studentSelection.classes, function (uid, cl) {
    selectionUL.append("<li>All of " + cl.name + "</li>");
  });
  $.each(studentSelection.students, function (uid, student) {
    selectionUL.append("<li>" + student.surname + ", " + student.firstname + " " + student.ctext + "</li>");
  });

}

// The val will be of the form {cids: [...], uids: [...]}
export function dfmStudentSelector_setValue(input, val) {
  if (!val.cids || val.cids.length == 0) val.cids = undefined; // deal with empty list
  if (!val.uids || val.uids.length == 0) val.uids = undefined; // deal with empty list
  console.log(JSON.stringify(val));
  input.val(JSON.stringify(val));
  if (input.data('options').classesOnly) {
    input.next().find('.dfm_studentSelector_classdropdown').val(val.cids[0]);
  } else {
    dfmStudentSelector_lookuplabel(input, val);
  }

}

export function dfmStudentSelector_lookuplabel(input, lookup) {

  console.log("[Lookup] " + JSON.stringify(lookup));
  // var formData = new FormData();
  // formData.append("lookup", JSON.stringify(lookup));
  $.ajax({
    url: "/api/user/others/lookupstudents",
    type: "POST",
    contentType: false,
    processData: false,
    cache: false,
    data: JSON.stringify(lookup),
    dataType: "json",
    success: function (data) {
      if (data.studentselection && Array.isArray(data.studentselection.classes)) data.studentselection.classes = {}; // turns [] into {}
      if (data.studentselection && Array.isArray(data.studentselection.students)) data.studentselection.students = {}; // turns [] into {}
      input.data('studentselection', data.studentselection);
      var mixedComponent = input.next().find('.dfm_studentSelector_selection');
      var individualsDropdown = input.next().find('.dfm_studentSelector_studentdropdown');
      mixedComponent.hide();
      individualsDropdown.hide();
      if (data.memberclass) {
        individualsDropdown.show();
        individualsDropdown.empty().append("<option value=''>MORE OPTIONS</option>");
        $.each(data.memberclass._students, function (k, student) {
          individualsDropdown.append("<option value='" + student.uid + "'>" + student.surname + ", " + student.firstname + "</option>");
        });
        individualsDropdown.val(lookup.uids[0]);
      } else {
        mixedComponent.show();
        mixedComponent.find('label').html(limitLength(data.label, 40));
      }
    },
    error: function (j, textStatus, errorThrown) {
      dfmAlert("An error occurred when trying to lookup the student selection label: <strong>" + j.responseText + "</strong>");
    }
  });
}



// -------------------------------------------------
// --  Mini-Action Menu
// -------------------------------------------------

// Like a select dropdown, but with actions associated with each option.
// Note: for the moment, I'm going to implement as an actual select!
// ----------------------------
// Constructor:
//    options: [{label: "...", action: function(){ ... }}, disabled?: boolean ]
//    default: The default label, e.g. 'Apply action'.
// Methods:
//    setOptions: ...


jQuery.fn.actionMenu = function (arg1, arg2) {
  var elem = $(this);
  if (arg1 == "setOptions") dfmActionMenu_setOptions(elem, arg2);
  else {
    elem.addClass('dfm_actionMenu');
    if (arg1.highlightOnActive) elem.addClass('dfm_actionMenu_highlight');
    elem.data('default', arg1.default);
    if (arg1.options) dfmActionMenu_setOptions(elem, arg1.options);
    elem.change(function () {
      var func = elem.data('options')[Number($(this).val())].action;
      func();
      elem.val("");
    });
  }
}

export function dfmActionMenu_setOptions(elem, options) {
  elem.empty();
  elem.data('options', options);
  elem.append("<option value=''>" + elem.data('default') + "</option>");
  elem.append("<option value='-1' disabled>Please select</option>");
  $.each(options, function (k, option) {
    const optionElement = $(`<option value='${k}'>${option.label}</option>`).appendTo(elem);
    optionElement.attr("disabled", option.disabled);
  });
}



// -------------------------------------------------
// --  Side Menu
// -------------------------------------------------

jQuery.fn.dfmSideMenu = function (arg1, arg2, arg3) {
  var tabs = $(this);
  if (arg1 == "selectTab") {
    dfmSideMenu_selectTab(tabs, arg2, arg3);
  } else {
    // Constructor.
    tabs.addClass('dfmSideMenu');
    tabs.data('options', arg1);
    arg1.panels.children().hide();
    tabs.find('a').click(function () {
      var selectedTab = $(this).attr('href').substr(1);
      dfmSideMenu_selectTab(tabs, selectedTab);
    });
  }
}

export function dfmSideMenu_selectTab(tabsDom, tab, dontTriggerListener) {
  tabsDom.find('a').removeClass('selected');
  if ($("a[href='#" + tab + "']").next().length > 0) {
    // If the tab is the parent of a submenu, descend to first item of submenu.
    tab = $("a[href='#" + tab + "']").next().find('a').first().attr('href').substr(1);
  }

  $("a[href='#" + tab + "']").addClass('selected');
  tabsDom.data('options').panels.children().hide();
  $("#" + tab).show();
  if (tabsDom.data('options').onSelect && !dontTriggerListener) {
    tabsDom.data('options').onSelect(tab);
  }
}


// -------------------------------------------------
// --  Mastery sliding bar
// -------------------------------------------------

const MASTERY_THRESHOLDS = [20, 50, 85];

jQuery.fn.dfmMasterySlidingBar = function (arg1, arg2) {
  var bar = $(this);
  // Constructor
  if (!arg1) {
    bar.addClass('dfmMasterySlidingBar_container');
    bar.empty();
    bar.append("<div class='dfmMasterySlidingBar_bar'><div class='dfmMasterySlidingBar_filling'></div>"
      + "<div class='dfmMasterySlidingBar_threshold1'></div><div class='dfmMasterySlidingBar_threshold2'></div><div class='dfmMasterySlidingBar_threshold3'></div></div>"
      + "<span class='dfmMasterySlidingBar_change'></span>");
    return;
  }
  else if (arg1 == "setValue") {
    var oldValue = bar.data('oldValue');
    for (var i = 0; i <= 3; i++)bar.find('.dfmMasterySlidingBar_filling').removeClass('dfmMasterySlidingBar_filling' + i);
    var masteryLevel = 0;
    if (arg2 >= MASTERY_THRESHOLDS[2]) masteryLevel = 3;
    else if (arg2 >= MASTERY_THRESHOLDS[1]) masteryLevel = 2;
    else if (arg2 >= MASTERY_THRESHOLDS[0]) masteryLevel = 1;
    bar.find('.dfmMasterySlidingBar_filling').addClass('dfmMasterySlidingBar_filling' + masteryLevel).css('width', arg2 + '%');
    if (bar.data('oldValue') !== undefined) {
      var change = Math.round(arg2 - bar.data('oldValue'));
      if (change >= 0) change = "+" + change;
      for (var i = 0; i <= 3; i++)bar.removeClass('dfmMasterySlidingBar_change' + i);
      bar.addClass('dfmMasterySlidingBar_change' + masteryLevel);
      bar.find('.dfmMasterySlidingBar_change').html(change);
    }
    bar.data('oldValue', arg2);
  }
  return bar;
}


// --------------------------------------
// -- Mastery bar
// --------------------------------------


jQuery.fn.dfmMasteryBar = function () {
  var div = $(this);
  var mastery = Number($(this).html());
  $(this).empty();
  $(this).html("<div></div><div></div><div></div>");
  $(this).addClass('dfmMasteryBar');

  for (var i = MASTERY_THRESHOLDS.length - 1; i >= 0; i--) {
    if (mastery >= MASTERY_THRESHOLDS[i]) {
      for (var j = 0; j <= i; j++) {
        $(this).children().eq(2 - j).addClass('mastery-' + (i + 1));
      }
      return;
    }
  }
}





// ------------------------------------------
// Key Skill common functions


window.dfmTree = function dfmTree(lab, prob, col) {
  const gap = 5;
  const sp = 18 / 300;
  const lgap = sp * 10;
  calculator.setExpression({ id: 'a1', latex: '\\left(4, 4\\right), \\left(0, 0\\right), \\left(4, -4\\right)', color: 'black', lines: true, points: false });
  calculator.setExpression({ id: 'a2', latex: '\\left(' + (8 + gap) + ', 5.5\\right), \\left(' + (gap + 4) + ', 4\\right), \\left(' + (8 + gap) + ', 2.5\\right)', color: 'black', lines: true, points: false });
  calculator.setExpression({ id: 'a3', latex: '\\left(' + (8 + gap) + ', -2.5\\right), \\left(' + (gap + 4) + ', -4\\right), \\left(' + (8 + gap) + ', -5.5\\right)', color: 'black', lines: true, points: false });

  calculator.setExpression({ id: 'b1', latex: '\\left(' + (4 + gap / 2) + ', 4\\right)', color: 'black', hidden: true, label: lab[0], showLabel: true });
  calculator.setExpression({ id: 'b2', latex: '\\left(' + (4 + gap / 2) + ', -4\\right)', color: 'black', hidden: true, label: lab[1], showLabel: true });

  calculator.setExpression({ id: 'b3', latex: '\\left(' + (8 + gap + gap / 2) + ', 5.5\\right)', color: 'black', hidden: true, label: lab[2], showLabel: true });
  calculator.setExpression({ id: 'b4', latex: '\\left(' + (8 + gap + gap / 2) + ', 2.5\\right)', color: 'black', hidden: true, label: lab[3], showLabel: true });

  calculator.setExpression({ id: 'b5', latex: '\\left(' + (8 + gap + gap / 2) + ', -2.5\\right)', color: 'black', hidden: true, label: lab[4], showLabel: true });
  calculator.setExpression({ id: 'b6', latex: '\\left(' + (8 + gap + gap / 2) + ', -5.5\\right)', color: 'black', hidden: true, label: lab[5], showLabel: true });

  calculator.setExpression({ id: 'c1', latex: '\\left(2, ' + (2 + lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[0] + '`', showLabel: true });
  calculator.setExpression({ id: 'c2', latex: '\\left(2, ' + (-2 - lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[1] + '`', showLabel: true });

  calculator.setExpression({ id: 'c3', latex: '\\left(' + (6 + gap) + ', ' + (4.75 + lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[2] + '`', showLabel: true });
  calculator.setExpression({ id: 'c4', latex: '\\left(' + (6 + gap) + ', ' + (3.25 - lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[3] + '`', showLabel: true });

  calculator.setExpression({ id: 'c5', latex: '\\left(' + (6 + gap) + ', ' + (-3.25 + lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[4] + '`', showLabel: true });
  calculator.setExpression({ id: 'c6', latex: '\\left(' + (6 + gap) + ', ' + (-4.75 - lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[5] + '`', showLabel: true });
}

window.dfmTreeNew = function dfmTreeNew(lab, prob, col, type = 0) {
  console.log('[DFMTree]');
  if (type == 1) {
    const gap = 5;
    const sp = 18 / 350;
    const lgap = sp * 10;

    calculator.setExpression({ id: 'a1', latex: '\\frac{(x-0)^2}{3}+\\frac{(y-0)^2}{1.5}=1', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a2', latex: '\\left(1, 1\\right), \\left(7, 5\\right)', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a3', latex: '\\left(1, -1\\right), \\left(7, -5\\right)', color: 'black', lines: true, points: false });

    calculator.setExpression({ id: 'a4', latex: '\\frac{(x-8.75)^2}{3}+\\frac{(y-5)^2}{1.5}=1', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a5', latex: '\\frac{(x-8.75)^2}{3}+\\frac{(y+5)^2}{1.5}=1', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a6', latex: '\\left(9.75, 6\\right), \\left(17, 8\\right)', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a7', latex: '\\left(9.75, 4\\right), \\left(17, 2\\right)', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a8', latex: '\\left(9.75, -6\\right), \\left(17, -8\\right)', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a9', latex: '\\left(9.75, -4\\right), \\left(17, -2\\right)', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a10', latex: '\\frac{(x-18.75)^2}{3}+\\frac{(y-8)^2}{1.5}=1', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a11', latex: '\\frac{(x-18.75)^2}{3}+\\frac{(y-2)^2}{1.5}=1', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a12', latex: '\\frac{(x-18.75)^2}{3}+\\frac{(y+8)^2}{1.5}=1', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a13', latex: '\\frac{(x-18.75)^2}{3}+\\frac{(y+2)^2}{1.5}=1', color: 'black', lines: true, points: false });

    calculator.setExpression({ id: 'b1', latex: '\\left(' + (4 - lgap * 2) + ', 4\\right)', color: 'black', hidden: true, label: lab[0], showLabel: true });
    calculator.setExpression({ id: 'b2', latex: '\\left(' + (4 - lgap * 2) + ', -4\\right)', color: 'black', hidden: true, label: lab[1], showLabel: true });
    calculator.setExpression({ id: 'b3', latex: '\\left(' + (13 - lgap * 2) + ', 8\\right)', color: 'black', hidden: true, label: lab[2], showLabel: true });
    calculator.setExpression({ id: 'b4', latex: '\\left(' + (13 - lgap * 3) + ', 2\\right)', color: 'black', hidden: true, label: lab[3], showLabel: true });
    calculator.setExpression({ id: 'b5', latex: '\\left(' + (13 - lgap * 2) + ', -2\\right)', color: 'black', hidden: true, label: lab[2], showLabel: true });
    calculator.setExpression({ id: 'b6', latex: '\\left(' + (13 - lgap * 3) + ', -8\\right)', color: 'black', hidden: true, label: lab[3], showLabel: true });

    calculator.setExpression({ id: 'c1', latex: '\\left(0, 0\\right)', color: col, hidden: true, label: '`' + prob[0] + '`', showLabel: true });
    calculator.setExpression({ id: 'c2', latex: '\\left(8.75, 5\\right)', color: col, hidden: true, label: '`' + prob[1] + '`', showLabel: true });
    calculator.setExpression({ id: 'c3', latex: '\\left(8.75, -5\\right)', color: col, hidden: true, label: '`' + prob[4] + '`', showLabel: true });
    calculator.setExpression({ id: 'c4', latex: '\\left(18.75, 8\\right)', color: col, hidden: true, label: '`' + prob[2] + '`', showLabel: true });
    calculator.setExpression({ id: 'c5', latex: '\\left(18.75, 2\\right)', color: col, hidden: true, label: '`' + prob[3] + '`', showLabel: true });
    calculator.setExpression({ id: 'c6', latex: '\\left(18.75, -2\\right)', color: col, hidden: true, label: '`' + prob[5] + '`', showLabel: true });
    calculator.setExpression({ id: 'c7', latex: '\\left(18.75, -8\\right)', color: col, hidden: true, label: '`' + prob[6] + '`', showLabel: true });

  } else {
    const gap = 5;
    const sp = 18 / 300;
    const lgap = sp * 10;

    calculator.setExpression({ id: 'a1', latex: '\\left(4, 4\\right), \\left(0, 0\\right), \\left(4, -4\\right)', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a2', latex: '\\left(' + (8 + gap) + ', 5.5\\right), \\left(' + (gap + 4) + ', 4\\right), \\left(' + (8 + gap) + ', 2.5\\right)', color: 'black', lines: true, points: false });
    calculator.setExpression({ id: 'a3', latex: '\\left(' + (8 + gap) + ', -2.5\\right), \\left(' + (gap + 4) + ', -4\\right), \\left(' + (8 + gap) + ', -5.5\\right)', color: 'black', lines: true, points: false });

    calculator.setExpression({ id: 'b1', latex: '\\left(' + (4 + gap / 2) + ', 4\\right)', color: 'black', hidden: true, label: lab[0], showLabel: true });
    calculator.setExpression({ id: 'b2', latex: '\\left(' + (4 + gap / 2) + ', -4\\right)', color: 'black', hidden: true, label: lab[1], showLabel: true });

    calculator.setExpression({ id: 'b3', latex: '\\left(' + (8 + gap + gap / 2) + ', 5.5\\right)', color: 'black', hidden: true, label: lab[2], showLabel: true });
    calculator.setExpression({ id: 'b4', latex: '\\left(' + (8 + gap + gap / 2) + ', 2.5\\right)', color: 'black', hidden: true, label: lab[3], showLabel: true });

    calculator.setExpression({ id: 'b5', latex: '\\left(' + (8 + gap + gap / 2) + ', -2.5\\right)', color: 'black', hidden: true, label: lab[4], showLabel: true });
    calculator.setExpression({ id: 'b6', latex: '\\left(' + (8 + gap + gap / 2) + ', -5.5\\right)', color: 'black', hidden: true, label: lab[5], showLabel: true });

    calculator.setExpression({ id: 'c1', latex: '\\left(2, ' + (2 + lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[0] + '`', showLabel: true });
    calculator.setExpression({ id: 'c2', latex: '\\left(2, ' + (-2 - lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[1] + '`', showLabel: true });

    calculator.setExpression({ id: 'c3', latex: '\\left(' + (6 + gap) + ', ' + (4.75 + lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[2] + '`', showLabel: true });
    calculator.setExpression({ id: 'c4', latex: '\\left(' + (6 + gap) + ', ' + (3.25 - lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[3] + '`', showLabel: true });

    calculator.setExpression({ id: 'c5', latex: '\\left(' + (6 + gap) + ', ' + (-3.25 + lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[4] + '`', showLabel: true });
    calculator.setExpression({ id: 'c6', latex: '\\left(' + (6 + gap) + ', ' + (-4.75 - lgap * 2.4) + '\\right)', color: col, hidden: true, label: '`' + prob[5] + '`', showLabel: true });
  }
}

// Gets the suffix for a date.
export function nth(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function limitLength(str, length = 80) {
  if (!str || typeof str !== "string") {
    return str;
  }
  if (str.length <= length) {
    return str;
  }
  var shortened = str.substr(0, length);
  // Make sure if an opening || for maths text, we find the closing one.
  var currentCharIndex = shortened.length;
  while ((shortened.match(/\|\|/g) || []).length % 2 == 1 && currentCharIndex < str.length) {
    shortened += str[currentCharIndex++];
  }
  return shortened + "...";
}

export function getLocalisedDateFormat() {
  return "dd/mm/yy"; // worry about this later
  if (!Context.user || !Context.user._school) return "dd/mm/yy";
  if (Context.user._school.country === "England" || Context.user._school.country === "Scotland" || Context.user._school.country === "Wales" || Context.user._school.country === "Ireland" || Context.user._school.country === "Northern Ireland" || Context.user._school.country === "UK Channel Islands" || Context.user._school.country === "British Isles") return "dd/mm/yy";
  else return "mm/dd/yy";
}

export function formatVideoLength(secs) {
  var mins = Math.round(secs / 60.0);
  if (secs > 0 && secs < 30) mins = 1;
  return mins + " min" + (mins == 1 ? "" : "s");
}

export function formatTime(secs) {
  if (secs < 60) return secs + " secs";
  var mins = Math.round(secs / 60.0);
  if (secs > 0 && secs < 30) mins = 1;
  return mins + " min" + (mins == 1 ? "" : "s");

}

const MONTHS_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export function readFriendlyDate(t, includeTime) {
  // Assume the time is from the backend, which is in UTC seconds.
  var d = new Date(t * 1000);
  var dateStr = MONTHS_SHORT[d.getMonth()] + " " + d.getDate() + nth(d.getDate());
  if (includeTime) {
    var minutesStr = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    dateStr += " " + (d.getHours() > 12 ? d.getHours() - 12 : d.getHours())
      + ":" + minutesStr + (d.getHours() >= 12 ? "pm" : "am");
  }
  return dateStr;
}


// --------------------------------------
// -- Task Setter
// --------------------------------------
// A dialog for setting a task or starting a practice.
// ----------------------------
//    options: {isPractice: bool, task: TASKOBJ, callback: FUNCTION, notice: STRING }  (the TASKOBJ is any values we want to set. If aid is set, we're editing)

export function dfmTaskSetter_respondToSkillSelection(selection) {
  // Change they haven't mixed Key Skills and exam practice.
  var hasEP = false; var hasKS = false;

  $.each(selection.subskills, function (k, subskill) {
    if (subskill.type == "k") hasKS = true;
    else hasEP = true;
  });
  if (hasEP && hasKS) {
    $.modal.close();
    dfmAlert("For " + (isStudent() ? "practices" : "practice/setting tasks") + ", you are not allowed to mix Exam Practice and Question Generators. Please remove one of these types from your selection.");
    return false;
  }

  // Difficulty and course filters are only applicable if just dealing with Exam Practice subskills.
  if (!(hasEP && !hasKS)) {
    $("#task-row-difficulty").hide();
    $("#task-row-courses").hide();
  } else {
    $("#task-row-difficulty").show();
    $("#task-row-courses").show();
  }

}

export function dfmTaskSetter(options) {
  var buttons = [];
  buttons.push({
    label: (options.isPractice ? "Start" : "Set"), action: function () {
      return dfmTaskSetter_processform(options);
    }
  });

  // Legacy (will temporarily allowed old and new API to both work - new uses 'name')
  if (options.task._worksheet && options.task._worksheet.title) {
    options.task._worksheet.name = options.task._worksheet.title;
  }

  // Dialog content
  var title;
  if (options.task.aid) title = "Edit Task/Recipients";
  else title = options.isPractice ? "Start Practice" : "Set a Task";
  dfmDialog("<h1>" + title + "</h1>" + (options.notice ? "<p class='notice-light'>" + options.notice + "</p>" : "") + "<form><div id='setaswork'><div id='setaswork-mainoptions'></div><div id='setaswork-otheroptions'><h3>Options</h3></div></div><div id='task-email-notifications'></div></form>", buttons);
  $(".modal").css('width', 'auto');
  $(".modal").css('max-width', 'none');

  /**
   * The above overrides of the jquery-modal styles are causing
   * undesired overflow behaviour of the .blocker element.
   *
   *  Parent:         .blocker
   *     Child 1:         ::before
   *     Child 2:         .modal
   *
   * When the width of the .modal element exceeds the width of
   * viewport, it wraps below the ::before element. The ::before
   * element has a height of 100%, shifting the modal down
   * (the css for the ::before element is set by the library).
   *
   * The styles below are very much a short term solution to
   * improve this display issue, which affects smaller
   * screens.
   */

  // This prevents the undesired layout shift.
  $(".blocker").css("text-wrap", "nowrap");

  // This sets the correct value for further inheritance.
  $(".modal").css("text-wrap", "wrap");

  var main = $("#setaswork-mainoptions");
  var other = $("#setaswork-otheroptions");

  if (options.isPractice) {
    main.append("<input type='hidden' id='task-recipients'>");
    $("#task-recipients").val(JSON.stringify(options.task.recipients));

    // Hide the email notifications checkbox for practice tasks.
    $("#task-email-notifications").hide();
  } else {
    main.append("<div><label>Set task for:</label><input type='hidden' id='task-recipients'></div>");
    $("#task-recipients").studentSelector({});
    if (options.task.recipients) {
      $("#task-recipients").studentSelector("setValue", options.task.recipients);
    }

    // Build the email notifications checkbox.
    const emailNotifications = options.task.emailNotifications ?? true;
    $("#task-email-notifications").show().append(`
      <input type="checkbox" id="task-email-notifications__input" ${emailNotifications ? "checked" : ""}>
      <label id="task-email-notifications__label" for="task-email-notifications__input">Send email notifications to students</label>
    `);
  }


  // Skill selection

  var isWorksheet = options.task && options.task.wid;

  if (!isWorksheet) {

    main.append("<input type='hidden' id='task-type' value='" + options.task.type + "'>");


    if (!options.task.cdata) options.task.cdata = {};

    // The skids array and ssids array should be within options.task.cdata
    // This is to accommodate skills and subskills being given in full object form.
    var hasKeySkill = false;
    var hasExamSkill = false;
    if (options.task.subskills) {
      options.task.cdata.ssids = [];
      $.each(options.task.subskills, function (k, ss) {
        options.task.cdata.ssids.push(ss.ssid);
        if (ss.type == "k") hasKeySkill = true;
        if (ss.type == "e") hasExamSkill = true;
      });
    }
    if (options.task.skills) {
      options.task.cdata.skids = [];
      $.each(options.task.skills, function (k, s) { options.task.cdata.skids.push(s.skid) });
    }

    main.append(`
      <div style="margin-bottom:5px">
        <label class="main-label">Skills:</label>
        <input type="hidden" id="task-skillselection">
      </div>
    `);

    // Set the current skill list
    $("#task-skillselection").dfmSkillSelector("setValue", { skids: options.task.cdata.skids, ssids: options.task.cdata.ssids }, true);

    /* // Interactivity is currently disabled
    var allowSubskills = options.task.cdata.ssids && options.task.cdata.ssids.length > 0; // can either choose skills or subskills but not a mixture. Based on prior selection.
    $("#task-skillselection").dfmSkillSelector({ selectSubskills: allowSubskills, singleChoice: false, onChange: dfmTaskSetter_respondToSkillSelection });
    */

    // A help notice explaining why the above is temporarily disabled
    $('#task-skillselection').after(`
        <span>(As selected)</span>
        <a class="help-button" id="skills-label">?</a>
      `);
    $("#skills-label").click(function () {
      dfmAlert('Editing the selected skills for this task is temporarily disabled. You will need to generate a new task to change the list.', true);
    });

    /**
     * We only want to show the options to configure difficulty and exam
     * board if the task only consists of subskills and those subskills
     * are exclusively of type = "e".
     */
    const hasSkills = options.task?.skills?.length > 0;
    const hasSubskills = options.task?.subskills?.length > 0;
    const onlyHasExamSubskills = hasSubskills && options.task.subskills.every(ss => ss.type === "e");
    const showExamQuestionOptions = !hasSkills && onlyHasExamSubskills;

    if (showExamQuestionOptions) {
      main.append("<div id='task-row-difficulty'><label class='main-label'>Difficulty:</label><select id='task-difficulty'>"
        + "<option value='1to3'>1-3</option><option value='1to4'>1-4</option>"
        + "<option value='1to2'>1-2</option><option value='2to3'>2-3</option>"
        + "<option value='2to4'>2-4</option><option value='3to4'>3-4</option>"
        + "<option value='1'>1 only</option><option value='2'>2 only</option><option value='3'>3 only</option>"
        + "<option value='4'>4 only</option></select> <a id='h-difficulty' class='help-button'>?</a></div>");
      $("#h-difficulty").click(function () {
        dfmAlert("Exam question difficulty varies between 1 and 4. Roughly speaking:<ul>"
          + "<li><strong>Difficulty 1</strong> questions are the most basic kind of a skill, often with simpler numbers/expressions and intended to test basic understanding.</li>"
          + "<li><strong>Difficulty 2</strong> are typical easier/medium standard exam questions.</li>"
          + "<li><strong>Difficulty 3</strong> involves a degree of problem solving, potentially multiple steps and potentially multiple skills."
          + "<li><strong>Difficulty 4</strong> questions are beyond usual exam difficulty with a high degree of problem solving, intended to be 'extension' questions.</li>"
          + "</ul>", true)
      });

      if (options.task.cdata.difficulty) $("#task-difficulty").val(options.task.cdata.difficulty);

      main.append("<div id='task-row-courses' style='margin-top:7px'><label class='main-label'>Author:</label><input type='hidden' id='task-courses'></div>");
      $("#task-courses").dfmExamBoardSelector({ light: true });
      if (options.task.cdata.courses) $("#task-courses").dfmExamBoardSelector("val", options.task.cdata.courses);
      else $("#task-courses").dfmExamBoardSelector("val", [-1]); // exam questions only
    }

  }
  else {
    var label = options.task._worksheet ? options.task._worksheet.name : "-";
    main.append("<div><label class='main-label'>Worksheet:</label><span style='display:inline-block;max-width:230px;margin-bottom:10px'>" + label + "</span></div>");
    main.append("<input type='hidden' id='task-wid' value='" + options.task.wid + "'>");
  }


  if (!options.isPractice) {

    // Custom label
    main.append("<div style='margin-top: 20px'><label>Custom Label:</label><input type='text' placeholder='(optional)' id='task-label'> <a class='help-button' id='h-label'>?</a></div>");
    $("#h-label").click(function () {
      dfmAlert('This will appear in the homework list for students, and also as a table heading in homework results. If not specified, we will create a label for you.', true);
    });
    if (options.task.label) $("#task-label").val(options.task.label);

    // Due date
    var dt;
    if (options.task.duedate) {
      dt = new Date(options.task.duedate * 1000);
    } else {
      dt = new Date();
      dt.setDate(dt.getDate() + (dt.getDay() == 5 ? 3 : 1)); // get tomorrow (or Monday if it's currently Friday)
    }
    var y = dt.getFullYear();
    var m = (dt.getMonth() + 1 <= 9 ? "0" : "") + (dt.getMonth() + 1);
    var d = dt.getDate();
    var dtt = getLocalisedDateFormat() === "dd/mm/yy" ? d + "/" + m + "/" + y : m + "/" + d + "/" + y;

    main.append("<div style='margin-top:10px'><label style='margin-bottom:0px'>Due:</label><label style='margin-bottom:2px'><input type='checkbox' id='task-noduedate'> No Due Date</label></div><div style='margin-bottom:15px'><input type='text' id='task-duedate' autocomplete='off' style='margin-left:150px;padding:5px;width:100px' value='" + dtt + "'><select id='task-duetime' style='padding:5px'></select></div>");
    for (var t = 0; t < 24; t++) {
      var tStr = t;
      if (t < 10) tStr = "0" + tStr;
      $("#task-duetime").append("<option value='" + tStr + ":00:00'>" + tStr + ":00</option>");
      if (t >= 8 && t <= 16) {
        $("#task-duetime").append("<option value='" + tStr + ":10:00'>" + tStr + ":10</option>");
        $("#task-duetime").append("<option value='" + tStr + ":20:00'>" + tStr + ":20</option>");
      }
      if (t >= 6 && t <= 20) $("#task-duetime").append("<option value='" + tStr + ":30:00'" + (t == 9 ? " selected" : "") + ">" + tStr + ":30</option>");
      if (t >= 8 && t <= 16) {
        $("#task-duetime").append("<option value='" + tStr + ":40:00'>" + tStr + ":40</option>");
        $("#task-duetime").append("<option value='" + tStr + ":50:00'>" + tStr + ":50</option>");
      }
    }
    if (options.task.duedate) $("#task-duetime").val(makeTwoDigits(dt.getHours()) + ":" + makeTwoDigits(dt.getMinutes()) + ":00");

    $("#task-noduedate").change(function () {
      if ($(this).is(':checked')) {
        $("#task-duedate").hide();
        $("#task-duetime").hide();
      } else {
        $("#task-duedate").show();
        $("#task-duetime").show();
      }
    });
    if (!options.task.duedate) $("#task-noduedate").click();
    $("#task-duedate").datepicker({ dateFormat: getLocalisedDateFormat() });

    // Allow scheduling for future.
    if (options.task.setdate) {
      dt = new Date(options.task.setdate * 1000);
    } else {
      dt = new Date();
      dt.setDate(dt.getDate() + (dt.getDay() == 5 ? 3 : 1)); // get tomorrow (or Monday if it's currently Friday)
    }
    var y = dt.getFullYear();
    var m = (dt.getMonth() + 1 <= 9 ? "0" : "") + (dt.getMonth() + 1);
    var d = dt.getDate();
    dtt = getLocalisedDateFormat() === "dd/mm/yy" ? d + "/" + m + "/" + y : m + "/" + d + "/" + y;

    main.append("<div id='settask-schedule-row'><label>Set:</label><select id='task-schedule' style='padding:6px'><option value='0'>Immediately<option value='1'>at a specified time</select></div>");
    main.append("<div style='margin-bottom:15px'><input type='text' id='task-scheduledate' style='padding:6px;margin-left:150px;width:100px' value='" + dtt + "'><select id='task-scheduletime' style='padding:6px'></select></div>");
    for (var t = 0; t < 24; t++) {
      var tStr = t;
      if (t < 10) tStr = "0" + tStr;
      $("#task-scheduletime").append("<option value='" + tStr + ":00:00'>" + tStr + ":00");
      if (t >= 8 && t <= 16) {
        $("#task-scheduletime").append("<option value='" + tStr + ":10:00'>" + tStr + ":10</option>");
        $("#task-scheduletime").append("<option value='" + tStr + ":20:00'>" + tStr + ":20</option>");
      }
      if (t >= 6 && t <= 20) $("#task-scheduletime").append("<option value='" + tStr + ":30:00'" + (t == 15 ? " selected" : "") + ">" + tStr + ":30");
      if (t >= 8 && t <= 16) {
        $("#task-scheduletime").append("<option value='" + tStr + ":40:00'>" + tStr + ":40</option>");
        $("#task-scheduletime").append("<option value='" + tStr + ":50:00'>" + tStr + ":50</option>");
      }
    }
    $("#task-scheduledate").datepicker({ dateFormat: getLocalisedDateFormat() });
    $("#task-schedule").change(function () {
      if ($(this).val() == 0) {
        $("#task-scheduledate").hide();
        $("#task-scheduletime").hide();
      } else {
        $("#task-scheduledate").show();
        $("#task-scheduletime").show();
      }
    });
    $("#task-schedule").change();
    if (options.task.setdate) {
      if (options.task.setdate < (new Date()).getTime() / 1000) {
        $("#settask-schedule-row").remove();
      } else {
        $("#task-schedule").val('1').change();
        $("#task-scheduletime").val(makeTwoDigits(dt.getHours()) + ":" + makeTwoDigits(dt.getMinutes()) + ":00");
      }
    }
  }

  // If not a worksheet (i.e. flexible task), then completion options.
  if (!isWorksheet) {

    main.append("<div id='task-type-container'></div>");
    $("#task-type-container").append("<div style='margin-top: 20px'><label class='task-type-option' style='width:350px'><input type='radio' name='task-cmode' id='task-cmode-fixednum' value='fixednum'><h2><strong>Fixed number</strong> of questions</h2>"
      + "<h3>Either the system differentiates between the subskills in your selection (giving " + (options.isPractice ? "you" : "them") + " harder or easier questions based on " + (options.isPractice ? "your" : "their") + " changing mastery), or interleaving between all the skills in your selection.</h3>"
      + "<div class='task-cmode-options' id='task-cmode-fixednumoptions' style='margin-left:30px;margin-top:10px;font-size:14px'><select id='task-numquestions' name='numquestions' style='padding:3px;font-size:14px'>"
      + "   <option value='4'>4</option><option value='6'>6</option>"
      + "   <option value='8'>8</option><option value='10' selected>10</option>"
      + "   <option value='12'>12</option><option value='15'>15</option>"
      + "   <option value='20'>20</option><option value='25'>25</option>"
      + "   <option value='30'>30</option><option value='35'>35</option>"
      + "</select> questions with <select id='task-cmode-fixednumvariant' style='padding:3px;margin-left:2px;font-size:14px'><option value='differentiate'>differentiation</option><option value='interleave'>interleaving</option><option value='neither'>neither</option></select></label></div>"
      + "</div>");
    if (options.task.cdata.numquestions) $("#task-numquestions").val(options.task.cdata.numquestions);
    $("#task-type-container").append("<div><label class='task-type-option' style='width:350px'><input type='radio' name='task-cmode' id='task-cmode-accuracy' value='accuracy'><h2><strong>Accuracy</strong> required to finish</h2>"
      + "<h3>We'll interleave between the subskills within your selection. " + (options.isPractice ? "You" : "Students") + " need to achieve the required accuracy at each subskill.</h3>"
      + "   <div class='task-cmode-options' id='task-cmode-accuracyoptions' style='margin-left:30px;margin-top:10px;font-size:14px'><select id='task-numcorrect' style='padding:3px;font-size:14px'><option value='2'>2<option value='3'>3<option value='4' selected>4<option value='5'>5<option value='6'>6<option value='7'>7<option value='8'>8<option value='10'>10</select> "
      + "   out of the last <select id='task-outof' style='padding:3px;font-size:14px'><option value='3'>3<option value='4'>4<option value='5' selected>5<option value='6'>6<option value='7'>7<option value='8'>8<option value='10'>10<option value='12'>12</select> questions correct on each subskill, <select id='task-accuracyinterleave' style='margin-top:5px;font-size:14px;padding:3px'><option value='0'>without interleaving</option><option value='1'>with interleaving</option></select>.</div>"
      + "</label></div>");
    if (options.isPractice) $("#task-type-container").append("<div><label class='task-type-option'><input type='radio' name='task-cmode' id='task-cmode-nostop' value='nostop'><h2><strong>Keep going</strong> until I say</label></div>");

    $("input[name=task-cmode]").change(function () {
      $(".task-cmode-options").hide();
      $("#task-cmode-" + $(this).val() + "options").show();
    }).change();
    if (options.task.cmode) {
      $("#task-cmode-" + options.task.cmode).prop('checked', true).change();
      if (options.task.cdata.numcorrect) $("#task-numcorrect").val(options.task.cdata.numcorrect);
      if (options.task.cdata.outof) $("#task-outof").val(options.task.cdata.outof);
      if (options.task.cdata.numquestions) $("#task-numcorrect").val(options.task.cdata.numquestions);
      if (options.task.cdata.interleave) $("#task-cmode-fixednumvariant").val('interleave');
      else if (options.task.cdata.differentiate) $("#task-cmode-fixednumvariant").val('differentiate');
      else $("#task-cmode-fixednumvariant").val('neither');
      $("#task-accuracyinterleave").val(options.task.cdata.interleave === true ? '1' : '0');
    } else {
      $("#task-cmode-fixednum").prop('checked', true).change();
    }

  } else {
    if (!options.isPractice) {
      main.append("<div id='task-type-container'></div>");
      if (false) {
        $("#task-type-container").append("<div><label class='task-type-option'><input type='radio' name='task-type' id='task-type-x' value='x' checked><h2>Set as a <strong>Topic Test</strong></h2>"
          + "<small class='setaswork'>This is a special mode available only for DrFrostMaths Topic Tests. Students must score at least 6/8 to earn a 'Topic Medal'. They have two attempts at the topic test within any 24 hour period. Earning the Topic Medal is required for successful completion of the homework.</h3></label></div>");
      } else {
        $("#task-type-container").append("<div><label class='task-type-option'><input type='radio' name='task-type' id='task-type-t' value='t'><h2>Set as <strong>a Homework/Classwork</strong></h2>"
          + "<h3>Students get instant feedback after submitting each answer.</h3></label></div>");
        $("#task-type-container").append("<div><label class='task-type-option'><input type='radio' name='task-type' id='task-type-a' value='a'><h2>Set as an <strong>Assessment</strong></h2>"
          + "<h3>Students do not see the answers until the due date specified by you. Students can not redo the assessment unless it is set again by the teacher.</h3></label></div>");
      }
      $("#task-type-" + (options.task.type ? options.task.type : "t")).prop('checked', true);
    }
  }



  // Other options
  if (!options.isPractice) {
    other.append("<div><label>Warn when Wrong:</label><select id='task-givewarning'><option value='0'>No<option value='1' selected>Yes</select> &nbsp;<a id='h-warn' class='help-button'>?</a></div>");
    $("#h-warn").click(function () { dfmAlert('When set to Yes, students will be warned once per question if their answer is incorrect. Excludes multiple choice questions.', true) });
    other.append("<div><label>Prevent Reattempts:</label><select id='task-preventreattempts' style='padding:6px'><option value='0'>No<option value='1' selected>Yes</select> &nbsp;<a id='h-reattempts' class='help-button'>?</a></div>");
    $("#h-reattempts").click(function () { dfmAlert('When set to Yes, students can only do a homework once, without subsequently trying for an improved mark. If set to No and if the task is a <strong>fixed question task</strong>, students will not get explanation/the correct answer for incorrectly answered questions, to prevent students knowing the answers for subsequent attempts.', true) });
    other.append("<div><label>Require Working:</label><select id='task-requireworking'><option value='0' selected>No<option value='1'>Yes<option value='2'>Optional</select> &nbsp;<a id='h-working' class='help-button'>?</a></div>");
    $("#h-working").click(function () { dfmAlert('When set to Yes, students must use the mini-whiteboard next to the question display to provide workings. Optional means the working will be recorded if provided.', true) });
    other.append("<div><label>Require Feedback:</label><select id='task-requirefeedback'><option value='0' selected>No<option value='1'>If incorrect<option value='2'>Always</select> &nbsp;<a id='h-feedback' class='help-button'>?</a></div>");
    $("#h-feedback").click(function () { dfmAlert('After students have answered a question, they have a box in which they can leave written reflections on the question for the teacher.', true) });
    other.append("<div><label>Time Limit:</label><select id='task-timelimit'><option selected value=''>None</option></select></div>");
    for (let i = 5; i <= 80; i += 5)$("#task-timelimit").append("<option value='" + (60 * i) + "'>" + i + " mins");
    for (let i = 90; i <= 120; i += 10)$("#task-timelimit").append("<option value='" + (60 * i) + "'>" + i + " mins");

    if (!isWorksheet) {
      other.append("<div><label>Hide skill names:</label><select id='task-hideskillnames'><option value='0' selected>No<option value='1'>Yes</select> &nbsp;<a id='h-hideskillname' class='help-button'>?</a></div>");
      $("#h-hideskillname").click(function () { dfmAlert('When set to Yes, students will not see the skill name of the current question at the top of the page, making it harder to identify what skill is needed. The Worked Example button will also be greyed out.', true) });
    }
    else {
      other.append("<div><label>Accuracy measure:</label><select " + (options.task._worksheet.allqswithmarks ? "" : "disabled") + " style='padding:6px;font-size:12px' id='task-accuracymeasure'><option value='0'>Each question worth the same</option><option value='1'>Use exam marking</option></select> <a class='help-button' id='h-accuracymeasure' href='#'>?</a></div>");
      $("#h-accuracymeasure").click(function () { dfmAlert('Questions from exam boards have associated marks. Exam marking will use these marks. Note that this option is only available when all questions are exam questions that have a number of marks assigned to them.', true) });
    }

    if (options.task.cdata) {
      if (options.task.cdata.givewarning !== undefined) $("#task-givewarning").val(options.task.cdata.givewarning ? "1" : "0");
      if (options.task.cdata.preventreattempts !== undefined) $("#task-preventreattempts").val(options.task.cdata.preventreattempts ? 1 : 0);
      if (options.task.cdata.requireworking !== undefined) $("#task-requireworking").val(options.task.cdata.requireworking);
      if (options.task.cdata.requirefeedback !== undefined) $("#task-requirefeedback").val(options.task.cdata.requirefeedback);
      if (options.task.cdata.timelimit !== undefined) $("#task-timelimit").val(options.task.cdata.timelimit);
      if (options.task.cdata.hideskillnames !== undefined) $("#task-hideskillnames").val(options.task.cdata.hideskillnames ? 1 : 0);
      if (options.task.cdata.accuracymeasure !== undefined) $("#task-accuracymeasure").val(options.task.cdata.accuracymeasure);
    }
  } else {
    other.hide();
  }

  if (isWorksheet && options.isPractice) {
    // No further selection to make, so submit immediately.
    dfmTaskSetter_processform(options);
  }

  $(".modal .dialog-buttons button").css('background-color', '#333'); // sorry - hacky. Make submit button black instead of blue.
  if (options.task.aid) $(".modal .dialog-buttons button").html("Update"); // editing
  MathJax.typeset();
}

export function makeTwoDigits(i) {
  i = String(i);
  return (i.length == 1 ? "0" : "") + i;
}

export function dfmTaskSetter_processform(options) {
  var data = {};

  if (options.task.aid) data.aid = options.task.aid; // editing an existing task
  var isWorksheet = options.task && options.task.wid;

  let examQuestionSource;

  if (isWorksheet) {
    data.wid = Number($("#task-wid").val());
    if (options.isPractice) {
      data.type = "c";
    } else {
      var taskType = $("input[name=task-type]:checked").val();
      data.type = taskType;
      if (!taskType) {
        dfmAlert("Please select a task type.", true);
        return false;
      }

      var accuracyMeasure = $("#task-accuracymeasure").val();
      if (accuracyMeasure !== undefined) data.accuracymeasure = accuracyMeasure;
    }
  } else if (!isWorksheet) {

    var cmode = $("input[name=task-cmode]:checked").val();
    // var cmode = $("#task-cmode").val();
    if (!cmode) {
      dfmAlert("No completion criteria was chosen.");
      return false;
    }
    data.cmode = cmode;

    if ($("#task-row-courses").is(':visible') && $("#task-courses").val()) {
      data.coids = JSON.parse($("#task-courses").val());
      // Get the names of the question sources for the PostHog event.
      const authorSpans = Array.from($("#setaswork-mainoptions").find(".dfmExamBoardSelector-light > span"));
      const authorSpansTextContext = authorSpans.map(span => span.textContent);
      if (authorSpansTextContext.length > 0) {
        examQuestionSource = authorSpansTextContext.length === 1 ? authorSpansTextContext[0] : authorSpansTextContext;
      }
    }
    if ($("#task-row-difficulty").is(':visible') && $("#task-difficulty").val()) data.difficulty = $("#task-difficulty").val();

    var numquestions = undefined;
    if (cmode === "accuracy") {
      var numcorrect = parseInt($("#task-numcorrect").val());
      var outof = parseInt($("#task-outof").val());

      if (numcorrect > outof) {
        dfmAlert("You are requiring an accuracy greater than 100%. Please set your expectations lower.", true);
        return false;
      }
      data.numcorrect = numcorrect;
      data.outof = outof;
      data.interleave = $("#task-accuracyinterleave").val() == 1;
    } else if (cmode == "fixednum") {
      data.numquestions = Number($("#task-numquestions").val());
      data.interleave = $("#task-cmode-fixednumvariant").val() == "interleave";
      data.differentiate = $("#task-cmode-fixednumvariant").val() == "differentiate";
    }


    var skillSelection = $("#task-skillselection").val();
    var skillSelectionJSON = JSON.parse(skillSelection);
    if (!skillSelection || !(skillSelectionJSON.skids.length > 0 || skillSelectionJSON.ssids.length > 0)) {
      dfmAlert("No skills or subskills were selected.");
      return false;
    }
    data.skillselection = skillSelectionJSON;
    var taskType = $("#task-type").val();
    if (!taskType) {
      dfmAlert("No task type was specified.", true);
      return false;
    }
    data.type = taskType;
  }

  // Due date/time
  if ($("#task-noduedate").is(":visible") && !$("#task-noduedate").is(':checked')) {
    var dueUTC = getUTCFromDateAndTime($("#task-duedate"), $("#task-duetime"));
    data.duedate = dueUTC;
  }

  // Set date/time
  if ($("#task-schedule").val() == "1") {
    var scheduleUTC = getUTCFromDateAndTime($("#task-scheduledate"), $("#task-scheduletime"));
    data.scheduledate = scheduleUTC;
  }

  // Options
  if ($("#task-givewarning").is(":visible")) data.givewarning = $("#task-givewarning").val() == 1;
  if ($("#task-preventreattempts").is(":visible")) data.preventreattempts = $("#task-preventreattempts").val() == 1;
  if ($("#task-requireworking").is(":visible")) data.requireworking = Number($("#task-requireworking").val());
  if ($("#task-requirefeedback").is(":visible")) data.requirefeedback = Number($("#task-requirefeedback").val());
  if ($("#task-timelimit").is(":visible") && $("#task-timelimit").val() != 0) data.timelimit = Number($("#task-timelimit").val());
  if ($("#task-hideskillnames").is(":visible")) data.hideskillnames = $("#task-hideskillnames").val() == 1;
  if ($("#task-label").val()) data.label = $("#task-label").val();

  var recipients = $("#task-recipients").val() ? JSON.parse($("#task-recipients").val()) : {};
  if (Context.user && !recipients.uids && !recipients.cids) {
    dfmAlert("No recipients for this task were specified.");
    return false;
  }
  else data.recipients = recipients; // as a practice, setting to themselves!

  // Email notifications
  if ($("#task-email-notifications__input").is(":visible")) {
    data.emailNotifications = $("#task-email-notifications__input").is(":checked");
  }

  $.ajax({
    url: "/api/tasks/tasks",
    type: "POST",
    contentType: false,
    processData: false,
    dataType: "json",
    data: JSON.stringify(data),
    cache: false,
    success: function (data) {
      // PostHog event
      const task = data._task;
      if (task && options.posthogTriggerId) {
        if (options.isPractice) {
          posthogEventTaskIndependentPracticeStarted({
            triggerId: options.posthogTriggerId,
            task,
            examQuestionSource,
           });
        } else {
          const attempts = data.aaids ?? [];
          const classes = recipients.cids ?? [];
          posthogEventTaskSet({
            triggerId: options.posthogTriggerId,
            task,
            examQuestionSource,
            numberOfClasses: classes.length,
            numberOfRecipients: attempts.length,
          });
        }
      }

      if (options.callback) options.callback(data);
      else {
        if (options.isPractice) window.location = 'do-question.php?aaid=' + data.aaids[0];
        else window.location = 'progress.php?aid=' + data.aid;
      }
    },
    error: function (j, textStatus, errorThrown) {
      dfmAlert("An error occurred when setting the task: <strong>" + j.responseText + "</strong>");
    }
  });

}

export function getUTCFromDateAndTime(dateDOM, timeDOM) {
  if (typeof dateDOM !== "string") dateDOM = dateDOM.val();  // if a HTML element, read its value
  if (typeof timeDOM !== "string") timeDOM = timeDOM.val();
  var dateParts = dateDOM.split("/");
  for (var i = 0; i < dateParts.length; i++)if (dateParts[i].length == 1) dateParts[i] = "0" + dateParts[i]; // each of day/month must be 2 digits
  var dateStr = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0] + "T" + timeDOM;
  var dateObj = new Date(dateStr);
  var utc = Date.UTC(dateObj.getUTCFullYear(), dateObj.getUTCMonth(),
    dateObj.getUTCDate(), dateObj.getUTCHours(),
    dateObj.getUTCMinutes(), dateObj.getUTCSeconds()) / 1000;
  return utc;
}



// ----------------------------------------------------
// --- DFM5 Skill selector
// ----------------------------------------------------
// Options:
// -- selectSubskills : bool
// -- includeExamPractice : bool  (if selectSubskills is true, but this is false, only Key Skills will be used)
// -- singleChoice : bool
//
// Methods:
// -- setValue({skids: [...], ssids: [...]})


jQuery.fn.dfmSkillSelector = function (arg1, arg2, arg3) {
  var input = $(this);

  // Constructor
  if ((typeof arg1) != "string") {
    if (arg1.selectSubskills) input.data("selectSubskills", arg1.selectSubskills);
    if (arg1.excludeExamPractice) input.data("excludeExamPractice", arg1.excludeExamPractice);
    if (arg1.singleChoice) input.data("singleChoice", arg1.singleChoice);
    if (arg1.dontAbridgeLabel) input.data("dontAbridgeLabel", arg1.dontAbridgeLabel);
    if (arg1.defaultLabel) input.data("defaultLabel", arg1.defaultLabel);
    if (arg1.hideClearButton) input.data("hideClearButton", arg1.hideClearButton);

    // Event handlers
    if (arg1.onChange) {
      input.data("onChange", arg1.onChange);
    }
    if (arg1.onCourseChange) {
      input.data("onCourseChange", arg1.onCourseChange);
    }
    if (arg1.onClose) {
      input.data("onClose", arg1.onClose);
    }
    if (arg1.onOpen) {
      input.data("onOpen", arg1.onOpen);
    }

    input.after("<span class='dfm_skillSelector'><label>" + (arg1.defaultLabel ? arg1.defaultLabel : "Click to choose") + "</label><span><img src='/images/chevron_down.svg'></span></span>");
    input.next().click(function () {
      dfmSkillSelector_openDialog(input);
    });
  } else if (arg1 == "reset") {
    input.val("");
    var label = input.data('defaultLabel') ? input.data('defaultLabel') : "Click to choose";
    input.next().find('label').html(label);
    input.removeData('skillselection');
  } else if (arg1 == "setValue") {
    input.val(JSON.stringify(arg2));
    let data = { lookup: arg2 };

    if ((!arg2.skids || arg2.skids.length == 0) && (!arg2.ssids || arg2.ssids.length == 0)) {
      $(this).dfmSkillSelector("reset");
      return false;
    }

    // @TODO: Kill the topic tree
    // We need to turn skids[] and ssids[] into full(ish) objects, mostly to provide a label for the
    // input. This is currently done via a topic-tree endpoint (/api/topic/lookup), but NO MORE OF THAT.

    $.ajax({
      url: "/api/topic/lookup",
      type: "POST",
      contentType: false,
      processData: false,
      dataType: "json",
      data: JSON.stringify(data),
      cache: false,
      success: function (data) {
        console.log("[Lookup] " + JSON.stringify(data));
        dfmSkillSelector_setValue(input, data, arg3);
      },
      error: function (j, textStatus, errorThrown) {
        dfmAlert("An error occurred when getting the skill: <strong>data = " + JSON.stringify(data) + ", " + j.responseText + "</strong>");
      }
    });
  }
}

export function dfmSkillSelector_loadUnit(input, unit) {
  const $unit = $("#dfmSkillSelector_unit" + unit.cuid + " > ul");
  const query = $("#dfmSkillSelector_filterInput").val().trim();
  $unit.empty();
  $.each(unit.skills, function (_, skill) {
    displaySkillRow(input, $unit, { skill });
  });
  if (!query) {
    $unit[0].scrollIntoView({ behavior: "smooth", block: "start" });
  }
}

export function getSkillDOMId(skillNode) {
  return "dfmSkillSelector_skill" + skillNode.skill.skid
}

export function displaySkillRow(input, ul, skillNode) {
  var skill = skillNode.skill;
  if (!skill) skill = { skid: 0, publicid: 0, name: "Error: Missing skill for: " + JSON.stringify(skillNode) };

  // Flags for the horrible ternaries to figure out if (a) we EXPECT to display subskills or exam
  // questions, and (b) whether we actually HAVE any subskills or exam questions to display.
  const shouldDisplaySubskills = Boolean(input.data('selectSubskills'));
  const shouldIncludeExamPractice = !input.data("excludeExamPractice");
  const hasSubskillsToDisplay = (shouldIncludeExamPractice && skill.subskills.length)
    || skill.subskills.filter((subskill) => subskill.type === 'k').length > 0;

  if (skill.skid) {
    ul.append(
      "<li id='" + getSkillDOMId(skillNode) + "' class='skill'>" +
      "<a href='#'><label><input type='checkbox' class='skill-checkbox'><em>" + skill.publicid + "</em><span>" + skill.name + "</span></label></a>" +
      (shouldDisplaySubskills && hasSubskillsToDisplay ? "<ul class='dfmSkillSelector_subskills'></ul>" : "") +
      (shouldDisplaySubskills && !hasSubskillsToDisplay ? "<p class='dfmSkillSelector_subskillsUnavailable'>No available subskills" + (shouldIncludeExamPractice ? " or exam questions" : "") + "</p>" : "") +
      "</li>"
    );

    // Store the skillNode against the DOM element representation (<li>)
    // We'll have duplicate IDs in the DOM if a skill appears twice in a course,
    // so we scope it to `ul` to locate THIS ID within the scope of THIS unit.
    // @TODO: Replace this whole thing!
    $("#" + getSkillDOMId(skillNode), ul).data('skillNode', skillNode);

    // If needed, add each subskill. Also add exam questions if we're "not excluding" them.
    if (shouldDisplaySubskills && hasSubskillsToDisplay) {
      $.each(skill.subskills, function (k3, subskill) {
        if (subskill.type == "k" || shouldIncludeExamPractice) {
          subskill.publicid = skill.publicid;
          ul.find("#" + getSkillDOMId(skillNode) + " > ul").append("<li id='dfmSkillSelector_subskill" + subskill.ssid + "' class='subskill'><a href='#'><label><input type='checkbox' class='subskill-checkbox'><em>" + (subskill.letter ? subskill.letter : "") + "</em><span>" + subskill.name + "</span></label></a></li>");
          ul.find("#dfmSkillSelector_subskill" + subskill.ssid).data('subskill', subskill);
        }
      });
    }
  }

  if (!shouldDisplaySubskills) {
    $("#" + getSkillDOMId(skillNode) + " .skill-checkbox", ul).click(function (e) {
      e.stopPropagation();
      console.log("Clicked skill checkbox");

      // Store the course/module/unit path to the selected skill
      input.data('skillPath', {
        coid: $('#dfmSkillSelector_bycourse select').val(),
        cmid: $(e.target).closest('[id^=dfmSkillSelector_module]')[0].id.replace('dfmSkillSelector_module', ''),
        cuid: $(e.target).closest('[id^=dfmSkillSelector_unit]')[0].id.replace('dfmSkillSelector_unit', ''),
      });

      var skill = $(this).closest('li').data('skill');
      if (input.data('singleChoice')) {
        console.log("[C]");
        dfmSkillSelector_buttonclick(input);
      } else {
        $(this).closest('li').toggleClass('selected');
        const selectionCount = $("#dfmSkillSelector input[type=checkbox]:checked").length;
        $("#dfmSkillSelector_useButton").prop('disabled', !selectionCount);
        $("#dfmSkillSelector_useButton").html(`Use selected${selectionCount ? ` (${selectionCount})` : ''}`);
      }
    });
  }

  $("#" + getSkillDOMId(skillNode) + " .subskill span", ul).click(function (e) {
    // This is to prevent clicks of the subskill label rather than the checkbox propagating all the way up to parent topic.
    e.stopPropagation();
  });
  $("#" + getSkillDOMId(skillNode) + " .skill > a > label > span", ul).click(function (e) {
    e.stopPropagation();
  });
  $("#" + getSkillDOMId(skillNode) + " .subskill-checkbox", ul).click(function (e) {
    e.stopPropagation();
    console.log("Click subskill checkbox");

    // Store the course/module/unit path to the selected subskill
    input.data('skillPath', {
      coid: $('#dfmSkillSelector_bycourse select').val(),
      cmid: $(e.target).closest('[id^=dfmSkillSelector_module]')[0].id.replace('dfmSkillSelector_module', ''),
      cuid: $(e.target).closest('[id^=dfmSkillSelector_unit]')[0].id.replace('dfmSkillSelector_unit', ''),
    });

    var subskill = $(this).closest('li').data('subskill');
    if (input.data('singleChoice')) {
      console.log("[B]");
      dfmSkillSelector_buttonclick(input);
    } else {
      $(this).closest('li').toggleClass('selected');
      const selectionCount = $("#dfmSkillSelector input[type=checkbox]:checked").length;
      $("#dfmSkillSelector_useButton").prop('disabled', !selectionCount);
      $("#dfmSkillSelector_useButton").html(`Use selected${selectionCount ? ` (${selectionCount})` : ''}`);
    }
  });
}

export function dfmSkillSelector_openDialog(input) {

  // Clear existing course data.
  // @TODO: We could potentially persist/cache this?
  input.data('currentCourse', null);

  // Create our dialog
  dfmAlert(`
    <div id="dfmSkillSelector">
      <div id="dfmSkillSelector_bycourse">
        <select></select>
        <div id="dfmSkillSelector_filter">
          <label for="dfmSkillSelector_filterInput">
            <img src="/images/search_icon.svg">
            Filter:
          </label>
          <input type="search" id="dfmSkillSelector_filterInput" title="Filter the skills within this course by ID, or by text match">
        </div>
        <p id="dfmSkillSelector_courseModulesEmpty">You must select a course above</p>
        <p id="dfmSkillSelector_filterEmpty">No filter results</p>
        <p id="dfmSkillSelector_courseEmpty">This course contains no selectable skills</p>
        <ul class="dfmSkillSelector_modules" id="dfmSkillSelector_courseModules"></ul>
        <div id="dfmSkillSelector_footer">
          <button id="dfmSkillSelector_useButton">Use selected</button>
          <button id="dfmSkillSelector_clearButton">Clear</button>
        </div>
      </div>
    </div>
  `, input.data("onClose"));

  // If we have an onOpen callback, fire it now.
  if (typeof input.data("onOpen") === "function") {
    input.data("onOpen")();
  }

  // Hide the not-actually-interactive-yet elements (and mark the dialog as loading)
  $('#dfmSkillSelector').addClass('isLoading');
  $('#dfmSkillSelector_bycourse').hide();
  $('#dfmSkillSelector_filter').hide();
  $('#dfmSkillSelector_courseModules').hide();
  $('#dfmSkillSelector_filterEmpty').hide();
  $('#dfmSkillSelector_courseEmpty').hide();

  if (input.data('singleChoice')) $("#dfmSkillSelector").addClass('singleChoice');
  if (input.data('selectSubskills')) $("#dfmSkillSelector").addClass('selectSubskills');

  $("#dfmSkillSelector_useButton").click(function () {
    console.log("[A]");
    dfmSkillSelector_buttonclick(input)
  });
  $("#dfmSkillSelector_clearButton").click(function () {
    input.dfmSkillSelector('reset');
    dfmSkillSelector_buttonclick(input);
  });

  // Hide the "use" button if we're only selecting one item (the click-to-select triggers the "use")
  if (input.data('singleChoice')) {
    $('#dfmSkillSelector_useButton').hide();
  } else {
    $("#dfmSkillSelector_useButton").prop('disabled', true);
  }

  // Hide the clear button if configured
  if (input.data("hideClearButton")) {
    $('#dfmSkillSelector_clearButton').hide();
  }

  // If both buttons are hidden we'll hide the whole footer to claim back the padding/margin space.
  if (input.data('singleChoice') && input.data("hideClearButton")) {
    $('#dfmSkillSelector_footer').hide();
  }

  // Set up course text filter
  $("#dfmSkillSelector_filterInput").dfmWaitingInput(
    async () => {
      const filter = $("#dfmSkillSelector_filterInput").val().trim();
      const coid = $("#dfmSkillSelector_bycourse select").val();
      const courseModulesList = $('#dfmSkillSelector_courseModules');
      const courseData = input.data('currentCourse');

      // Clear filtering (show/hide everything, collapse everything)
      $('#dfmSkillSelector_filterEmpty').hide();
      $('#dfmSkillSelector_courseModules').show();
      $('.strand, .topic', courseModulesList).show().removeClass('expanded');
      $('.skill, .subskill', courseModulesList).show();

      if (filter && coid && courseData) {
        // Find out which modules and units have skills or subskills which match the search.
        const type = input.data('excludeExamPractice') ? 'k' : '';
        const searchSubskills = input.data('selectSubskills') ? 1 : 0;

        const matches = getSkillFilterMatches(courseData, filter, searchSubskills, type);

        // If we're completely empty just hide the list and show a message
        if (matches.length === 0) {
          $('#dfmSkillSelector_courseModules').hide();
          $('#dfmSkillSelector_filterEmpty').show();
        } else {
          // Hide all courses and units
          $('.strand, .topic', courseModulesList).hide();

          // Make sure we're showing and expanding the modules and units with [sub]skill matches
          // ("Click" units with matched children to expand them, triggering a data load if necessary)
          matches.forEach((match) => {
            $(`#dfmSkillSelector_module${match.cmid}`, courseModulesList).show().addClass('expanded');
            match.cuids.forEach((cuid) => {
              $(`#dfmSkillSelector_unit${cuid}`, courseModulesList).show().find('a > h2').click();
            });
          });

          // Hide existing [sub]skills in the DOM that don't match
          const shouldDisplaySubskills = Boolean(input.data('selectSubskills'));

          $('.skill', courseModulesList).each((_, skillElement) => {
            filterSkillElement(skillElement, filter, shouldDisplaySubskills);
          });

          // ...Highlight matches? mark.js?
        }
      }

      $('#dfmSkillSelector_courseModules').removeClass('isPending');
    },
    () => {
      $('#dfmSkillSelector_courseModules').addClass('isPending');
    }
  );

  // Add interactivity to the course selector
  $("#dfmSkillSelector_bycourse select").change(function () {
    dfmSkillSelector_loadCourse(input, $(this).val());
  });

  // Get the initial course list, and navigate/drill down if we know the location
  dfmSkillSelector_getCourseList(input);
}


/**
 * @param {{
 *   coid: int, modules: {
 *     cmid: int, name: string, units: {
 *       cuid: int, name: string, skills: {
 *         skid: int, name: string, publicid: int, subskills: {
 *           ssid: int, name: string, letter: string
 *         }[]
 *       }[]
 *     }[]
 *   }[]
 * }} courseData A streamlined set of course data as used in the skill-selector
 * @param {string} [filter] The string to search for
 * @param {boolean} [searchSubskills] Whether to match on subskills or not
 * @param {'e'|'k'} [type] The type of subskills to include in the search: (e)xam / (k)eyskill
 * @returns {{ cmid: int, cuids: int[] }[]} A set of modules and units which match the given filter
 */
function getSkillFilterMatches(courseData, filter = '', searchSubskills = false, type = '',) {
  const filterRegex = new RegExp(`.*${filter}.*`, 'i');

  // Cycle through each module and unit, testing the unit's skills (and
  // optionally subskills) against the filter value.
  const matches = [];
  courseData.modules.forEach((module) => {
    const unitMatches = [];
    module.units.forEach((unit) => {
      // Uses `some()` to short-circuit on matches and speed up processing
      if (unit.skills.some((skill) => {
        if (`${skill.publicid}` === filter) {
          return true;
        }
        if (filterRegex.test(skill.name)) {
          return true;
        }
        if (searchSubskills && skill.subskills.some((subskill) => {
          if (type && subskill.type === type) {
            if (`${skill.publicid}${subskill.letter}` === filter) {
              return true;
            }
            if (filterRegex.test(subskill.name)) {
              return true;
            }
          }
        })) {
          return true;
        }
      })) {
        unitMatches.push(unit.cuid);
      };
    });

    if (unitMatches.length) {
      matches.push({
        cmid: module.cmid,
        cuids: [...new Set(unitMatches)],
      })
    }
  });

  return matches;
};

// Hides a skill element based on whether it or any of its subskills match the filter
function filterSkillElement(skillElement, filter, includeSubskills = false) {
  const filterRegex = new RegExp(`.*${filter}.*`, 'i');

  let skillMatch = false;
  let subskillMatch = false;

  // Test the skill first
  const description = skillElement.querySelector('label span').textContent;
  const skillId = skillElement.querySelector('label em').textContent;
  if (skillId == filter || filterRegex.test(description)) {
    skillMatch = true;
  }

  // If we're displaying/selecting subskills we need to test those too
  // (If the skill matches we won't hide any children, so can skip this.)
  if (!skillMatch && includeSubskills) {
    const publicId = skillElement.querySelector('a label em').textContent;
    const subskillElements = skillElement.querySelectorAll('li');
    subskillElements.forEach((subskillElement) => {
      const letter = subskillElement.querySelector('label em').textContent;
      const description = subskillElement.querySelector('label span').textContent;
      const subskillId = `${publicId}${letter}`;
      if (subskillId == filter || filterRegex.test(description)) {
        subskillMatch = true;
      } else {
        subskillElement.style.display = 'none';
      }
    });
  }

  // Hide the skill if it does not match and contains no matches
  if (!skillMatch && !subskillMatch) {
    skillElement.style.display = 'none';
  };
}


export function dfmSkillSelector_loadCourse(input, coid) {

  // Tidy up ready for a new course
  $('#dfmSkillSelector_filter').hide();
  $("#dfmSkillSelector_filterInput").val('');
  $("#dfmSkillSelector_filterEmpty").hide();
  $("#dfmSkillSelector_courseEmpty").hide();
  $("#dfmSkillSelector_courseModules").empty();

  // If no course has been selected show a message and return
  if (!coid) {
    $("#dfmSkillSelector_courseModulesEmpty").show();
    $("#dfmSkillSelector_courseModules").hide();
    return;
  }

  // Okay, we're going to load a course
  $("#dfmSkillSelector_courseModulesEmpty").hide();
  $("#dfmSkillSelector_courseModules").show();
  $("#dfmSkillSelector_courseModules").addClass('isLoading');

  $.ajax({
    url: "/api/course/skillselector/" + coid,
    context: document.body,
    dataType: "json",
    success: function (course, textStatus, jqXHR) {
      // Store the course data against the input element to use when searching/
      // filtering. (Rather than extracting data back out from DOM elements...!)
      input.data('currentCourse', course);

      if (course.modules.length) {

        // Add each module to the list
        $.each(course.modules, function (key2, module) {
          $("#dfmSkillSelector_courseModules").append("<li id='dfmSkillSelector_module" + module.cmid + "' class='strand'><a href='#'><h2><img src='/images/chevron_down.svg'> " + module.name + "</h1></a><ul class='dfmSkillSelector_topics'></ul></li>");
          $.each(module.units, function (key3, unit) {
            $("#dfmSkillSelector_module" + module.cmid + " > ul").append("<li id='dfmSkillSelector_unit" + unit.cuid + "' class='topic'><a href='#'><h2><img src='/images/chevron_down.svg'> " + unit.name + "</h2></a><ul class='dfmSkillSelector_skills'></ul></li>");
            $("#dfmSkillSelector_unit" + unit.cuid).data('unit', unit);
            dfmSkillSelector_loadUnit(input, unit);
          });
        });

        // Render any LaTeX in the module/unit/skill/subskill names that we've added to the DOM
        MathJax.typeset(["#dfmSkillSelector_courseModules"]);

        // Add interaction
        $("#dfmSkillSelector_bycourse .strand > a, #dfmSkillSelector_bycourse .topic > a").click(function (e) {
          e.stopPropagation();
          console.log("CLICKED ON: " + e.target.nodeName);
          $(this).parent().toggleClass('expanded');
        });

        // Show the filter input
        $('#dfmSkillSelector_filter').show();

        // Show the course list element
        $("#dfmSkillSelector_courseModules").removeClass('isLoading');
        $("#dfmSkillSelector_courseModules").show();
        $("#dfmSkillSelector_courseModulesEmpty").hide();

        // Expand the previous module and unit, if available
        let skillPath = input.data('skillPath');

        if (skillPath?.useAllSkills) {
          // Find the full location of the current [sub]skill within the
          // all-skills course, and populate skillPath with the coid, cmid, and cuid
          // so that the full expansion can be done.
          const selectedSkills = JSON.parse($(input).val() || 'null');
          skillPath = getSkillPathWithinCourse(input.data('currentCourse'), selectedSkills?.skids[0], selectedSkills?.ssids[0]);
          $(input).data('skillPath', skillPath);
        }

        if (skillPath?.coid && parseInt(skillPath.coid, 10) === parseInt(coid, 10)) {
          // We're on the right course, so we'll expand further if we can
          if (skillPath?.cmid) {
            // We've got a module. Scroll into view and expand it.
            $(`#dfmSkillSelector_module${skillPath.cmid}`)[0]
              .scrollIntoView({ behavior: "smooth", block: "start" });
            $(`#dfmSkillSelector_module${skillPath.cmid}`).addClass('expanded');
            if (skillPath.cuid) {
              // We've got a unit. Scroll into view and expand it.
              $(`#dfmSkillSelector_unit${skillPath.cuid}`)[0]
                .scrollIntoView({ behavior: "smooth", block: "start" });
              $(`#dfmSkillSelector_unit${skillPath.cuid}`).addClass('expanded');
            }
          }
        }
      } else {
        // We've got a course with no selectable [sub]skills at all, could be a course without any
        // modules, or one in which no units contain any skills (and so this selector can't work.)
        $("#dfmSkillSelector_courseModules").removeClass('isLoading');
        $('#dfmSkillSelector_courseModules').hide();
        $('#dfmSkillSelector_courseEmpty').show();
      }
    },
    error: function (jqXHR, textStatus, errorThrown) {
      dfmAlert("There was an error retrieving the course:<br><br><strong>" + jqXHR.responseText + "</strong>");
    }
  });

}

export async function buildCourseSelectElement($select, onChange) {
  const courseGroups = await getCourseGroupsForDropdown({ user: Context?.user });

  $select.empty();
  $select.append("<option disabled selected>&ndash; Select a course &ndash;</option>");

  // Add the all-courses course
  // Special case since it doesn't get an <optgroup> or an author prefix
  const allSkillsCourse = courseGroups.find((group) => group.id === 'all').courses[0];
  if (allSkillsCourse) { // We really SHOULD have one if this code is running!
    $select.append(`<option value="${allSkillsCourse.coid}">${allSkillsCourse.name}</option>`);
  }

  // Add other course types to the select in our defined order
  ['school', 'user'].forEach((courseType) => {
    const courseGroup = courseGroups.find((group) => group.id === courseType);
    if (courseGroup?.courses.length) {
      $select.append(`<optgroup label="${courseGroup.heading}">`);
      courseGroup.courses.forEach((course) => {
        // Prepend the course's author for clarity for all courses NOT owned
        // by the current user's school.
        const authorPrefix = course.sid !== Context.user?.sid ? `[${course.authorName}] ` : '';
        $select.append(`<option value="${course.coid}">${authorPrefix}${course.name}</option>`);
      });
      $select.append('</optgroup>');
    }
  });

  if (typeof onChange === "function") {
    $select.on("change", (e) => {
      const coid = e.target.value;
      const course = courseGroups.flatMap((group) => group.courses).find((course) => course.coid == coid);
      onChange(course);
    });
  }
}

export async function dfmSkillSelector_getCourseList(input) {
  const $select = $("#dfmSkillSelector_bycourse select");
  await buildCourseSelectElement($select, input.data("onCourseChange"));

  // Update the display now that we've loaded the course list
  $('#dfmSkillSelector').removeClass('isLoading');
  $('#dfmSkillSelector_bycourse').show();

  let skillPath = $(input).data('skillPath');
  const selectedSkills = JSON.parse($(input).val() || 'null');

  if (skillPath?.coid) {
    // If we have a previous course, or indication we should use all-skills,
    // then expand and navigate to that course.
    // (Module and unit navigation is handled when the course is fully loaded.)
    $select.val(skillPath.coid).change();
  } else if (skillPath?.useAllSkills) {
    const allSkillsCourse = await getAllSkillsCourseDetails();
    if (allSkillsCourse?.coid) {
      $select.val(allSkillsCourse.coid).change();
    }
  }
}

export function getSkillPathWithinCourse(course, skid, ssid) {

  const skillPath = { coid: course.coid };

  // Big ol' set of loops
  for (const module of course.modules) {
    skillPath.cmid = module.cmid;
    for (const unit of module.units) {
      skillPath.cuid = unit.cuid;
      for (const skill of unit.skills) {
        if (skid && skill.skid === skid) {
          return skillPath;
        } else if (ssid) {
          for (const subskill of skill.subskills) {
            if (subskill.ssid === ssid) {
              return skillPath;
            }
          };
        }
      };
    };
  };

  // Well, at least we know the course ID.
  return { coid: course.coid };
}


export function dfmSkillSelector_buttonclick(input) {
  console.log("[dfmSkillSelector_buttonclick]");

  var skills = [];
  var subskills = [];
  $(".subskill-checkbox:checked").each(function () {
    var subskill = $(this).closest('li').data('subskill');
    if (!subskill) {
      console.log("[SkillSelector] Error: checkbox selected for subskill but data for subskill was missing");
      return;
    }
    subskills.push(subskill);
    // dfmSkillSelector_pathcache[subskill.skid] = subskill.path;
  });
  $(".skill-checkbox:checked").each(function () {
    var skillNode = $(this).closest('li').data('skillNode');
    var skill = skillNode.skill;
    console.log(" -- Skill checkbox " + skill.skid + " was selected");
    skills.push(skill);
  });

  var skillselection = { skills, subskills };
  console.log("[SkillSelector] skillselection = " + JSON.stringify(skillselection));
  dfmSkillSelector_setValue(input, skillselection, true)
  $.modal.close();
}

export function dfmSkillSelector_setValue(input, selection, triggerListener) {
  if (selection.skills) console.log("dfmSkillSelector_setValue skid count = " + selection.skills.length);
  var skids = [];
  var ssids = [];
  var names = [];
  $.each(selection.subskills, function (k, subskill) {
    ssids.push(subskill.ssid);
    names.push(getSubskillFullName(subskill));
  });
  $.each(selection.skills, function (k, skill) {
    skids.push(skill.skid);
    names.push("<em>" + skill.publicid + "</em> " + skill.name);
  });
  var label = names.join(", ").replace("Exam Practice: ", "");
  // if (!input.data('dontAbridgeLabel')) label = limitLength(label, 25);
  if (label) input.next().find('label').html(label);
  else input.next().find('label').html(input.data('defaultLabel') ? input.data('defaultLabel') : "Click to choose");

  var skillSelectionIds = { skids, ssids };
  input.val(JSON.stringify(skillSelectionIds));
  input.data('skillselection', selection); // having full skill objects available useful if we reselect skill.
  if (input.data("onChange") && triggerListener) input.data("onChange")(selection);

  // Hovering mouse over element allows them to see the original non-abridged skill list.
  input.next().attr('title', names.join(", ").replace("<em>", "").replace("</em>", ""));

}

export function getSubskillLabel(subskill) {
  return getSubskillFullName(subskill);
}

export function getSubskillFullName(subskill) {
  return "<em>" + subskill.publicid + (subskill.letter ? subskill.letter : "") + "</em> " + subskill.name;
}


// ------------------------------


export function setTaskDialog() {
  closeSideMenu();
  dfmAlertInverted(""
    + "<div class='dialog-options'><h1>Set a Task</h1>"
    + "<div><button id='settask-bytopic'>By Topic</button><p>A mixture of past paper questions and our random question generators.</p></div>"
    + "<div><button id='settask-pastpapers'>Past Papers</button><p>Choose an existing paper, with the option to modify the paper first.</p></div>"
    + "<div><button id='settask-choosequestions'>Choose questions</button><p>Build your own selection of questions from scratch.</p></div>"
    + "<div><button id='settask-worksheetshome'>Your worksheets</button><p>Go to your worksheets home directory.</p></div>"
    + "</div>",
    () => {
      posthogEventSetATaskModalClosed({ triggerId: "set-a-task-modal-dismiss" });
    }
  );
  $("#settask-bytopic").on("click", function () {
    posthogEventTaskByTopicChosen({ triggerId: "set-a-task-modal-by-topic-button" });
    window.location = "/explorer.php";
  });
  $("#settask-pastpapers").on("click", function () {
    posthogEventWorksheetPastPapersDirectoryViewed({ triggerId: "set-a-task-modal-past-papers-button" });
    window.location = "/worksheets.php?wdid=2";
  });
  $("#settask-choosequestions").on("click", function () {
    posthogEventTaskChooseQuestionsChosen({ triggerId: "set-a-task-modal-choose-questions-button" });
    window.location = "/worksheets.php?wid=new";
  });
  $("#settask-worksheetshome").on("click", function () {
    posthogEventWorksheetHomeDirectoryViewed({ triggerId: "set-a-task-modal-your-worksheets-button" });
    window.location = "/worksheets.php?wdid=home";
  });

}

export function startPracticeDialog() {
  closeSideMenu();
  dfmAlertInverted(""
    + "<div class='dialog-options'><h1>Start a Practice</h1>"
    + "<div><button id='practice-bytopic'>By Topic</button><p>Practise either exam questions, or to become confident with specific types of questions, use our question generators.</p></div>"
    + "<div><button id='practice-pastpapers'>Past Papers</button><p>Practise collections of questions from different exam boards.</p></div>"
    + (Context.user ? "<div><button id='practice-cleanup'>Cleanup</button><p>Redo 4 questions you recently got incorrect.</p></div>" : "")
    + "</div>",
    () => {
      posthogEventStartAPracticeModalClosed({ triggerId: "start-a-practice-modal-dismiss" });
    }
  );
  $("#practice-bytopic").on("click", function () {
    posthogEventTaskByTopicChosen({ triggerId: "start-a-practice-modal-by-topic-button" });
    window.location = "/explorer.php";
  });
  $("#practice-pastpapers").on("click", function () {
    posthogEventWorksheetPastPapersDirectoryViewed({ triggerId: "start-a-practice-modal-past-papers-button" });
    window.location = "/worksheets.php?wdid=2";
  });
  $("#practice-cleanup").click(startCleanupTask);
}

export function liveGameDialog() {
  closeSideMenu();
  dfmAlertInverted("<h1>Dr Frost Live!</h1><img src='/images/phone_icon2.svg' style='filter:invert(1);width:60px;float:left;margin-right:30px;margin-bottom:40px'>"
    + "<p>In a Live! game, students see the questions on your own screen and play along on their mobile/tablet device. To start a game, go to the <strong>Question Explorer</strong> or <strong>Papers & Worksheets</strong>, and when starting a task, choose the Live! option.</p>");
  return false;
}


export function learnDialog() {
  closeSideMenu();
  dfmAlertInverted(""
    + "<div class='dialog-options'><h1>How can we help you best?</h1>"
    + "<div><button id='learn-bytopic'>By Topic</button><p>Watch videos and practise either exam questions, or to become confident with specific types of questions, use our question generators.</p></div>"
    + "<div><button id='learn-pastpapers'>Past Papers</button><p>Practise collections of questions from different exam boards.</p></div>"
    + "<div><button id='learn-course'>Do a course</button><p>Work through a course, either using one of our inhouse courses, or from exam boards and educational publishers.</p></div>"
    + "</div>",
    () => {
      posthogEventLearnModalClosed({ triggerId: "learn-modal-dismiss" });
    }
  );
  $("#learn-bytopic").on("click", function () {
    posthogEventTaskByTopicChosen({ triggerId: "learn-modal-by-topic-button" });
    window.location = "/explorer.php";
  });
  $("#learn-pastpapers").on("click", function () {
    posthogEventWorksheetPastPapersDirectoryViewed({ triggerId: "learn-modal-past-papers-button" });
    window.location = "/worksheets.php?wdid=2";
  });
  $("#learn-course").on("click", function () {
    window.location = "/view-courses.php";
  });

}

export function startCleanupTask() {
  let data = {
    type: "z", // z means a cleanup task
    recipients: {
      uids:
        [Context.user.uid]
    }
  };

  $.ajax({
    url: "/api/tasks/tasks",
    type: "POST",
    contentType: false,
    processData: false,
    dataType: "json",
    data: JSON.stringify(data),
    cache: false,
    success: function (data) {
      // Send PostHog event for the start of an independent practice task.
      let task = data._task;
      if (task) {
        posthogEventTaskIndependentPracticeStarted({
          triggerId: "start-a-practice-modal-cleanup-button",
          task,
        });
      }

      if (data.aaids && data.aaids[0]) {
        window.location = 'do-question.php?aaid=' + data.aaids[0];
      } else {
        dfmAlert("Unable to find previous task attempt.");
      }
    },
    error: function (j, textStatus, errorThrown) {
      dfmAlert("An error occurred when setting the task: <strong>" + j.responseText + "</strong>");
    }
  });
}

// --------------------------------------------
// Percentage total mastery bar
// To use: Just put <span>X/Y</span> in the HTML then call .dfmPercentageTotalMasteryBar() on it.

jQuery.fn.dfmPercentageTotalMasteryBar = function (arg1, arg2) {
  var mastery = Number($(this).html().split("/")[0]);
  var outof = Number($(this).html().split("/")[1]);
  var percentage = 100 * mastery / outof;
  $(this).addClass('dfm_percentageTotalMasteryBar');
  $(this).html("<div class='bar'><div class='bar-filled'></div></div><span><span class='mastery-text'>35/350</span> Mastery</span>");
  $(this).find('.bar-filled').css('width', percentage + "%");
  $(this).find('.mastery-text').html(mastery + "/" + outof);
  if (outof == 0) $(this).hide(); // no point display progress bar if not out of anything
}

// --------------------------------------
// -- DIRECTORY SELECTOR
// --------------------------------------

jQuery.fn.dfmDirectorySelector = function (options) {
  console.log("[DFMDirectorySelector]");
  var input = $(this);
  if (options && options == "update") {
    console.log("[DirectorySelector] Updated directory to wdid = " + input.val());
    dfmCurrentDir = input.val();
    dfmGetDirectoryAncestors(input);
    return;
  }
  var readOnly = options && options.readOnly;
  if (options && options.callback) input.data("callback", options.callback);
  if (options && options.default) input.data("default", options.default);
  else if (Context.user && Context.user.type != "student") dfmDirectorySelector_loadHomeDirectory(input); // use user home directory as default
  if (options && options.checkAddPermission) input.data("checkAddPermission", options.checkAddPermission === true);
  dfmCurrentDir = input.val();
  input.after("<span class='dfm-directoryselector'></span>");
  if (!readOnly) input.next().click(function () { dfmOpenDirectorySelector(input) });
  dfmGetDirectoryAncestors(input);
};

export function dfmDirectorySelector_loadHomeDirectory(input) {
  $.ajax({
    url: "/api/worksheets/userhomedirectory",
    type: "GET",
    contentType: false,
    processData: false,
    cache: false,
    dataType: "json",
    success: function (homeDirec) {
      input.data("default", homeDirec.wdid);
    },
    error: function (j, textStatus, errorThrown) {
      dfmAlert("An error occurred when trying to get the home directory: <strong>" + j.responseText + "</strong>");
    }
  });

}

// This is for the actual main component on the page (not the dialog)
export function dfmGetDirectoryAncestors(input) {
  if (!input.val()) {
    input.next().empty();
    input.next().html("No saved location");
    return;
  }
  var url = "/api/worksheets/directory/" + dfmCurrentDir + "?directoriesOnly=1";
  $.ajax({
    url: url,
    context: document.body,
    dataType: "json",
    success: function (data, textStatus, jqXHR) {
      input.next().empty();
      data._ancestors.push(data); // Put the directory itself at end of ancestors
      $.each(data._ancestors, function (k2, anc) {
        input.next().append("/ <a href='#'>" + (anc.name == "ROOT" ? "DFM" : anc.name) + "</a>");
      });
    },
    error: function (j, textStatus, errorThrown) {
      $.modal.close();
      dfmAlert("There was an error retrieving this directory:<br><br><strong>" + j.responseText + "</strong>");
    }
  });

}

var dfmCurrentDir;
export function dfmOpenDirectorySelector(input) {
  var inputTxt = "<div id='dfm-directoryselector-current'><span></span></div>";
  inputTxt += "<ul id='dfm-directoryselector-children'></ul><br><br>";

  dfmDialog(inputTxt, [{
    label: "Use current directory", action: function () {
      input.next().html($("#dfm-directoryselector-current span").html());
      input.next().find('a').attr('href', '#'); // remove interactivity
      input.val(dfmCurrentDir);
      if (input.data("callback")) input.data("callback")();
    }
  }], true);
  dfmOpenDirectory_load(dfmCurrentDir ? dfmCurrentDir : input.data("default"), input);
}

export function dfmOpenDirectory_load(wdid, input) {
  console.log("[DirectorySelector] wdid=" + wdid);
  dfmCurrentDir = wdid;
  var url = "/api/worksheets/directory/" + dfmCurrentDir + "?directoriesOnly=1";
  $("#worksheet-fileexplorer ul").empty();

  $.ajax({
    url: url,
    context: document.body,
    dataType: "json",
    success: function (data, textStatus, jqXHR) {
      console.log(JSON.stringify(data));
      $("#dfm-directoryselector-children").empty();
      $.each(data._children, function (k, f) {
        var img = "<img src='/homework/img/directory-small.png'>";
        var fid = "db" + f.wdid;
        var access = ""; // "<img src='/homework/img/access-"+f.v+".png' style='float:right'>";
        $("#dfm-directoryselector-children").append("<li id='" + fid + "'>" + access + img + f.name + "</li>");
        $("#" + fid).data("wdid", f.wdid);
        $("#" + fid).on('click', function () {
          dfmOpenDirectory_load($(this).data("wdid"), input);
        });
      });
      if (data._children.length == 0) {
        $("#dfm-directoryselector-children").append("There are no further subdirectories. Click the <em>Use Current Directory</em> button to select this directory or navigate upwards above.</span>");
      }

      $("#dfm-directoryselector-current span").empty();
      if (!data._ancestors) data._ancestors = [];
      data._ancestors.push(data); // Put the directory itself at end of ancestors
      $.each(data._ancestors, function (k2, anc) {
        $("#dfm-directoryselector-current span").append("/ <a href='#' id='dfmWSAncestor-" + anc.wdid + "'>" + (anc.name == "ROOT" ? "DFM" : anc.name) + "</a>");
        $("#dfmWSAncestor-" + anc.wdid).data('wdid', anc.wdid);
        $("#dfmWSAncestor-" + anc.wdid).click(function () {
          var wdid = $(this).data('wdid');
          dfmOpenDirectory_load(wdid, input);
        });
      });

      // This disables the 'Use this directory' button where we're not allowed to save in that directory.
      if (input.data("checkAddPermission")) {
        var permit = hasDirectoryPermission(data, 'a');
        $(".dialog-buttons button").prop('disabled', !permit);
      }

    },
    error: function (j, textStatus, errorThrown) {
      $.modal.close();
      dfmAlert("There was an error retrieving this directory:<br><br><strong>" + j.responseText + "</strong>");
    }
  });

}


/* ----------------------------------------------
   -- Main
   ---------------------------------------------- */
$(document).ready(async function () {

  $("#search-form input").keyup(function (e) {
    if ((e.keyCode >= 37 && e.keyCode <= 40) || e.keyCode == 13) return false;
    var val = $.trim($("#search-form input").val());
    if (!emSearchBar_resultsActive) $("#search-results .resources").empty();
    if (val == "") closeSearch();
    else doSearch(val);
  });

  $("#users-panel-left select").change(function () {
    updateUsersPanel();
  });

  if ($("#search-results-new").length > 0) {
    $("#search-results-new").dfmTabs();
    $("#search-results-new").find('ul').next().css('background-color', '#2c5857');
  }

  $("#close-search-button").click(closeSearch);

  addInitialFunction(instantiateSideMenu);

  // Get the logged in user.
  const userData = await getLoggedInUser();

  // Set the user in the Context object.
  Context.user = userData?.user ?? null;

  // Send the user data to the handler to prepare parts of the UI.
  dealWithGetLogin(userData);

  // Execute the initial functions that rely on the Context object.
  if ($(document).data("initialFuncs")) {
    $.each($(document).data("initialFuncs"), function (k, func) {
      func();
    });
  }

  /**
   * Cookie consent banner.
   */
  const cookieConsent = localStorage.getItem("cookie_consent");

  if (cookieConsent === null) {
    $(".cookie-consent__container").css("display", "flex")
    $(".cookie-consent__accept").on("click", () => {
      $(".cookie-consent__container").hide();
      localStorage.setItem("cookie_consent", "optional");
      posthog.set_config({ persistence: "localStorage+cookie" });
    });
    $(".cookie-consent__reject").on("click", () => {
      $(".cookie-consent__container").hide();
      localStorage.setItem("cookie_consent", "required");
      posthog.set_config({ persistence: "memory" });
    });
  }
});
